import React, { useContext, useEffect, useState } from "react";
import Sd_faqs_semi_view from "../../Components/sd-common-components/sd-faqs-semi-view";
import SD_Paroksha_Seva_One from "../../Components/body/paroksha-seva/sd-paroksha-seva-component-one";
import Sd_donation_inner_one from "../../Components/body/sd-donation/sd-donation-inner-one";
import Sd_gallery_semi_view from "../../Components/sd-common-components/sd-gallery-semi-view";
import { SD_Context } from "../../context/sd-context";
import Sd_Donations_service from "../../services/sd-donations";
import { toast } from "react-toastify";
import Sd_loading from "../../Components/sd-common-components/sd-loading";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";

const SdDonationComonTemplate = ({ match }) => {
  const { donationData, setDonationData, templeID, commonMatchFormat } =
    useContext(SD_Context);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useeffect-----------------------------------------------------------//

  useEffect(() => {
    if (donationData.length !== 0) {
      setLoading(true);

      let datas = donationData.find(
        (item) =>
          commonMatchFormat(item?.name) === commonMatchFormat(match.params.id)
      );

      if (datas) {
        setData(datas);
        setLoading(false);
      }
    } else {
      getData();
    }
  }, [match]);
  //-------------------------------------------------------------useeffect-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//

  const getData = async () => {
    setLoading(true);
    try {
      let data = await Sd_Donations_service.getAllOnline(templeID);
      setDonationData(data);

      const datas = data.find(
        (item) =>
          commonMatchFormat(item?.name) === commonMatchFormat(match.params.id)
      );
      setData(datas);
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
    }
  };
  //-------------------------------------------------------------functions-----------------------------------------------------------//

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {`${data?.name} in srisailam | Srisailam Temple | Srisailam
            Online`}
        </title>
        <meta
          name="description"
          content={`${data.name} .  Srisailam temple accepts online donations for the ${data.name}.`}
        />
        <meta
          name="keywords"
          content={`${data.name} in srisailam, Srisailam Temple, Srisailam Online`}
        />
      </Helmet>
      {loading ? (
        <Sd_loading />
      ) : (
        <div className="w-100">
          <SD_Paroksha_Seva_One from={`${data?.name}`} data={data} />
          <Sd_donation_inner_one type={data} />
          <Sd_faqs_semi_view from="donations" /> <Sd_gallery_semi_view />
        </div>
      )}
    </>
  );
};

export default withRouter(SdDonationComonTemplate);

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { SD_Logo_circle } from "./Assets/imageList";
const SITE_KEY = "6LfKG_QaAAAAAI9rFw0CjwXxTjEUWZkCMzVqyba6";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const Reacptcha = ({ history }) => {
  const [selectedlanguage, setSelectedlanguage] = useState("en-in");

  useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {
        try {
          window.grecaptcha.ready(async () => {
            window.grecaptcha
              .execute(SITE_KEY, { action: "submit" })
              .then(async (token) => {
                history.push(
                  `/${selectedlanguage}/redirect-token?recaptcha=${token}`
                );
              });
          });
        } catch (e) {
          //
        }
      }
    );
  }, []);

  return (
    <div className="flex">
      <img src={SD_Logo_circle} alt="logo" height="100px" />
    </div>
  );
};

export default withRouter(Reacptcha);

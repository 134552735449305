import React, { useEffect, useContext, useState, useRef } from "react";
import {
  sd_check,
  sd_dashboard_side_arrow,
  sd_india_flag,
  sd_trash,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-css/main.css";
import { SD_Context } from "../../../context/sd-context";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import Sd_DashboardService from "../../../services/sd-dashboard.service";
// import Sd_POPup from "./sd-pop-up";
import "../../../Utils/sd-styles/sd-support.scss";
import UserService from "../../../services/sd-user.service";
import moment from "moment";
import Sd_loading from "../../../Components/sd-common-components/sd-loading";
import { addDays, getMonth, getYear } from "date-fns";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import CommsService from "../../../services/sd-comms.service";
const CommonRegex = /^[#.0-9a-zA-Z\s,-/]+$/;
const Sd_dashboard_ma_myprofile = () => {
  //-------------------------------------------------------------states--------------------------------------------------------//
  const { setActive, setActivePath, userData, setUserData, logout, templeID } =
    useContext(SD_Context);
  const [uploadFlag, setUploadFlag] = useState(false);
  const TITLES = ["Mr", "Mrs", "Miss", "Dr"];
  const [firstName, setFirstName] = useState(
    userData?.firstName ? userData?.firstName : ""
  );
  const [lastName, setlastName] = useState(
    userData?.lastName ? userData?.lastName : ""
  );
  const [gothram, setGothram] = useState(
    userData?.gothram ? userData?.gothram : ""
  );
  const [uploadLink, setUploadLink] = useState(
    userData?.docFileUrl ? userData?.docFileUrl : ""
  );
  const [startDate, setStartDate] = useState(
    userData?.dateOfBirth ? new Date(userData?.dateOfBirth) : ""
  );
  const [docNumber, setdocNumber] = useState(
    userData?.idProofNumber ? userData?.idProofNumber : ""
  );
  const [docType, setDockType] = useState(
    userData?.idProofType ? userData?.idProofType : ""
  );
  const [gender, setGender] = useState(
    userData?.gender ? userData?.gender : ""
  );
  // const [modal, setModal] = useState(false);
  const [email, setEmail] = useState(userData?.email ? userData?.email : "");
  const [address, setaddress] = useState(
    userData?.address1 ? userData?.address1 : ""
  );
  const [title, setTitle] = useState(userData?.title ? userData?.title : "");
  const [displayName, setDisplay_name] = useState(userData?.displayName);
  const [address2, setAddress2] = useState(
    userData?.address2 ? userData?.address2 : ""
  );
  const [flag, setFlag] = useState(true);
  const [city, setCity] = useState(userData?.city ? userData?.city : "");
  const [state, setState] = useState(userData?.state ? userData?.state : "");
  const [country, setCountry] = useState(
    userData?.country ? userData?.country : "India"
  );
  const [zip, setZip] = useState(userData?.pinCode ? userData?.pinCode : "");
  const [selectedLanguage, setSelectedlanguage] = React.useState("en-in");
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [phone, setPhone] = useState(
    userData?.phone ? userData.phone?.replace?.("+", "") : "91"
  );
  const [load, setLoad] = useState(false);
  const [err, setErr] = useState([]); // console.log(userData.phone?.replace?.("+91", ""));
  const ref = useRef(null);
  // const [type, setType] = useState("");
  const [toggle, setToggle] = useState(false);
  const [emailFlag, setemailFlag] = useState(false);
  const [emailFlagLoading, setemailFlagLoading] = useState(false);
  const [pendingFlag, setpendingFlag] = useState(false);
  const [verifyFlag, setVerifyFlag] = useState(false);
  const [msg, setMsg] = useState("");
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useeffects--------------------------------------------------------//
  const range = (start, end) => {
    var ans = [];
    for (let i = start; i <= end; i++) {
      ans.push(i);
    }
    return ans;
  };
  useEffect(() => {
    // console.log("yes");
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    setActive("my-profile");
    setActivePath("My Account-My Profile");
    // getEmailStatus();
    // getStats();
  }, []);

  useEffect(() => {
    setToggle(!toggle);
  }, []);

  useEffect(() => {
    if (
      userData?.displayName?.toLowerCase() === displayName.toLowerCase() &&
      userData?.email === email &&
      userData?.title === title &&
      userData?.firstName === firstName &&
      userData?.lastName === lastName &&
      userData?.dateOfBirth === moment(startDate).format("YYYY-MM-DD") &&
      userData?.gender === gender &&
      userData?.gothram === gothram &&
      userData?.address1 === address &&
      userData?.city === city &&
      userData?.state === state &&
      userData?.country === country &&
      userData?.pinCode === zip &&
      userData?.idProofNumber === docNumber &&
      userData?.idProofType === docType &&
      userData?.docFileUrl === uploadLink
    ) {
      if (userData?.address2) {
        if (userData?.address2 === address2) {
          // console.log("in here");
          setFlag(true);
        } else {
          // console.log("address ");
          setFlag(false);
        }
      } else {
        if (address2 !== "") {
          // console.log("address 2");

          setFlag(false);
        } else {
          setFlag(true);
        }
      }
    } else {
      // console.log("all equal");
      setFlag(false);
    }
  }, [
    displayName,
    lastName,
    firstName,
    city,
    address,
    address2,
    country,
    state,
    docType,
    docNumber,
    uploadLink,
    email,
    zip,
    gender,
    gothram,
    startDate,
    title,
    address2,
    toggle,
  ]);

  //-------------------------------------------------------------functions--------------------------------------------------------//

  const handleSubmit = async () => {
    setLoad(true);
    let a = true;
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (a) {
      let data = [...err];
      if (email === "") {
        data.push({ type: "email", msg: "Please enter a valid email id" });
      } else {
        if (email !== userData?.email) {
          if (!re.test(String(email).toLowerCase())) {
            data.push({ type: "email", msg: "Please enter a valid email id" });
          }
        }
      }
      if (firstName === "") {
        data.push({ type: "first", msg: "Please enter your first name" });
      } else if (firstName.length < 3) {
        data.push({ type: "first", msg: "Minimum 3 characters" });
      }
      // if (lastName === "") {
      //   data.push({type: "last", msg: "Please enter your last name"});
      // }
      if (gender === "") {
        data.push({ type: "gender", msg: "Select a gender" });
      }
      if (title === "") {
        data.push({ type: "title", msg: "Please select a title" });
      }
      if (gothram === "") {
        data.push({ type: "gothram", msg: "Please enter your gothram" });
      } else if (gothram.length < 3) {
        data.push({ type: "gothram", msg: "Minimum 3 characters" });
      }
      if (displayName === "") {
        data.push({ type: "display", msg: "Please enter your display name" });
      } else if (displayName.length < 3) {
        data.push({ type: "display", msg: "Minimum 3 characters" });
      }
      if (city === "") {
        data.push({ type: "city", msg: "Please enter a city" });
      } else if (city.length < 3) {
        data.push({ type: "city", msg: "Minimum 3 characters" });
      }
      if (startDate === "" || startDate === "Invalid date") {
        data.push({ type: "date", msg: "Please select a date" });
      }
      if (state === "") {
        data.push({ type: "state", msg: "Please select your state" });
      }
      if (address === "") {
        data.push({ type: "address", msg: "Please enter your address" });
      } else if (address.length < 3) {
        data.push({ type: "address", msg: "Minimum 3 characters" });
      }
      if (country === "") {
        data.push({ type: "country", msg: "Please select a country" });
      }
      if (docType === "") {
        data.push({ type: "docType", msg: "Select the type of id proof" });
      } else {
        if (docType === "pan") {
          let txt = docNumber.toUpperCase();
          let regex =
            /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
          let pan = {
            C: "Company",
            P: "Personal",
            H: "Hindu Undivided Family (HUF)",
            F: "Firm",
            A: "Association of Persons (AOP)",
            T: "AOP (Trust)",
            B: "Body of Individuals (BOI)",
            L: "Local Authority",
            J: "Artificial Juridical Person",
            G: "Govt",
          };
          pan = pan[txt[3]];
          if (regex.test(txt)) {
            if (pan !== "Personal") {
              data.push({ type: "docNumber", msg: "Invalid pan number" });
            }
          } else data.push({ type: "docNumber", msg: "Invalid pan number" });
        } else if (docType === "ration") {
          let regex = /^([a-zA-Z0-9]){8,12}\s*$/;
          if (!regex.test(docNumber.toUpperCase())) {
            data.push({ type: "docNumber", msg: "Invalid ration number" });
          }
        } else if (docType === "driving") {
          let regex =
            /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
          if (!regex.test(docNumber.toUpperCase())) {
            // data.push({
            //   type: "docNumber",
            //   msg: "Invalid driving license number",
            // });
          }
        } else if (docType === "voter") {
          let regex = /^([a-zA-Z]){3}([0-9]){7}?$/;
          if (!regex.test(docNumber.toUpperCase())) {
            data.push({ type: "docNumber", msg: "Invalid voter id" });
          }
        } else if (docType === "aadhar") {
          let regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
          if (!regex.test(docNumber.toUpperCase())) {
            data.push({ type: "docNumber", msg: "Invalid AADHAAR number" });
          }
        }
        if (uploadLink === "") {
          data.push({ type: "upload", msg: "Please attach a valid id proof" });
        }
      }
      if (zip === "") {
        data.push({ type: "zip", msg: "Please enter valid zip/pinCode" });
      } else if (zip !== "" && zip !== userData?.zip) {
        if (zip.length !== 6) {
          // data.push({type: "zip", msg: "Please enter valid zip/pinCode"});
        }
      }

      // if (phone === "") {
      //   data.push({type: "phone", msg: "Invalid mobile number"});
      // } else if (phone !== "" && phone !== userData.phone?.replace?.("+", "")) {

      //   if (phone.length !== 10) {
      //     data.push({type: "phone", msg: "Invalid mobile number"});
      //   } else {
      //     if (phone !== userData?.phone?.replace?.("+", "")) {
      //       try {
      //         const registered = await UserService.isRegistered(`%2B${phone}`);
      //         if (registered) {
      //           data.push({
      //             type: "phone",
      //             msg: "Phone Number is already registered.",
      //           });
      //         }
      //       } catch (error) {
      //         toast?.error(error?.message);
      //       }
      //     }
      //   }
      // }

      setErr(data);
      if (data.length !== 0) {
        setLoad(false);
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });

        return;
      }
    }

    if (
      address !== "" ||
      city !== "" ||
      state !== "" ||
      country !== "" ||
      zip !== ""
    ) {
      let data = [...err];
      if (address === "") {
        data.push({ type: "address", msg: "Please Enter address" });
      }
      if (country === "") {
        data.push({ type: "country", msg: "Please Enter Country" });
      }
      if (state === "") {
        data.push({ type: "state", msg: "Please Enter State" });
      }
      if (zip === "") {
        data.push({ type: "zip", msg: "Please Enter Zip" });
      }
      if (city === "") {
        data.push({ type: "city", msg: "Please Enter City" });
      }
      setErr(data);
      if (data.length !== 0) {
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        setLoad(false);

        return;
      }
    }

    let data = {};
    displayName !== "" && (data.displayName = capitalize(displayName));
    zip !== "" && (data.pinCode = zip);
    lastName !== "" && (data.lastName = capitalize(lastName));
    firstName !== "" && (data.firstName = capitalize(firstName));
    uploadLink !== "" &&
      (data.docFileUrl = uploadLink?.fileUrl
        ? uploadLink?.fileUrl
        : uploadLink);
    docType !== "" && (data.idProofType = docType);
    docNumber !== "" && (data.idProofNumber = docNumber);
    country !== "" && (data.country = country);
    state !== "" && (data.state = state);
    address !== "" && (data.address1 = capitalize(address));
    // phone !== "" && (data.phone = `+${phone}`);
    gothram !== "" && (data.gothram = capitalize(gothram));
    gender !== "" && (data.gender = gender);
    address2 !== "" && (data.address2 = capitalize(address2));
    title !== "" && (data.title = title);
    if (startDate === "") {
      if (userData?.dateOfBirth) {
        data.dateOfBirth = moment(userData?.dateOfBirth).format("YYYY-MM-DD");
      }
    } else {
      data.dateOfBirth = moment(startDate).format("YYYY-MM-DD");
    }

    email !== "" && (data.email = email);
    city !== "" && (data.city = capitalize(city));

    try {
      await UserService.editUserDetails(data, templeID);
      setUserData([]);
      setFlag(true);
      toast.success("Updated Profile details");
      setLoad(false);
    } catch (e) {
      setLoad(false);
      let data = [...err];
      data.push({ type: "common", msg: e?.message });
      setErr(data);
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      // toast.error(e.message);
      if (e.message === "Sesson Expired") {
        history?.push(`/${selectedLanguage}/login`);
      }
    }
  };

  const verfiyEmail = async () => {
    setemailFlagLoading(true);
    try {
      await CommsService.verifyEmail(email);
      setMsg(
        "A verification Link has been sent to your Email Address.Please verify your email and reload this page after verfication."
      );
      setemailFlag(true);
      setpendingFlag(true);
      setemailFlagLoading(false);
      setVerifyFlag(false);
    } catch (e) {
      if (
        e?.message ===
        "You have already requested an email change today. Please try again tomorrow."
      ) {
        setEmail("");
      }
      setMsg(e?.message);
      setemailFlag(true);
      setemailFlagLoading(false);
    }
  };

  const getEmailStatus = async () => {
    // created_at: "2022-01-20T09:32:46.465Z"
    // email: "samalakrishna7@gmail.com"
    // id: 2
    // sd_user_id: 5
    try {
      let res = await CommsService.getEmailStatus(userData?.email);
      // console.log(userData?.email);
      if (
        userData?.email === null &&
        res.email !== "" &&
        res.created_at &&
        moment().diff(moment(res.created_at), "days") === 0
      ) {
        setMsg(
          `You have already sent an verification email to ${res.email}. Please verify your email.`
        );
        setemailFlag(true);
      }
    } catch (e) {
      setMsg(e?.message);
      setemailFlag(true);
    }
  };

  function capitalize(str) {
    let splitStr = str?.toLowerCase?.().split?.(" ");
    for (let i = 0; i < splitStr?.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr?.join(" ");
  }

  const handleFileUpload = async (e) => {
    setUploadFlag(true);
    try {
      if (e.target.files[0].size / 1024 / 1024 > 5) {
        setUploadFlag(false);
        document.getElementById("uploadInput").value = "";
        throw new Error(
          "Please make sure the file you are attaching are below 5 mb "
        );
      }
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      let res = await Sd_DashboardService.upload_document(
        formData,
        "myProfile"
      );
      toast.info("File Uploaded");
      setUploadLink(res);
      if (err.find((e) => e.type === "upload" || e.type !== "common")) {
        let data = err.filter((e) => {
          if (e.type !== "upload" && e.type !== "common") {
            return e;
          }
        });
        setErr(data);
      }
      setUploadFlag(false);
    } catch (e) {
      toast.error(e.message);
      setUploadLink("");
      setUploadFlag(false);
      document.getElementById("uploadInput").value = "";

      // let upload = document.getElementById("upload-image-donation");
      // upload.files = [];
      if (e.message === "Session Expired") {
        logout();
        history?.push(
          `/${selectedLanguage}/devotee/signin?to=devotee-app/my-account/profile`
        );
      }
    }
  };
  // const getStats = () => {
  //   let address = 0;
  //   let documnets = 0;
  //   let overall = 0;
  //   let contact = 0;
  //   if (userData?.displayName) {
  //     overall += 10;
  //   }
  //   if (userData?.email) {
  //     overall += 10;
  //     contact += 10;
  //   }
  //   if (userData?.phone) {
  //     overall += 10;
  //     contact += 10;
  //   }

  //   if (userData?.title) {
  //     overall += 10;
  //   }

  //   if (userData?.firstName) {
  //     overall += 10;
  //   }
  //   if (userData?.lastName) {
  //     overall += 10;
  //   }
  //   if (userData?.dateOfBirth) {
  //     overall += 10;
  //   }
  //   if (userData?.gender) {
  //     overall += 10;
  //   }
  //   if (userData?.gothram) {
  //     overall += 10;
  //   }

  //   if (userData?.address1) {
  //     overall += 10;
  //     address += 10;
  //   }
  //   if (userData?.address2) {
  //     overall += 10;
  //     address += 10;
  //   }
  //   if (userData?.city) {
  //     overall += 10;
  //     address += 10;
  //   }
  //   if (userData?.state) {
  //     overall += 10;
  //     address += 10;
  //   }
  //   if (userData?.country) {
  //     overall += 10;
  //     address += 10;
  //   }
  //   if (userData?.pinCode) {
  //     overall += 10;
  //     address += 10;
  //   }
  //   if (userData?.idProofNumber) {
  //     overall += 10;
  //     documnets += 10;
  //   }
  //   if (userData?.idProofType) {
  //     overall += 10;
  //     documnets += 10;
  //   }
  //   if (userData?.docFileUrl) {
  //     overall += 10;
  //     documnets += 10;
  //   }

  //   setDocumentStats(~~(documnets / 0.3));
  //   setAddressStats(~~(address / 0.6));
  //   setOverall(~~(overall / 1.8));
  //   setContactStats(~~(contact / 0.2));
  // };

  //-------------------------------------------------------------functions--------------------------------------------------------//

  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {load ? (
        <Sd_loading />
      ) : (
        <div className="col-xs-12 col-sm-12 col-md-11 col-lg-10 pl-0 xs-pr-0 sd-profile ">
          <h2 className="sd-side-heading fw400">
            <span className="fw700">My</span> Profile
          </h2>
          <div className="clearfix" ref={ref}>
            {/* <div className="spec">
              <Sd_test
                label="Overall profile"
                stroke="#2f3293"
                percentage={overall}
              />
              <Sd_test
                label="Contact Info"
                stroke="#991403"
                percentage={contactStats}
              />
              <Sd_test
                label="Address"
                stroke="#9b59b6"
                percentage={addressStats}
              />
              <Sd_test
                label="Documents"
                stroke="#1abc9c"
                percentage={documnetStats}
              />
            </div> */}

            <form className="sd-form">
              {/* <hr className="clearfix mb-24" /> */}

              <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 xssm-pr-0 pl-0 pr-0">
                <label>
                  Display Name<span className="sd-imp">*</span>
                </label>
                <input
                  type="text"
                  maxLength="30"
                  autoFocus
                  placeholder="Please enter a display name"
                  value={displayName}
                  className={
                    err.find((e) => e.type === "display")
                      ? "form-control sd-red"
                      : displayName !== ""
                      ? "sd-voilet sd-more form-control "
                      : "form-control"
                  }
                  onChange={(e) => {
                    if (
                      /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                      e.target.value === ""
                    ) {
                      if (
                        err.find(
                          (e) => e.type === "display" || e.type === "common"
                        )
                      ) {
                        let data = err.filter((e) => {
                          if (e.type !== "display" && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      setDisplay_name(e.target.value);
                    }
                  }}
                />{" "}
                <p className="sd-error-m ">
                  {err.find((e) => e.type === "display")?.msg}
                </p>
              </div>
              <div className="form-group col-xs-12 sd-profile-mobile col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0 mb-32">
                <label
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>
                    {" "}
                    Mobile Number<span className="sd-imp">*</span>
                  </span>
                </label>
                <div>
                  <span className="prefix">
                    <img src={sd_india_flag} alt="india" />{" "}
                    <sapn>+{phone}</sapn>{" "}
                  </span>

                  <PhoneInput
                    disabled
                    inputExtraProps={{
                      autoFocus: true,
                      enableSearch: true,
                      countryCodeEditable: false,
                    }}
                    placeholder="Enter Your 10 digit mobile number"
                    maxLength="15"
                    countryCodeEditable={false}
                    containerClass={
                      err.find((e) => e.type === "phone")
                        ? "sd-phone-voilet sd-red"
                        : phone !== ""
                        ? "sd-phone-voilet"
                        : ""
                    }
                    country="in"
                    value={phone}
                    onChange={(e) => {
                      if (
                        err?.find(
                          (e) => e.type === "phone" || e.type === "common"
                        )
                      ) {
                        let array = err.filter((e) => {
                          if (e.type !== "phone" && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(array);
                      }
                      setPhone(e);
                    }}
                  />

                  {/* <input
                    type="number"
                    maxLength="30"
                    placeholder="Enter your 10 digit mobile number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className={
                      err.find((e) => e.type === "phone")
                        ? "form-control sd-red"
                        : phone !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={phone}
                    onChange={(e) => {
                      if (
                        err.find(
                          (e) => e.type === "phone" || e.type === "common"
                        )
                      ) {
                        let data = err.filter((e) => {
                          if (e.type !== "phone" && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      // if (e.target.value.length <= 10) {
                      //   setPhone(e.target.value);
                      // }
                    }}
                    readOnly
                  /> */}
                  {userData.phone?.replace?.("+", "") === phone && (
                    <span className="suffix">
                      <img src={sd_check} alt="india" />
                    </span>
                  )}
                  {/* <p className="sd-error-m mr-15">
                    {err.find((e) => e.type === "phone")?.msg}
                  </p> */}
                </div>{" "}
                {/* <ReactPhoneInput
                inputExtraProps={{
                  autoFocus: true,
                  enableSearch: true,
                }}
                containerClass={phone !== "" ? "sd-phone-voilet" : ""}
                country="in"
                value={phone}
                onChange={(e) => {

                  setPhone(e);
                }}
              /> */}
              </div>
              <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 pr-0">
                <label
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>
                    {" "}
                    Email Address<span className="sd-imp">*</span>
                  </span>{" "}
                  {/* <span
                    style={{color: "#2f3193"}}
                    onClick={() => {
                      setModal(true);
                      setType("email");
                    }}
                  >
                    Update Email Address
                  </span> */}
                </label>
                <div>
                  <input
                    type="text"
                    maxLength="30"
                    className={
                      err.find((e) => e.type === "email")
                        ? "form-control sd-red sd-email"
                        : email !== ""
                        ? "form-control sd-voilet sd-email"
                        : "form-control sd-email"
                    }
                    style={{ textTransform: "none" }}
                    placeholder="Please enter a valid email id"
                    value={email}
                    onChange={(e) => {
                      if (
                        err.find(
                          (e) => e.type === "email" || e.type === "common"
                        )
                      ) {
                        let array = err.filter((e) => {
                          if (e.type !== "email" && e.type !== "common") {
                            return e;
                          }
                        });

                        setErr(array);
                      }
                      setEmail(e.target.value);
                    }}
                  />
                  {/* {email === "" ? (
                    ""
                  ) : userData.email === email && !verifyFlag ? (
                    <span className="sd-email-verified">
                      {" "}
                      <img src={sd_check} alt="verified" />{" "}
                    </span>
                  ) : (
                    <span className="sd-email-verified-s">
                      {" "}
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          if (!pendingFlag && !emailFlagLoading) {
                            verfiyEmail();
                          }
                        }}
                      >
                        {pendingFlag ? "Pending" : "Verify"}
                      </button>
                    </span>
                  )}{" "} */}
                  <p
                    className="sd-error-m"
                    style={{ bottom: "-60px !important" }}
                  >
                    {err.find((e) => e.type === "email")?.msg}
                  </p>
                </div>
              </div>

              <hr className="clearfix mb-24" />
              <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 xssm-pr-0 pl-0 spec">
                <label style={{ paddingRight: "20px", marginBottom: "0px" }}>
                  Title<span className="sd-imp">*</span>
                </label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {TITLES.map((e, i) => {
                    return (
                      <div
                        className="sd-radio-select-no-border  size"
                        key={i}
                        style={{ paddingRight: "20px" }}
                      >
                        <input
                          type="radio"
                          name={`optradio${i}`}
                          id={`test${i}`}
                          onChange={() => {}}
                          value={title}
                          checked={
                            title.toLowerCase() === e.toLowerCase()
                              ? true
                              : false
                          }
                        />
                        <label
                          htmlFor={`test${i}`}
                          className="radio-inline"
                          onClick={() => {
                            if (
                              err.find(
                                (e) => e.type === "title" || e.type === "common"
                              )
                            ) {
                              let array = err.filter((e) => {
                                if (e.type !== "title" && e.type !== "common") {
                                  return e;
                                }
                              });

                              setErr(array);
                            }
                            setTitle(e);
                          }}
                        >
                          <p>{e}</p>
                        </label>
                      </div>
                    );
                  })}
                </div>
                <p
                  className="sd-error-m mr-15"
                  style={{ left: "0px", right: "auto" }}
                >
                  {err.find((e) => e.type === "title")?.msg}
                </p>
              </div>

              <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 xssm-pr-0 pl-0">
                <label>
                  First Name<span className="sd-imp">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Please enter a first name"
                  value={firstName}
                  maxLength="30"
                  className={
                    err.find((e) => e.type === "first")
                      ? "sd-red form-control"
                      : firstName !== ""
                      ? "sd-voilet sd-more form-control "
                      : "form-control"
                  }
                  onChange={(e) => {
                    if (
                      /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                      e.target.value === ""
                    ) {
                      if (
                        err.find(
                          (e) => e.type === "first" || e.type === "common"
                        )
                      ) {
                        let array = err.filter((e) => {
                          if (e.type !== "first" && e.type !== "common") {
                            return e;
                          }
                        });

                        setErr(array);
                      }
                      setFirstName(e.target.value);
                    }
                  }}
                />{" "}
                <p className="sd-error-m mr-15">
                  {err.find((e) => e.type === "first")?.msg}
                </p>
              </div>
              <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 xssm-pl-0 pr-0">
                <label>Last Name</label>
                <input
                  placeholder="Please enter a last name"
                  value={lastName}
                  maxLength="30"
                  type="text"
                  className={
                    err.find((e) => e.type === "last")
                      ? "sd-red form-control"
                      : lastName !== ""
                      ? "sd-voilet form-control "
                      : "form-control"
                  }
                  onChange={(e) => {
                    if (
                      /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                      e.target.value === ""
                    ) {
                      if (
                        err.find(
                          (e) => e.type === "last" || e.type === "common"
                        )
                      ) {
                        let array = err.filter((e) => {
                          if (e.type !== "last" && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(array);
                      }
                      setlastName(e.target.value);
                    }
                  }}
                />
                <p className="sd-error-m">
                  {err.find((e) => e.type === "last")?.msg}
                </p>
              </div>

              <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pl-0 xssm-pr-0 pl-0">
                <label>
                  Date of Birth<span className="sd-imp">*</span>
                </label>
                <div
                  className={
                    err.find((e) => e.type === "date")
                      ? "sd-red sd-dob customDatePickerWidth"
                      : startDate !== ""
                      ? "sd-voilet sd-dob customDatePickerWidth"
                      : "sd-dob customDatePickerWidth"
                  }
                >
                  <DatePicker
                    renderCustomHeader={({ date, changeYear, changeMonth }) => (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <select
                          className="monthYearSelectContainer"
                          value={getYear(date)}
                          onChange={({ target: { value } }) =>
                            changeYear(value)
                          }
                        >
                          {range(
                            getYear(new Date()) - 100,
                            getYear(new Date())
                          ).map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <select
                          className="monthYearSelectContainer"
                          value={months[getMonth(date)]}
                          onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                          }
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    allowSameDay={false}
                    placeholderText={"DD/MM/YYYY"}
                    dateFormat={"dd/MM/yyyy"}
                    showDisabledMonthNavigation
                    style={{
                      width: "90%",
                      border: "none",
                      backgroundColor: "blue",
                    }}
                    maxDate={addDays(new Date(), -3652)}
                    clearIcon={null}
                    selected={startDate}
                    onChange={(date) => {
                      if (
                        err.find(
                          (e) => e.type === "date" || e.type === "common"
                        )
                      ) {
                        let data = err.filter((e) => {
                          if (e.type !== "date" && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      setStartDate(date);
                    }}
                  />
                  <p className="sd-error-m" style={{ fontSize: "15.4px" }}>
                    {err.find((e) => e.type === "date")?.msg}
                  </p>
                </div>
              </div>
              <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pl-0 ">
                <label>
                  Gender<span className="sd-imp">*</span>
                </label>
                <select
                  className={
                    err.find((e) => e.type === "gender")
                      ? "form-control sd-red"
                      : gender !== ""
                      ? "form-control sd-voilet"
                      : "form-control"
                  }
                  placeholder="Select"
                  value={gender}
                  onChange={(e) => {
                    if (
                      err.find(
                        (e) => e.type === "gender" || e.type === "common"
                      )
                    ) {
                      let data = err.filter((e) => {
                        if (e.type !== "gender" && e.type !== "common") {
                          return e;
                        }
                      });
                      setErr(data);
                    }
                    setGender(e.target.value);
                  }}
                >
                  <option key="" value="" style={{ display: "none" }}>
                    Select a gender
                  </option>
                  <option key="male" value="male">
                    Male
                  </option>
                  <option key="female" value="female">
                    Female
                  </option>
                  <option key="others" value="others">
                    Others
                  </option>
                </select>
                <p className="sd-error-m mr-15">
                  {err.find((e) => e.type === "gender")?.msg}
                </p>
              </div>

              <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pr-0 mb-32 ">
                <label>
                  Gothram<span className="sd-imp">*</span>
                </label>
                <input
                  type="text"
                  maxLength="30"
                  placeholder="Please enter your gothram."
                  className={
                    err.find((e) => e.type === "gothram")
                      ? "form-control sd-red"
                      : gothram !== ""
                      ? "form-control sd-voilet"
                      : "form-control"
                  }
                  value={gothram}
                  onChange={(e) => {
                    if (
                      /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                      e.target.value === ""
                    ) {
                      if (
                        err.find(
                          (e) => e.type === "gothram" || e.type === "common"
                        )
                      ) {
                        let data = err.filter((e) => {
                          if (e.type !== "gothram" && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      setGothram(e.target.value);
                    }
                  }}
                />{" "}
                <p className="sd-error-m ">
                  {err.find((e) => e.type === "gothram")?.msg}
                </p>
              </div>

              <hr className="clearfix mb-24"></hr>
              <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                <label>
                  Address Lane 1<span className="sd-imp">*</span>
                </label>
                <input
                  value={address}
                  maxLength="50"
                  type="text"
                  placeholder="House/building number, street number
                  "
                  className={
                    err.find((e) => e.type === "address")
                      ? "sd-red form-control"
                      : address !== ""
                      ? "sd-voilet form-control "
                      : "form-control"
                  }
                  onChange={(e) => {
                    if (
                      CommonRegex.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      if (
                        err.find(
                          (e) => e.type === "address" || e.type === "common"
                        )
                      ) {
                        let data = err.filter((e) => {
                          if (e.type !== "address" && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      setaddress(e.target.value);
                    }
                  }}
                />{" "}
                <p className="sd-error-m mr-15">
                  {err.find((e) => e.type === "address")?.msg}
                </p>
              </div>
              <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 pr-0">
                <label>Address Lane 2</label>
                <input
                  placeholder="Building name, street name, etc"
                  maxLength="50"
                  value={address2}
                  type="text"
                  className={
                    address2 !== "" ? "sd-voilet form-control " : "form-control"
                  }
                  onChange={(e) => {
                    if (
                      CommonRegex.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      setAddress2(e.target.value);
                    }
                  }}
                />{" "}
              </div>
              <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                <label>
                  City<span className="sd-imp">*</span>
                </label>
                <input
                  type="text"
                  value={city}
                  placeholder="Enter your city"
                  maxLength="30"
                  className={
                    err.find((e) => e.type === "city")
                      ? "sd-red form-control"
                      : city !== ""
                      ? "sd-voilet form-control "
                      : "form-control"
                  }
                  onChange={(e) => {
                    if (
                      CommonRegex.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      if (
                        err.find(
                          (e) => e.type === "city" || e.type === "common"
                        )
                      ) {
                        let data = err.filter((e) => {
                          if (e.type !== "city" && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      setCity(e.target.value);
                    }
                  }}
                />{" "}
                <p className="sd-error-m mr-15">
                  {err.find((e) => e.type === "city")?.msg}
                </p>
              </div>
              <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 pr-0">
                <label>
                  ZIP/Pincode<span className="sd-imp">*</span>
                </label>
                <input
                  type="text"
                  onWheel={(event) => event.currentTarget.blur()}
                  className={
                    err.find((e) => e.type === "zip")
                      ? "form-control sd-red"
                      : zip !== ""
                      ? "form-control sd-voilet"
                      : "form-control"
                  }
                  placeholder="Enter your zip/pinCode"
                  value={zip}
                  maxLength={12}
                  onChange={(e) => {
                    if (
                      CommonRegex.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      if (
                        err.find((e) => e.type === "zip" || e.type === "common")
                      ) {
                        let data = err.filter((e) => {
                          if (e.type !== "zip" && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      if (e.target.value.length < 12) {
                        setZip(e.target.value.toUpperCase());
                      }
                    }
                  }}
                />{" "}
                <p className="sd-error-m">
                  {err.find((e) => e.type === "zip")?.msg}
                </p>
              </div>

              <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0 mb-32">
                <label>
                  State<span className="sd-imp">*</span>
                </label>
                <RegionDropdown
                  country={country}
                  classes={
                    err.find((e) => e.type === "state")
                      ? "sd-red w-100 sd-border-none"
                      : state !== ""
                      ? " sd-voilet w-100 sd-border-none"
                      : "w-100 sd-border-none"
                  }
                  value={state}
                  showDefaultOption={true}
                  blankOptionLabel="Select a State"
                  onChange={(val) => {
                    if (
                      err.find((e) => e.type === "state" || e.type === "common")
                    ) {
                      let data = err.filter((e) => {
                        if (e.type !== "state" && e.type !== "common") {
                          return e;
                        }
                      });
                      setErr(data);
                    }
                    setState(val);
                  }}
                />
                <p className="sd-error-m mr-15">
                  {err.find((e) => e.type === "state")?.msg}
                </p>
              </div>

              <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 pr-0">
                <label>
                  Country<span className="sd-imp">*</span>
                </label>
                <CountryDropdown
                  value={country}
                  classes={
                    err.find((e) => e.type === "country")
                      ? "sd-red w-100 sd-border-none"
                      : country !== ""
                      ? " sd-voilet w-100 sd-border-none"
                      : "w-100 sd-border-none"
                  }
                  showDefaultOption={true}
                  defaultOptionLabel="Select a Country"
                  onChange={(val) => {
                    if (
                      err.find(
                        (e) => e.type === "country" || e.type === "common"
                      )
                    ) {
                      let data = err.filter((e) => {
                        if (e.type !== "country" && e.type !== "common") {
                          return e;
                        }
                      });
                      setErr(data);
                    }
                    setCountry(val);
                  }}
                />
                <p className="sd-error-m">
                  {err.find((e) => e.type === "country")?.msg}
                </p>
              </div>
              <hr className="clearfix mb-24"></hr>

              <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                <label>
                  Select Photo ID Proof<span className="sd-imp">*</span>
                </label>
                <select
                  placeholder="Select"
                  className={
                    err.find((e) => e.type === "docType")
                      ? "sd-red form-control"
                      : docType !== ""
                      ? "form-control sd-voilet"
                      : "form-control"
                  }
                  value={docType}
                  onChange={(e) => {
                    if (
                      err.find(
                        (e) =>
                          e.type === "docType" ||
                          e.type === "common" ||
                          e.type === "docNumber"
                      )
                    ) {
                      let data = err.filter((e) => {
                        if (
                          e.type !== "docType" &&
                          e.type !== "common" &&
                          e.type !== "docNumber"
                        ) {
                          return e;
                        }
                      });
                      setErr(data);
                    }
                    setDockType(e.target.value);
                  }}
                >
                  <option style={{ display: "none" }} value="" key="">
                    Select a valid id proof
                  </option>
                  <option key="pan" value="pan">
                    PAN
                  </option>
                  <option key="aadhar" value="aadhar">
                    AADHAAR
                  </option>
                  <option key="driving" value="driving">
                    Driving License
                  </option>
                  <option key="ration" value="ration">
                    Ration Card
                  </option>
                  <option key="voter" value="voter">
                    Voter ID
                  </option>
                </select>
                <p className="sd-error-m mr-15">
                  {err.find((e) => e.type === "docType")?.msg}
                </p>
              </div>

              <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 pr-0">
                <label>
                  Photo ID Number<span className="sd-imp">*</span>
                </label>
                <input
                  type="text"
                  maxLength="30"
                  placeholder="Please enter your id number"
                  className={
                    err.find((e) => e.type === "docNumber")
                      ? "form-control sd-red"
                      : docNumber !== ""
                      ? "form-control sd-voilet"
                      : "form-control"
                  }
                  style={{ paddingLeft: "15px", textTransform: "none" }}
                  value={docNumber}
                  onChange={(e) => {
                    if (
                      /^[#.0-9a-zA-Z\s]+$/.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      if (err.find((e) => e.type === "docNumber")) {
                        let data = err.filter((e) => {
                          if (e.type !== "docNumber" && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      setdocNumber(
                        e.target.value?.toUpperCase()?.replaceAll?.(" ", "")
                      );
                    }
                  }}
                />
                <p className="sd-error-m">
                  {err.find((e) => e.type === "docNumber")?.msg}
                </p>
              </div>

              <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0 mb-32">
                <label>
                  Upload Photo ID Proof<span className="sd-imp">*</span>
                </label>
                {uploadLink !== "" && (
                  <div className="upload-button">
                    <p>
                      {" "}
                      {uploadLink?.originalname
                        ? uploadLink?.originalname
                        : "File Uploaded"}{" "}
                    </p>{" "}
                    <img
                      src={sd_trash}
                      alt="delete"
                      onClick={() => {
                        if (err.find((e) => e.type === "upload")) {
                          let data = err.filter((e) => {
                            if (e.type !== "upload" && e.type === "common") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setUploadLink("");
                      }}
                    />
                  </div>
                )}
                {uploadFlag && (
                  <div className="upload-button">
                    <p>Uploading...</p>{" "}
                  </div>
                )}
                {uploadLink === "" && !uploadFlag && (
                  <input
                    style={{ padding: "10px 12px" }}
                    type="file"
                    placeholder="Browse"
                    id="uploadInput"
                    accept="image/png, image/jpeg,image/jpg,.pdf"
                    className={
                      err.find((e) => e.type === "upload")
                        ? "form-control sd-red"
                        : uploadLink !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    onChange={(e) => {
                      handleFileUpload(e);
                    }}
                  />
                )}{" "}
                {err.find((e) => e.type === "upload") && (
                  <p className="sd-error-m mr-15">
                    {err.find((e) => e.type === "upload")?.msg}
                  </p>
                )}
              </div>

              <hr className="clearfix "></hr>

              <p className="sd-error-m" style={{ color: "red" }}>
                {err.find((e) => e.type === "common")?.msg}
              </p>

              <div className=" col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 sd-form-button-update mt-5 clearfix mb-20">
                <a
                  className={flag ? "sd-btn-orange opace" : "sd-btn-orange"}
                  onClick={() => {
                    if (!uploadFlag) {
                      if (!flag) {
                        handleSubmit();
                      }
                    }
                  }}
                >
                  Update Profile{" "}
                  <img
                    className="sd-arrow-btn ml-5"
                    src={sd_dashboard_side_arrow}
                    alt=">"
                  />
                </a>
              </div>
            </form>
            {/* <Sd_POPup
              type={type}
              setType={setType}
              modal={modal}
              setModal={setModal}
            /> */}

            {emailFlag && (
              <div className="sd-profile-popup">
                <div
                  className="inner"
                  style={{
                    // height: "150px",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "150px",
                  }}
                >
                  <h2>{msg}</h2>
                  <button
                    style={{
                      color: "white",
                      backgroundColor: "#991403",
                      marginTop: "10px",
                      alignSelf: "center",
                    }}
                    className="btn"
                    onClick={() => {
                      setMsg("");
                      setemailFlag(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}{" "}
    </div>
  );
};
export default Sd_dashboard_ma_myprofile;

import React, { useEffect, useContext } from "react";
import { withRouter } from "react-router";
import {
  SD_Right_Arrow_white,
  sd_rupee,
  sd_script,
  sd_share,
  sd_trash,
} from "../../../Assets/imageList";
import { SD_Context } from "../../../context/sd-context";
// import IndiaTime from "../../../Utils/sd-moment/IndiaTime";
import "../../../Utils/sd-styles/sd-favorities.scss";
// import "../../../Utils/sd-styles/sd-css/main.css";
const Sd_dashboard_ma_favorites = ({ history }) => {
  //-------------------------------------------------------------states--------------------------------------------------------//

  const { setActive, setActivePath } = useContext(SD_Context);
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [copyFlag, setCopyFlag] = React.useState(false);
  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");
  const { fav, removeFavorite } = useContext(SD_Context);

  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useffect--------------------------------------------------------//
  useEffect(() => {
    setActive("my-favorites");
    setActivePath("My Account-My Favorites");

    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);
  //-------------------------------------------------------------useefect--------------------------------------------------------//

  //-------------------------------------------------------------functions--------------------------------------------------------//

  const getImage = (data) => {
    return (
      <img
        className="border-l"
        src={sd_trash}
        alt="active"
        onClick={() => {
          removeFavorite(data.id);
        }}
      />
    );
  };
  const onShareFunction = (path) => {
    setCopyFlag(true);
    navigator.clipboard.writeText(path);
    setTimeout(() => {
      setCopyFlag(false);
    }, 2000);
  };
  const displayFunction = () => {
    let res = fav.map((item, i) => {
      switch (item.type) {
        case "parokshaSeva": {
          return (
            <div className="seva-div" key={i}>
              <img
                className="head-image"
                src={item.seva.imageUrl}
                alt={"image"}
              />
              <h4>{item?.seva?.name}</h4>
              <h5>{item.seva.description}</h5>
              <h6
                onClick={() => {
                  history?.push(
                    `/${selectedlanguage}/sevas-and-darshanam/paroksha-seva/${item?.seva?.name
                      ?.replace?.(/\s/g, "-")
                      .toLowerCase()}`
                  );
                }}
              >
                More Info <img src={SD_Right_Arrow_white} alt=">" />
              </h6>
              <div className="sd-timings">
                <h3>
                  {/* <i className="far fa-clock"></i> */}
                  {/* {IndiaTime(item.seva?.start_date).format("hh:mm A") ===
                  IndiaTime(item.seva?.end_date).format("hh:mm A") ? (
                    <>
                      {item.seva.start_date &&
                        IndiaTime(item.seva.start_date).format("hh:mm A")}
                    </>
                  ) : (
                    <>
                      {item.seva.start_date &&
                        IndiaTime(item.seva.start_date).format("hh:mm A")}{" "}
                      -
                      {item.seva.end_date &&
                        IndiaTime(item.seva.end_date).format("hh:mm A")}
                    </>
                  )} */}
                </h3>
                <div>
                  <img
                    className="padding-r"
                    src={sd_share}
                    alt="share"
                    onClick={() => {
                      onShareFunction(
                        `https://srikanipakadevasthanam.org/${selectedlanguage}/sevas-and-darshanam/paroksha-seva/${item?.seva?.name
                          ?.replace?.(/\s/g, "-")
                          .toLowerCase()}`
                      );
                    }}
                  />
                  {getImage(item)}
                </div>
              </div>
              <div>
                <p>
                  <img src={sd_rupee} alt="Rs." /> {item.seva.price}
                </p>
                <button
                  onClick={() => {
                    if (localStorage.getItem("accessToken")) {
                      history?.push(
                        `/${selectedlanguage}/devotee-app/online-booking/paroksha-seva/booking/${item?.seva?.name
                          ?.replace?.(/\s/g, "-")
                          .toLowerCase()}`
                      );
                    } else {
                      history?.push(
                        `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/paroksha-seva/booking/${item?.seva?.name
                          ?.replace?.(/\s/g, "-")
                          .toLowerCase()}`
                      );
                    }
                  }}
                >
                  Book Now{" "}
                  <img className="imgs" src={SD_Right_Arrow_white} alt=">" />
                </button>
              </div>
              {copyFlag && <div className="sd-copy">Link Copied</div>}{" "}
            </div>
          );
        }
        case "pratyakshaSeva":
          return (
            <div className="seva-div" key={i}>
              <img
                className="head-image"
                src={item.seva.imageUrl}
                alt={"image"}
              />
              <h4>{item?.seva?.name}</h4>
              <h5>{item?.seva?.description}</h5>
              <h6
                onClick={() => {
                  history?.push(
                    `/${selectedlanguage}/sevas-and-darshanam/pratyaksha-seva/${item?.seva?.name
                      ?.replace?.(/\s/g, "-")
                      .toLowerCase()}`
                  );
                }}
              >
                More Info <img src={SD_Right_Arrow_white} alt=">" />
              </h6>
              <div className="sd-timings">
                <h3>
                  {/* <i className="far fa-clock"></i> */}
                  {/* {IndiaTime(item.seva?.start_date).format("hh:mm A") ===
              IndiaTime(item.seva?.end_date).format("hh:mm A") ? (
                <>
                  {item.seva.start_date &&
                    IndiaTime(item.seva.start_date).format("hh:mm A")}
                </>
              ) : (
                <>
                  {item.seva.start_date &&
                    IndiaTime(item.seva.start_date).format("hh:mm A")}{" "}
                  -
                  {item.seva.end_date &&
                    IndiaTime(item.seva.end_date).format("hh:mm A")}
                </>
              )} */}
                </h3>
                <div>
                  <img
                    className="padding-r"
                    src={sd_share}
                    alt="share"
                    onClick={() => {
                      onShareFunction(
                        `https://srikanipakadevasthanam.org/${selectedlanguage}/sevas-and-darshanam/pratyaksha-seva/${item?.seva?.name
                          ?.replace?.(/\s/g, "-")
                          .toLowerCase()}`
                      );
                    }}
                  />
                  {getImage(item)}
                </div>
              </div>
              <div>
                <p>
                  <img src={sd_rupee} alt="Rs." /> {item.seva.price}
                </p>
                <button
                  onClick={() => {
                    if (localStorage.getItem("accessToken")) {
                      history?.push(
                        `/${selectedlanguage}/devotee-app/online-booking/pratyaksha-seva/booking/${item?.seva?.name
                          ?.replace?.(/\s/g, "-")
                          .toLowerCase()}`
                      );
                    } else {
                      history?.push(
                        `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-seva/booking/${item?.seva?.name
                          ?.replace?.(/\s/g, "-")
                          .toLowerCase()}`
                      );
                    }
                  }}
                >
                  Book Now{" "}
                  <img className="imgs" src={SD_Right_Arrow_white} alt=">" />
                </button>
              </div>
              {copyFlag && <div className="sd-copy">Link Copied</div>}{" "}
            </div>
          );

        case "darshanam":
          return (
            <div className="seva-div" key={i}>
              <img
                className="head-image"
                src={item.darshanam.imageUrl}
                alt={"image"}
              />
              <h4>{item.darshanam.name}</h4>
              <h5>{item.darshanam.description}</h5>
              <h6
                onClick={() => {
                  history?.push(
                    `/${selectedlanguage}/donations/${item?.darshanam?.name
                      ?.replace?.(/\s/g, "-")
                      .toLowerCase()}`
                  );
                }}
              >
                More Info <img src={SD_Right_Arrow_white} alt=">" />
              </h6>
              <div className="sd-timings">
                {/* <h3>
                  <i className="far fa-clock"></i>
                </h3> */}
                <div>
                  <img
                    className="padding-r"
                    src={sd_share}
                    alt="share"
                    onClick={() => {
                      onShareFunction(
                        `https://srikanipakadevasthanam.org/${selectedlanguage}/donations/${item?.darshanam?.name
                          ?.replace?.(/\s/g, "-")
                          .toLowerCase()}`
                      );
                    }}
                  />
                  {getImage(item)}
                </div>
              </div>
              <div>
                <p>
                  <img src={sd_rupee} alt="Rs." /> {item.darshanam.price}
                </p>
                <button
                  onClick={() => {
                    if (localStorage.getItem("accessToken")) {
                      history?.push(
                        `/${selectedlanguage}/devotee-app/online-booking/donations`
                      );
                    } else {
                      history?.push(
                        `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/donations`
                      );
                    }
                  }}
                >
                  Book Now{" "}
                  <img className="imgs" src={SD_Right_Arrow_white} alt=">" />
                </button>
              </div>
              {copyFlag && <div className="sd-copy">Link Copied</div>}{" "}
            </div>
          );

        case "donation":
          return (
            <div className="seva-div" key={i}>
              <img
                className="sd-head-image"
                src={item.donation.imageUrl}
                alt={"image"}
              />
              <h4>{item?.donation?.name}</h4>
              <h5>{item?.donation?.description}</h5>
              <h6
                onClick={() => {
                  history?.push(
                    `/${selectedlanguage}/donations/${item?.donation?.name
                      ?.replace?.(/\s/g, "-")
                      .toLowerCase()}`
                  );
                }}
              >
                More Info <img src={SD_Right_Arrow_white} alt=">" />
              </h6>
              <div className="sd-timings">
                <div>
                  <img
                    className="padding-r"
                    src={sd_share}
                    alt="share"
                    onClick={() => {
                      onShareFunction(
                        `https://srikanipakadevasthanam.org/${selectedlanguage}/donations/${item?.donation?.name
                          ?.replace?.(/\s/g, "-")
                          .toLowerCase()}`
                      );
                    }}
                  />
                  {getImage(item)}
                </div>
              </div>
              <div>
                <p>
                  <i className=""></i> {item.donation.price}
                </p>
                <button
                  onClick={() => {
                    if (localStorage.getItem("accessToken")) {
                      history?.push(
                        `/${selectedlanguage}/devotee-app/online-booking/donations?to=${item?.donation?.name
                          ?.replace?.(/\s/g, "-")
                          .toLowerCase()}`
                      );
                    } else {
                      history?.push(
                        `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/donations?to=${item?.donation?.name
                          ?.replace?.(/\s/g, "-")
                          .toLowerCase()}`
                      );
                    }
                  }}
                >
                  Donate Now
                  <img className="imgs" src={SD_Right_Arrow_white} alt=">" />
                </button>
              </div>
              {copyFlag && <div className="sd-copy">Link Copied</div>}{" "}
            </div>
          );

        default:
          break;
      }
    });
    if (res.length === 0) {
      return (
        <p className="flex-c w-100 border-c pb-30  pt-20 br-4">
          <img
            src={sd_script}
            alt="#"
            height="80px"
            width="80px"
            className="mr-5 mb-5 opace"
          />
          You have not marked your favourites yet! Mark your favourites for easy
          access.
        </p>
      );
    } else {
      return res;
    }
  };
  //-------------------------------------------------------------functions--------------------------------------------------------//

  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      <div className="col-xs-12 pl-0 sd-profile ">
        <h2 className="sd-side-heading fw400">
          <span className="fw700">My</span> Favorites
        </h2>
        <div className="sd-favorities">{displayFunction()}</div>
      </div>
    </div>
  );
};
export default withRouter(Sd_dashboard_ma_favorites);

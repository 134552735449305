import React, { useState, useContext, useEffect, useRef } from "react";
import {
  sd_btn_loading,
  SD_Dropdown_Arrow_icon,
  SD_Right_Arrow_white,
} from "../../../../Assets/imageList";
import "../../../../Utils/sd-styles/sd-css/main.css";
import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
import { SD_Context } from "../../../../context/sd-context";

import "../../../../Utils/sd-styles/sd-dashboard.scss";
//import "react-day-picker/lib/style.css";
// import moment from "moment";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PratyakshaSevaService from "../../../../services/sd-pratyakshaseva.service";
import DatePicker from "react-datepicker";
import moment from "moment";
import ParokshaSevaService from "../../../../services/sd-parokshaseva.service";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";

const Sd_dashboard_parokshaseva_seva_details = ({ history, match }) => {
  const [loading, setLoading] = useState(true);
  const {
    setActivePath,
    setActive,
    setCurrentBooking,
    currentBooking,
    parokshaSevaData,
    setParokshaSevaData,
    userData,
    devoteeList,
    logout,
    AddressRegex,
  } = useContext(SD_Context);

  const ref = useRef(null);
  const [selectedlanguage, setSelectedlanguage] = useState("en-in");
  const [noPersons, setNoperons] = useState(1);
  const [countArray, setCountArray] = useState([]);
  const [checkbox, setCheckbox] = useState(false);
  const [autoFill, setAutoFill] = useState("none");
  const [personStates, setPersonStates] = useState([
    {
      id: 1,
      name: "",
    },
  ]);
  const [err, setErr] = useState([]);
  const [totalCost, setTotalCost] = useState("");
  const [show, setShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedArray, setSelectedArray] = useState([]);
  const [slot, setSlot] = useState("");
  const [slotLoading, setSlotLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [gothram, setGothram] = useState("");
  const [abhishekamType, setAbhishekamType] = useState("single");
  const [slotsData, setSlotsData] = useState([]);
  const [highlightDates, setHighlightDates] = useState([]);
  const [excludeDates, setExcludeDates] = useState([]);
  const templeID = process.env.REACT_APP_TEMPLE ?? "SBMSD";
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("India");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  const [mobile, setMobile] = useState("91");
  const termsList = [
    `Paroksha Seva is solely an online seva and pilgrims are not allowed to attend Pratyaksha seva at the templewith Paroksha seva tickets.`,
    `Paroksha Seva will be performed at Devasthanam with Devotee Gothra Namam read out in the name of those who booked the seva online.`,
    `After completion of booking, cancellation / advancement / postponement are not accepted. `,
    `Each seva is individually priced. Paroksha Seva is performed by reciting 4 Gothra Namas per one booking.`,
    `After offering seva, Prasadham will be sent to Devotee’s Postal Address, which was filled out while online booking by the devotee.`,
    `Devotees can witness the live telecast of Puja/Homa from their respective location through Srisaila Tv via YouTube, Facebook.`,
    `Bank Charges or Handling charges if any shall be payable by devotee only.`,
    "Srisaila Devasthanam having all right reserve to cancel the seva at any time",
  ];

  useEffect(() => {
    setActive("online-booking-parokshaseva-enter-details");
    setActivePath("Onlinebooking-Paroksha Seva-Enter Details");
    setLoading(true);
    localStorage.removeItem("payment-context");
    getData();
  }, []);

  useEffect(() => {
    listFunction();
  }, [noPersons]);

  useEffect(() => {
    if (selectedArray && selectedArray[0]?.id) {
      getQuota(selectedArray[0]);
    }
  }, [selectedArray]);
  //----------------------------------------------------------------useeeffect------------------------------------------------------------------//

  //----------------------------------------------------------------functions------------------------------------------------------------------//

  const getData = async () => {
    if (!match.params.type) {
      history?.push(
        `/${selectedlanguage}/devotee-app/online-booking/paroksha-seva`
      );
    }
    try {
      let data = await PratyakshaSevaService.getAllvalidOnline(templeID);
      data = data?.filter(
        (item) => item?.onlineEnabled && item?.sevaType === "parokshaSeva"
      );
      setParokshaSevaData(data);
      if (currentBooking) {
        currentBooking?.abhishekamType &&
          setAbhishekamType(currentBooking?.abhishekamType);
        setNoperons(currentBooking?.noPersons);
        setPersonStates(currentBooking?.personStates);
        setStartDate(currentBooking?.startDate);
        setSelectedArray(currentBooking?.selectedArray);
        currentBooking?.slot && setSlot(currentBooking?.slot);
        setSlot(currentBooking?.slot);
        setSlotsData(currentBooking?.slotsData);
        setGothram(currentBooking?.gothram);
        setMobile(currentBooking?.mobile);
        setAddress1(currentBooking?.address1);
        setAddress2(currentBooking?.address2);
        setCountry(currentBooking?.country);
        setState(currentBooking?.state);
        setCity(currentBooking?.city);
        setZip(currentBooking?.zip);
        let count = [];
        for (let i = 1; i <= currentBooking?.selectedArray[0].noOfPeople; i++) {
          count.push(i);
        }
        setCountArray(count);
      } else {
        console.log(data);
        if (data) {
          let finder = data?.find(
            (item) =>
              item?.name?.replace?.(/\s/g, "-").toLowerCase() ===
              match.params.type
          );

          if (finder) {
            if (finder?.noOfPeople) {
              const count = [];
              for (var i = 1; i <= finder?.noOfPeople; i++) {
                count.push(i);
              }
              setCountArray([...count]);
            } else {
              setCountArray([1, 2]);
            }
            setSelectedArray([finder]);
          }
        }
      }

      setLoading(false);
    } catch (e) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      toast.error(e.message);
      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/paroksha-seva`
        );
      }
      setLoading(false);

      return;
    }
  };

  const buttonsArrayFunction = () => {
    return selectedArray?.map((item, i) => {
      return (
        <div
          className="button-div"
          style={{ display: "flex" }}
          key={i + "buttons-prs"}
        >
          <p>
            {item?.name}
            <span className="mr-3">-</span>Rs.
            {item?.price}
          </p>
        </div>
      );
    });
  };

  const listFunctionSevas = () => {
    let temp = parokshaSevaData.filter((item) => item.onlineEnabled);
    let array = temp.map((item, i) => {
      if (!item?.onlineEnabled || item?.maxOnlineBookings === 0) {
        return;
      } else {
        let finder = null;
        for (let i = 0; i < selectedArray.length; i++) {
          if (selectedArray[i].name === item?.name) {
            finder = true;
          }
        }
        return (
          <div
            key={i + "list-prs"}
            className={finder ? "option-class sd-cyan" : "option-class"}
            onClick={() => {
              setTotalCost(item.price);
              setSelectedArray([item]);
              setSlot("");
              setSlotsData([]);
              setStartDate("");
              setShow(false);
            }}
          >
            {item?.name}
            {window.innerWidth < 600 ? <br /> : <span className="mr-3">-</span>}
            <span className="mr-3">-</span> Rs.
            {item.price}
          </div>
        );
      }
    });
    return array;
  };

  const fnCalculateAge = (userDateinput) => {
    let birthDate = new Date(userDateinput);
    let difference = Date.now() - birthDate.getTime();
    let ageDate = new Date(difference);
    let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
    return calculatedAge;
  };

  const handleChange = (index, event, name) => {
    let data = [...personStates];
    data[index][`${name}`] = event.target.value;
    setPersonStates(data);
  };

  const listFunction = () => {
    if (noPersons === personStates.length) {
      return;
    }
    if (noPersons < personStates.length) {
      let arr = [...personStates];
      let b = personStates.length - noPersons;
      let total = personStates.length;
      while (b !== 0) {
        let data = err.filter((e) => {
          if (e.type !== `${personStates.length}-name`) {
            return e;
          }
        });
        setErr(data);
        arr.pop();
        total--;
        b--;
      }
      setPersonStates([...arr]);
      return;
    }
    if (noPersons > personStates.length) {
      let arr = [...personStates];
      for (let i = personStates.length; i < noPersons; i++) {
        arr.push({
          id: personStates.length + 1,
          name: "",
        });
      }

      setPersonStates([...arr]);
      return;
    }
  };

  function addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }

  const autoFillFunction = (name, i) => {
    let array = [];
    let userOnClick = () => {
      let data = [...personStates];
      data[i]["name"] =
        userData?.firstName || userData?.lastName
          ? `${userData?.firstName} ${userData?.lastName}`
          : "";
      setGothram(userData?.gothram ?? "");
      setMobile(userData?.phone?.replace("+", "") ?? "");
      setAddress1(userData?.address1 ?? "");
      setAddress2(userData?.address2 ?? "");
      setCity(userData?.city ?? "");
      setCountry(userData?.country ?? "");
      setZip(userData?.pinCode ?? "");
      setState(userData.state ?? "");
      setPersonStates(data);
      let datas = err.filter((e) => {
        if (e.type !== `${i + 1}-name`) {
          return e;
        }
      });
      setErr(datas);
      setAutoFill("none");
    };
    let a = personStates.filter((e) => {
      if (
        e?.name?.toLowerCase() ===
        `${userData?.firstName?.toLowerCase()} ${userData?.lastName?.toLowerCase()}`
      ) {
        return e;
      }
    });

    if (
      userData?.firstName?.toLowerCase()?.includes(name?.toLowerCase()) ||
      userData?.lastName?.toLowerCase()?.includes(name?.toLowerCase())
    ) {
      if (a?.length === 0) {
        array.push(
          <p
            className="sd-autofill-div"
            onClick={() => {
              userOnClick();
            }}
          >
            <p
              style={{ textTransform: "capitalize" }}
              onClick={() => {
                userOnClick();
              }}
            >
              Name:{" "}
              {userData?.firstName
                ? userData?.firstName
                : userData?.displayName}
              <span style={{ marginLeft: "3px" }}>{userData?.lastName}</span>
            </p>
            {userData?.dateOfBirth && (
              <p
                onClick={() => {
                  userOnClick();
                }}
              >
                Age:
                {fnCalculateAge(userData?.dateOfBirth)} yrs
              </p>
            )}
            {userData?.gender && <p>Gender: {userData?.gender}</p>}
          </p>
        );
      }
    }
    if (devoteeList !== null) {
      devoteeList.forEach((items) => {
        if (
          items?.firstName?.toLowerCase()?.includes(name?.toLowerCase()) ||
          items?.lastName?.toLowerCase()?.includes(name?.toLowerCase())
        ) {
          let a = personStates.filter((e) => {
            if (e?.name === `${items?.firstName} ${items?.lastName}`) {
              return e;
            }
          });
          if (a?.length === 0) {
            let onCLick = () => {
              let data = [...personStates];
              data[i]["name"] =
                items?.firstName || items?.lastName
                  ? `${items?.firstName} ${items?.lastName}`
                  : "";
              setPersonStates(data);
              let datas = err.filter((e) => {
                if (e.type !== `${i + 1}-name`) {
                  return e;
                }
              });
              setErr(datas);
              setAutoFill("none");
            };
            array.push(
              <p
                key={items.id + "autofill-prs"}
                className="sd-autofill-div"
                onClick={() => {
                  onCLick();
                }}
              >
                <p
                  onClick={() => {
                    onCLick();
                  }}
                >
                  Name: {items?.firstName}
                  <span style={{ marginLeft: "3px" }}>{items?.lastName}</span>
                </p>
                <p
                  onClick={() => {
                    onCLick();
                  }}
                >
                  Age:
                  {fnCalculateAge(items?.dob)} yrs
                </p>
                <p>Gender: {items?.gender}</p>
              </p>
            );
          }
        }
      });
    }

    if (array.length !== 0) {
      return (
        <div className="sd-autofill bg">
          {array.map((item) => {
            return item;
          })}
        </div>
      );
    } else {
      if (name === "" && a?.length === 0) {
        let demo = (
          <p
            className="sd-autofill-div"
            onClick={() => {
              userOnClick();
            }}
          >
            <p
              style={{ textTransform: "capitalize" }}
              onClick={() => {
                userOnClick();
              }}
            >
              Name:{" "}
              {userData?.firstName
                ? userData?.firstName
                : userData?.displayName}
              <span style={{ marginLeft: "3px" }}>{userData?.lastName}</span>
            </p>
            {userData?.dateOfBirth && (
              <p
                onClick={() => {
                  userOnClick();
                }}
              >
                Age:
                {fnCalculateAge(userData?.dateOfBirth)} yrs
              </p>
            )}
            {userData?.gender && (
              <p
                onClick={() => {
                  userOnClick();
                }}
              >
                Gender: {userData?.gender}
              </p>
            )}
          </p>
        );

        let a = [...devoteeList, demo];
        return (
          <div className="sd-autofill bg ani">
            {a.map((item) => {
              return item;
            })}{" "}
          </div>
        );
      } else {
        return "";
      }
    }
  };

  const getSlotsDetails = () => {
    if (slotsData?.length === 0 || !slotsData) {
      return <p>No Slots available</p>;
    }
    return slotsData
      ?.filter((item) => moment(startDate).format("YYYY-MM-DD") === item?.date)
      ?.sort((a, b) =>
        a.productSlot?.startTime.localeCompare(b.productSlot?.startTime)
      )
      ?.map((item, i) => {
        if (item?.availableQuantity < 1) {
          return;
        }
        return (
          <div
            key={i + "slots-prs"}
            onClick={() => {
              if (item?.availableQuantity < 1) {
                return;
              }
              setSlot(item);
              if (err?.find((e) => e.type === `slot`)) {
                let data = err.filter((e) => e.type !== "slot");
                setErr(data);
              }
            }}
          >
            <div
              className="sd-darshanam-box text-center"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                border:
                  err.find((e) => e.type === "slot") ||
                  item?.availableQuantity < 1
                    ? "1px solid red"
                    : "",
              }}
              onClick={() => {
                if (personStates?.length > item?.availableQuantity) {
                  return;
                }
                setSlot(item);
                if (err?.find((e) => e.type === `slot`)) {
                  let data = err.filter((e) => e.type !== "slot");
                  setErr(data);
                }
              }}
            >
              <p
                className="sd-darshanam-time"
                style={{ marginBottom: "0px", maxWidth: "85px" }}
              >
                <span style={{ marginRight: "3px", fontWeight: "600" }}>
                  {item.availableQuantity}
                </span>
                <span style={{ fontWeight: "400" }}> Available </span>
              </p>
              <div className="sd-side-heading-2 sd-acc">
                <span
                  style={{
                    fontWeight: "600",
                    whiteSpace: "nowrap",
                  }}
                >
                  Slot-(
                  <>
                    {" "}
                    {moment(item?.productSlot?.startTime, "HH:mm:ss").format(
                      "hh:mm A"
                    )}
                  </>
                  )
                </span>
              </div>
              <input
                className="sd-darshanam-radio"
                type="radio"
                id={"slot" + 11}
                name=""
                value={slot}
                onClick={() => {
                  if (personStates?.length > item?.availableQuantity) {
                    return;
                  }
                  setSlot(item);
                  if (err?.find((e) => e.type === `slot`)) {
                    let data = err.filter((e) => e.type !== "slot");
                    setErr(data);
                  }
                }}
                checked={slot.id === item?.id}
              />
            </div>
          </div>
        );
      });
  };

  const getDates = (startDate, endDate, quotaDates) => {
    let date = startDate;
    let dates = [];
    while (date <= endDate) {
      if (
        !quotaDates?.includes(moment(date, "YYYY-MM-DD").format("YYYY-MM-DD"))
      ) {
        dates.push(new Date(date));
      }
      date = moment(date, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
    }
    setExcludeDates([...dates]);
  };

  const getQuota = async (data) => {
    setSlotLoading(true);
    let quotaDates = [];
    let availableDates = [];
    let availableDatesString = [];
    let almostBookedDates = [];
    let almostBookedDatesString = [];
    let notAvailableDates = [];
    let notAvailableDatesString = [];
    try {
      let res = await PratyakshaSevaService.getDailyQuota(
        moment().add(data?.minOnlineHours, "hours").format("YYYY-MM-DD"),
        moment().add(data?.maxOnlineHours, "hours").format("YYYY-MM-DD"),
        Number(data?.id),
        data?.onlineEnabled,
        templeID
      );
      let count = [];
      for (let i = 1; i <= data?.noOfPeople; i++) {
        count.push(i);
      }
      setCountArray(count);
      setNoperons(1);
      setSlotsData(res);
      res?.map((item) => {
        quotaDates.push(item?.date);
        if (item?.quantity > 0) {
          if (Number(item?.availableQuantity / item?.quantity) * 100 > 50) {
            availableDates.push(new Date(item?.date));
            availableDatesString.push(new Date(item?.date).toString());
            if (
              notAvailableDatesString.includes(new Date(item?.date).toString())
            ) {
              let index = notAvailableDatesString.indexOf(
                new Date(item?.date).toString()
              );
              notAvailableDates.splice(index, 1);
              notAvailableDatesString.splice(index, 1);
            }
          } else if (
            Number(item?.availableQuantity / item?.quantity) * 100 <= 50 &&
            Number(item?.availableQuantity / item?.quantity) * 100 > 0
          ) {
            almostBookedDates.push(new Date(item?.date));
            almostBookedDatesString.push(new Date(item?.date).toString());
            if (
              notAvailableDatesString.includes(new Date(item?.date).toString())
            ) {
              let index = notAvailableDatesString.indexOf(
                new Date(item?.date).toString()
              );
              notAvailableDates.splice(index, 1);
              notAvailableDatesString.splice(index, 1);
            }
          } else {
            if (
              !availableDatesString.includes(new Date(item?.date).toString())
            ) {
              if (
                !almostBookedDatesString.includes(
                  new Date(item?.date).toString()
                )
              ) {
                notAvailableDates.push(new Date(item?.date));
                notAvailableDatesString.push(new Date(item?.date).toString());
              }
            }
          }
        } else {
          notAvailableDates.push(new Date(item?.date));
          notAvailableDatesString.push(new Date(item?.date).toString());
        }
      });
      notAvailableDates?.map((date) => {
        if (
          availableDatesString.includes(date.toString()) ||
          almostBookedDatesString.includes(date.toString())
        ) {
          let index = notAvailableDatesString.indexOf(date.toString());
          notAvailableDates.splice(index, 1);
          notAvailableDatesString.splice(index, 1);
        }
      });
      setHighlightDates([
        { "react-datepicker__day--highlighted-custom-1": availableDates },
        { "react-datepicker__day--highlighted-custom-2": almostBookedDates },
        { "react-datepicker__day--highlighted-custom-3": notAvailableDates },
      ]);
      getDates(
        moment().add(data?.minOnlineHours, "hours").format("YYYY-MM-DD"),
        moment().add(data?.maxOnlineHours, "hours").format("YYYY-MM-DD"),
        quotaDates
        // notAvailableDates?.filter((item) => item)
      );
      setSlotLoading(false);
    } catch (e) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      toast.error(e.message);
      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/paroksha-seva`
        );
      }
      setLoading(false);

      return;
    }
  };

  function capitalize(str) {
    let splitStr = str?.toLowerCase?.().split?.(" ");
    for (let i = 0; i < splitStr?.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr?.join(" ");
  }

  const handleSubmit = async () => {
    setLoading(true);
    let a = true;
    if (a) {
      let data = [...err];

      if (!checkbox) {
        data.push({
          type: "check",
          msg: "Please agree to terms and conditions",
        });
      }
      if (startDate === "") {
        data.push({ type: "date", msg: "Please select a date" });
      }
      if (slot === "") {
        data.push({ type: "slot", msg: "Please select a slot" });
      }
      if (mobile.substring(0, 2) === "91") {
        if (mobile.length !== 12) {
          data.push({ type: "phone", msg: "Invalid mobile number" });
        }
      } else {
        if (mobile.length < 8) {
          data.push({ type: "phone", msg: "Invalid mobile number" });
        }
      }
      if (city === "") {
        data.push({ type: "city", msg: "Please enter a city" });
      } else if (city.length < 3) {
        data.push({ type: "city", msg: "Minimum 3 characters" });
      }
      if (address1 === "") {
        data.push({ type: "address1", msg: "Please enter a valid address" });
      } else if (address1.length < 3) {
        data.push({ type: "address1", msg: "Minimum 3 characters" });
      }
      if (state === "") {
        data.push({ type: "state", msg: "Please select your state" });
      }

      if (zip.length === "") {
        data.push({ type: "zip", msg: "Invalid zipcode! Try again" });
      }
      if (country === "") {
        data.push({ type: "country", msg: "Please select a country" });
      }
      if (gothram === "") {
        data.push({ type: "gothram", msg: "Please select a gothram" });
      }
      personStates.map((item) => {
        for (var key of Object.keys(item)) {
          if (item[key] === "") {
            data.push({
              type: `${item["id"]}-${key}`,
              msg: `Invalid ${key}`,
            });
          } else {
            if (key === "name") {
              if (item["name"].length < 3) {
                data.push({
                  type: `${item["id"]}-name`,
                  msg: "Minimun 3 characters",
                });
              }
            }
          }
        }
      });
      setErr(data);
      if (data.length !== 0) {
        setLoading(false);
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        return;
      }
    }

    try {
      setLoading(true);
      let dup = [...personStates];
      let arrayEx = dup.map((e) => {
        return {
          personName: e?.name,
        };
      });
      let dto = {
        sevaSlotId: slot?.productSlot?.id,
        bookingDate: moment(startDate).format("YYYY-MM-DD"),
        phoneNumber: `+${mobile}`,
        personDetails: arrayEx,
        gothram: gothram,
        address: capitalize(address1),
        city: capitalize(city),
        state: state,
        country: country,
        pinCode: Number(zip),
      };
      if (address2 !== "") {
        dto.address2 = capitalize(address2);
      }

      let schema = {
        cartContains: ["seva"],
        sevaBookings: [dto],
        returnPriceStats: true,
      };

      let res = await ParokshaSevaService.bookings(schema, templeID);
      if (res) {
        let data = {
          selectedArray,
          startDate,
          noPersons,
          personStates,
          slot,
          slotsData,
          mobile,
          res,
          gothram,
          state,
          country,
          address1,
          address2,
          zip,
          city,
          templeID,
        };

        setCurrentBooking(data);
        history?.push(
          `/${selectedlanguage}/devotee-app/online-booking/paroksha-seva/confirm-details`
        );
      }
    } catch (e) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      toast.error(e.message);
      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/paroksha-seva`
        );
      }
      setLoading(false);

      return;
    }
  };

  //----------------------------------------------------------------useeeffect------------------------------------------------------------------//

  return (
    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard">
      {loading ? (
        <Sd_loading />
      ) : (
        <div className="col-xs-12 col-sm-12 col-md-11 col-lg-11 pl-0 xs-pr-0 sd-profile sd-seva-form">
          <h2 className="sd-side-heading fw400">
            <span className="fw400">
              <span className="fw700" ref={ref}>
                Paroksha
              </span>{" "}
              Seva ( Virtual Seva )
            </span>
          </h2>
          <div className="clearfix">
            <form className="sd-form">
              <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 xssm-pr-0 pl-0 pr-0">
                <label>
                  Select a Seva<span className="sd-imp">*</span>
                </label>

                <div className="select-div">
                  <div
                    className={
                      selectedArray.length > 0
                        ? "constant-top sd-white sd-voilet-border"
                        : "constant-top "
                    }
                  >
                    <div className="sd-buttonArray">
                      {buttonsArrayFunction()}
                    </div>

                    <div className="sd-input-image">
                      <input
                        className={selectedArray.length > 0 ? "sd-white" : " "}
                        type="text"
                        onClick={() => {
                          setShow(!show);
                        }}
                        onChange={(e) => {
                          setSelectedValue({ value: e.target.value });
                        }}
                      />
                      <img
                        className={show ? "sd-image-invert" : ""}
                        src={SD_Dropdown_Arrow_icon}
                        alt=""
                        onClick={() => {
                          setShow(!show);
                        }}
                      />
                    </div>
                  </div>

                  {show && (
                    <div className="option-div" style={{ paddingTop: "1vh" }}>
                      {listFunctionSevas()}
                    </div>
                  )}
                </div>
                {selectedArray.find((e) =>
                  e?.name?.includes(
                    "Sri Bayalu Veerabhadra Swamy Vari Visesharchana"
                  )
                ) && (
                  <p style={{ color: "red" }}>
                    Note: Enroll Sri Bayalu Veerabhadra Swamy Visesharchana Seva
                    on 5th of June 2024.
                  </p>
                )}
                {selectedArray.find((e) =>
                  e?.name?.includes("Laksha Kumkumarchana")
                ) && (
                  <p style={{ color: "red" }}>
                    Note: Enroll your Laksha Kumkumarchana Seva to Sri
                    Bhramaramba Devi on 21st of June, 2024.
                  </p>
                )}

                {selectedArray.find((e) =>
                  e?.name?.includes("Nandeeshwara Swamy Vari Visesharchana")
                ) && (
                  <p style={{ color: "red" }}>
                    Note: Enroll your Special Archana for Nandeeshwara Swamy
                    Vari Visesharchana on 19th June, 2024.
                  </p>
                )}
                {selectedArray.find(
                  (e) =>
                    e?.name ===
                    "Abhishekam Alamkaramandapam (Couple) Only Alamkara Darshanam"
                ) && (
                  <p style={{ color: "red" }}>
                    Note : Due to devotee rush on going in this Karthika Masam,
                    devotees are allowed to make Alankara Dharshanam only.
                  </p>
                )}
                {selectedArray.find(
                  (e) => e?.name === "Samuhika Abhishekamulu (Couple)"
                ) && (
                  <p style={{ color: "red" }}>
                    Note : Kind attention Devotees. Due to Heavy Rush during
                    Kartheeka Masam, the Sparsha Darsanam of Sri Mallikarjuna
                    Swamy and Garbhalaya Abhishekams are being stopped on
                    Sundays, Mondays and Kartheeka Pournami from 05.11.2021 to
                    04.12.2021.
                  </p>
                )}
                {selectedArray.find(
                  (e) => e?.name === "VIP Break Darshanam"
                ) && (
                  <p style={{ color: "red" }}>
                    Note : Kind attention Devotees. Due to Heavy Rush during
                    Kartheeka Masam, the Sparsha Darsanam Tickets of Sri
                    Mallikarjuna Swamy and Garbhalaya Abhishekams Tickets are
                    freezed on Sundays, Mondays and Kartheeka Pournami from
                    05.11.2021 to 04.12.2021.
                  </p>
                )}
              </div>
              <hr className="clearfix"></hr>
              <div className="sd-profile">
                <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4 xssm-pr-0 pl-0">
                  <label>
                    Select Date<span className="sd-imp">*</span>
                  </label>

                  <div
                    className={
                      err?.find((e) => e.type === `date`)
                        ? "sd-dob customDatePickerWidth sd-red"
                        : startDate !== ""
                        ? "sd-date-voilet sd-dob customDatePickerWidth"
                        : "sd-dob customDatePickerWidth"
                    }
                  >
                    <DatePicker
                      allowSameDay={false}
                      placeholderText="DD/MM/YYYY"
                      dateFormat={"dd/MM/yyyy"}
                      showDisabledMonthNavigation
                      style={{
                        width: "90%",
                        border: "none",
                        backgroundColor: "blue",
                      }}
                      minDate={addDays(
                        new Date(),
                        selectedArray[0]?.minOnlineHours / 24
                      )}
                      maxDate={addDays(
                        new Date(),
                        selectedArray[0]?.maxOnlineHours / 24
                      )}
                      highlightDates={highlightDates}
                      excludeDates={excludeDates}
                      clearIcon={null}
                      selected={startDate}
                      onChange={(date) => {
                        if (err.find((e) => e.type === "date")) {
                          let data = err.filter((e) => e.type !== "date");
                          setErr(data);
                        }
                        setStartDate(date);
                      }}
                    >
                      <div className="sd-quota">
                        <div className="sd-quota-div">
                          <p
                            style={{
                              backgroundColor: "rgb(0, 207, 0)",
                              color: "rgb(0, 207, 0)",
                            }}
                          ></p>
                          Available
                        </div>
                        <div className="sd-quota-div">
                          <p
                            style={{
                              backgroundColor: "orange",
                              color: "orange",
                            }}
                          ></p>
                          Almost Booked
                        </div>
                      </div>
                      <div className="sd-quota">
                        <div className="sd-quota-div">
                          <p
                            style={{
                              backgroundColor: "rgb(201, 0, 0)",
                              color: "rgb(201, 0, 0)",
                            }}
                          ></p>
                          Not Available
                        </div>
                        <div className="sd-quota-div">
                          <p
                            style={{
                              backgroundColor: "rgb(117,117,117)",
                              color: "rgb(117,117,117)",
                            }}
                          ></p>
                          Quota Not Released
                        </div>
                      </div>
                    </DatePicker>
                  </div>
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "date")?.msg}
                  </p>
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4  xssm-pl-0 xssm-pr-0  ">
                  <label>
                    Number of Person(s)<span className="sd-imp">*</span>
                  </label>
                  <select
                    className={
                      noPersons !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={noPersons}
                    onChange={(e) => {
                      setNoperons(e.target.value);
                    }}
                  >
                    {countArray.map((num) => {
                      return (
                        <option key={num} value={num}>
                          {num}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4 xssm-pl-0 xssm-pr-0   mb-0">
                  <label>
                    Gothram<span className="sd-imp">*</span>
                  </label>
                  <input
                    maxLength="30"
                    type="text"
                    placeholder="Enter gothram."
                    className={
                      err?.find((e) => e.type === `gothram`)
                        ? "form-control sd-red"
                        : gothram !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={gothram}
                    onChange={(e) => {
                      if (
                        /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                        e.target.value === ""
                      ) {
                        if (err.find((e) => e.type === `gothram`)) {
                          let data = err.filter((e) => e.type !== `gothram`);
                          setErr(data);
                        }
                        setGothram(e.target.value);
                      }
                    }}
                  />
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === `gothram`)?.msg}
                  </p>
                </div>
              </div>
              {slotsData?.length !== 0 && startDate ? (
                <>
                  <hr className="clearfix"></hr>

                  <div
                    className="clearfix sd-darshanam-content"
                    style={{ position: "relative" }}
                  >
                    <br />
                    {getSlotsDetails()}
                    <p
                      style={{
                        position: "absolute",
                        top: "-12px",
                        left: "0px",
                        textAlign: "right",
                        color: "red",
                      }}
                    >
                      {err?.find((e) => e.type === `slot`)?.msg}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  {startDate && !slotLoading ? (
                    <>
                      <hr className="clearfix"></hr>{" "}
                      <div
                        className="clearfix sd-darshanam-content"
                        style={{ position: "relative", color: "red" }}
                      >
                        No Slots available on{" "}
                        {moment(startDate).format("DD-MM-YYYY")}
                      </div>
                    </>
                  ) : (
                    startDate && (
                      <>
                        <hr className="clearfix"></hr>{" "}
                        <div
                          className="clearfix sd-darshanam-content"
                          style={{ position: "relative" }}
                        >
                          Fetching slots...Please wait{" "}
                        </div>
                      </>
                    )
                  )}
                </>
              )}
              <div className="clearfix">
                <hr className="clearfix"></hr>
              </div>{" "}
              <>
                {" "}
                <div className="clearfix" key={"personstates-prs"}>
                  <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pl-0 pl-0 pr-0">
                    {personStates.map((item, i) => {
                      return (
                        <div
                          className={`form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0  ${
                            i % 2 === 0 && "pl-0"
                          }`}
                          key={i + "paroksha_names"}
                        >
                          <label>
                            {i + 1}.Name<span className="sd-imp">*</span>
                          </label>
                          <input
                            maxLength="20"
                            autoComplete="new-password"
                            onFocus={() => {
                              if (autoFill === `${i}autofill`) {
                                setAutoFill("none");
                              } else {
                                setAutoFill(`${i}autofill`);
                              }
                            }}
                            onBlur={() => {
                              if (autoFill === `${i}autofill`) {
                                setTimeout(() => {
                                  setAutoFill("none");
                                }, 250);
                              }
                            }}
                            placeholder="Please enter your name"
                            type="text"
                            className={
                              err?.find((e) => e.type === `${i + 1}-name`)
                                ? "form-control sd-red"
                                : item?.name !== ""
                                ? "form-control sd-voilet"
                                : "form-control"
                            }
                            value={item?.name}
                            onChange={(e) => {
                              if (
                                /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                                e.target.value === ""
                              ) {
                                if (
                                  err.find((e) => e.type === `${i + 1}-name`)
                                ) {
                                  let data = err.filter(
                                    (e) => e.type !== `${i + 1}-name`
                                  );
                                  setErr(data);
                                }
                                handleChange(i, e, "name");
                              }
                            }}
                          />
                          <p className="sd-EM mr-15 ">
                            {err.find((e) => e.type === `${i + 1}-name`)?.msg}
                          </p>
                          {autoFill === `${i}autofill` &&
                            autoFillFunction(item?.name, i)}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
              <div className="clearfix">
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                  <label>
                    Address Lane 1<span className="sd-imp">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength="50"
                    placeholder="House/building number, street number"
                    className={
                      err.find((e) => e.type === "address1")
                        ? "form-control sd-red"
                        : address1 !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={address1}
                    onChange={(e) => {
                      if (
                        AddressRegex.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        if (
                          err.find(
                            (e) => e.type === "address1" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "address1" && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setAddress1(e.target.value);
                      }
                    }}
                  />
                  <p className="d-EM mr-15">
                    {err.find((e) => e.type === "address1")
                      ? err.find((e) => e.type === "address1")?.msg
                      : ""}
                  </p>
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0  xssm-pr-0 pr-0 ">
                  <label>Address Lane 2</label>
                  <input
                    type="text"
                    maxLength="50"
                    className={
                      err.find((e) => e.type === "address2")
                        ? "form-control sd-red"
                        : address2 !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    placeholder="building name, street name, etc"
                    value={address2}
                    onChange={(e) => {
                      if (
                        AddressRegex.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        if (
                          err.find(
                            (e) => e.type === "address2" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "address2" && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setAddress2(e.target.value);
                      }
                    }}
                  />
                  <p className="d-EM mr-15">
                    {err.find((e) => e.type === "address2")
                      ? err.find((e) => e.type === "address2")?.msg
                      : ""}
                  </p>
                </div>
              </div>
              <div className="clearfix">
                <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pr-0 pl-0">
                  <label>
                    City<span className="sd-imp">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength="30"
                    className={
                      err.find((e) => e.type === "city")
                        ? "form-control sd-red"
                        : city !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={city}
                    placeholder="Please enter your city"
                    onChange={(e) => {
                      if (
                        AddressRegex.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        if (
                          err.find(
                            (e) => e.type === "city" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "city" && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setCity(e.target.value);
                      }
                    }}
                  />
                  <p className="d-EM mr-15">
                    {err.find((e) => e.type === "city")
                      ? err.find((e) => e.type === "city")?.msg
                      : ""}
                  </p>
                </div>

                <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pr-0 ">
                  <label>
                    Zipcode<span className="sd-imp">*</span>
                  </label>
                  <input
                    type="text"
                    onWheel={(event) => event.currentTarget.blur()}
                    className={
                      err.find((e) => e.type === "zip")
                        ? "form-control sd-red"
                        : zip !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={zip}
                    maxLength={12}
                    placeholder="Enter your zipcode"
                    onChange={(e) => {
                      if (
                        AddressRegex.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        if (
                          err.find(
                            (e) => e.type === "zip" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "zip" && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        if (e.target.value.length < 12) {
                          setZip(e.target.value.toUpperCase());
                        }
                      }
                    }}
                  />
                  <p className="d-EM mr-15">
                    {err.find((e) => e.type === "zip")
                      ? err.find((e) => e.type === "zip")?.msg
                      : ""}
                  </p>
                </div>

                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pr-0 ">
                  <label>
                    State<span className="sd-imp">*</span>
                  </label>
                  <RegionDropdown
                    country={country}
                    classes={
                      err.find((e) => e.type === "state")
                        ? "sd-red w-100 sd-border-none"
                        : state !== ""
                        ? " sd-voilet w-100 sd-border-none"
                        : "w-100 sd-border-none"
                    }
                    value={state}
                    showDefaultOption={true}
                    blankOptionLabel="Select a State"
                    onChange={(val) => {
                      if (
                        err.find(
                          (e) => e.type === "state" || e.type === "common"
                        )
                      ) {
                        let data = err.filter((e) => {
                          if (e.type !== "state" && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      setState(val);
                    }}
                  />
                  <p className="d-EM ">
                    {err.find((e) => e.type === "state")
                      ? err.find((e) => e.type === "state")?.msg
                      : ""}
                  </p>
                </div>
              </div>
              <div className="clearfix">
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 pl-0 xssm-pr-0 ">
                  <label>
                    Country<span className="sd-imp">*</span>
                  </label>
                  <CountryDropdown
                    value={country}
                    classes={
                      err.find((e) => e.type === "country")
                        ? "sd-red w-100 "
                        : country !== ""
                        ? " sd-voilet w-100 sd-border-none"
                        : "w-100 sd-border-none"
                    }
                    showDefaultOption={true}
                    defaultValue="India"
                    defaultOptionLabel="Select a Country"
                    onChange={(val) => {
                      if (
                        err.find(
                          (e) => e.type === "country" || e.type === "common"
                        )
                      ) {
                        let data = err.filter((e) => {
                          if (e.type !== "country" && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      setCountry(val);
                    }}
                  />
                  <p className="d-EM mr-15">
                    {err.find((e) => e.type === "country")
                      ? err.find((e) => e.type === "country")?.msg
                      : ""}
                  </p>
                </div>

                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pr-0">
                  <label>
                    Mobile Number<span className="sd-imp">*</span>
                  </label>

                  <div className={"sd-profile-mobile"}>
                    <PhoneInput
                      inputExtraProps={{
                        autoFocus: true,
                        enableSearch: true,
                        countryCodeEditable: false,
                      }}
                      onlyCountries={["in"]}
                      placeholder="Enter Your 10 digit mobile number"
                      maxLength="15"
                      countryCodeEditable={false}
                      containerClass={
                        err?.find((e) => e.type === "phone")
                          ? "sd-phone-voilet sd-red"
                          : mobile !== ""
                          ? "sd-phone-voilet"
                          : ""
                      }
                      country="in"
                      value={mobile}
                      onChange={(e) => {
                        if (
                          err?.find(
                            (e) => e.type === "phone" || e.type === "common"
                          )
                        ) {
                          let array = err.filter((e) => {
                            if (e.type !== "phone" && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(array);
                        }
                        setMobile(e);
                      }}
                    />
                    <p className="d-EM ">
                      {err.find((e) => e.type === "phone")
                        ? err.find((e) => e.type === "phone")?.msg
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
              <hr className="clearfix"></hr>
              <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pl-0 pr-0">
                <p
                  className="ml--15"
                  style={{
                    color: "#181818",
                    fontWeight: "600",

                    whiteSpace: "pre-wrap",
                  }}
                >
                  Note: Sri Bhramaramba Mallikarjuna Swamy Vari Devasthanam does
                  not ship Prasadham overseas. NRIs(Non-Resident Indians) and
                  Overseas Citizens who wish to book Paroksha Seva are advised
                  to fill their Indian Address(if any) while booking the Seva to
                  receive the Prasadham via post.
                </p>
                <ol className="sd-terms-list">
                  {selectedArray.find(
                    (item) => item?.live_telecast === false
                  ) ? (
                    <li className="active">
                      <a>
                        Access to live service of{" "}
                        {
                          selectedArray.find(
                            (item) => item?.live_telecast === false
                          )?.name
                        }{" "}
                        is currently not available. However, devotees who have
                        booked the said service may witness their names read out
                        during live Veedasirvachanam.
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  <li className="impText">{termsList[0]}</li>
                  {selectedArray.find(
                    (e) =>
                      e?.name ===
                        "Nandeeshwara Swamy Vari Visesharchana (Virtual Seva - పరోక్ష సేవ)" ||
                      e?.name === "Laksha Kumkumarchana" ||
                      e?.name ===
                        "Sri Bayalu Veerabhadra Swamy Vari Visesharchana" ||
                      e?.name === "Sri Ganapathi Pooja"
                  ) ? (
                    <li>
                      Each seva is individually priced. Paroksha Seva is
                      performed by reciting 2 Gothra Namas per one booking.
                    </li>
                  ) : (
                    <li>
                      Each seva is individually priced. Paroksha Seva is
                      performed by reciting 4 Gothra Namas per one booking.
                    </li>
                  )}

                  {termsList.map((item, i) => {
                    if (i === 0) {
                      return;
                    }
                    return (
                      <li className="active" key={i + "terms-ps"}>
                        <a>{item}</a>
                      </li>
                    );
                  })}
                </ol>

                <div
                  className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 xssm-pr-0 pl-0 sd-terms-checkbox"
                  style={{ alignItems: "center" }}
                >
                  <input
                    className="mr-5"
                    type="checkbox"
                    name=""
                    value={checkbox}
                    onChange={() => {
                      if (
                        err.find(
                          (e) => e.type === `check` || e.type === "common"
                        )
                      ) {
                        let data = err.filter((e) => {
                          if (e.type !== `check` && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      setCheckbox(!checkbox);
                    }}
                    checked={checkbox === true ? true : false}
                    style={{ position: "relative" }}
                  />
                  <label
                    htmlFor=""
                    style={{ marginBottom: "0px", position: "relative" }}
                  >
                    I agree to the{" "}
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedlanguage}/legalities/terms-and-conditions`
                        );
                      }}
                    >
                      Terms & Conditions
                    </a>{" "}
                    and the
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedlanguage}/legalities/privacy-policy`
                        );
                      }}
                    >
                      {" "}
                      Privacy Policy
                    </a>
                  </label>
                  <p className="sd-accept">
                    {err?.find((e) => e.type === `check`)?.msg}
                  </p>
                </div>
              </div>
              <hr className="clearfix"></hr>
              <div className="clearfix mt-50">
                <a
                  className="sd-btn-orange"
                  onClick={() => {
                    if (!loading) {
                      handleSubmit();
                    }
                  }}
                >
                  Confirm Booking{" "}
                  {loading ? (
                    <img
                      src={sd_btn_loading}
                      alt=".."
                      className="sd-arrow-btn ml-5 h-20"
                    />
                  ) : (
                    <img
                      className="sd-arrow-btn ml-5"
                      src={SD_Right_Arrow_white}
                    />
                  )}
                </a>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
export default Sd_dashboard_parokshaseva_seva_details;

import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import {
  sd_move_left,
  sd_move_right,
  SD_Print_Icon,
  sd_script,
  // SD_Right_Arrow_white,
  sd_view,
} from "../../Assets/imageList";
import Sd_loading from "../../Components/sd-common-components/sd-loading";
import { SD_Context } from "../../context/sd-context";
import Sd_DashboardService from "../../services/sd-dashboard.service";
import { EncryptInformation } from "../../Utils/sd-encrypt/encrypt";
import IndiaTime from "../../Utils/sd-moment/IndiaTime";
import "../../Utils/sd-styles/sd-css/main.css";

const Sd_dashboard_booking_history = ({ history }) => {
  //-------------------------------------------------------------states----------------------------------------------------------//
  const { setActive, setActivePath, logout, userData } = useContext(SD_Context);
  const [loading, setLoading] = useState();
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [selectedLanguage, setSelectedlanguage] = React.useState("en-in");
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [act, setAct] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("success");
  const templeID = process.env.REACT_APP_TEMPLE ?? "SBMSD";
  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useeffect--------------------------------------------------------//
  useEffect(() => {
    setLoading(true);
    setActive("booking-history");
    setActivePath("Booking History");
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);
  useEffect(() => {
    getData(10, 1);
  }, [paymentStatus]);
  //-------------------------------------------------------------useefects--------------------------------------------------------//

  //-------------------------------------------------------------functions--------------------------------------------------------//
  const getData = async (item, page) => {
    try {
      let res = await Sd_DashboardService.booking_history(
        item,
        page,
        templeID,
        false,
        paymentStatus
      );
      setData(res.items);
      setMeta(res.meta);
      setLoading(false);
      setAct(page);
    } catch (e) {
      if (e.message === "Session Expired") {
        logout();
        history?.push(
          `/${selectedLanguage}/devotee/signin?to=devotee-app/devotee-dashboard`
        );
      }
      setLoading(false);
    }
  };

  const getTotal = () => {
    let array = [];
    for (let i = 1; i <= meta?.totalPages; i++) {
      array.push(
        <li
          className={act ? "sd-background" : ""}
          onClick={() => {
            if (act === i) {
              return;
            }
            getData(10, i);
          }}
        >
          <a>{i}</a>
        </li>
      );
    }
    return array;
  };
  //-------------------------------------------------------------functions--------------------------------------------------------//
  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {loading ? (
        <Sd_loading />
      ) : (
        <div className="sd-confirm-details">
          <h2 className="sd-side-heading fw400">
            <span className="fw400">
              <span className="fw700">Booking </span> History
            </span>
            <span className="float-right">
              <span className="sd-view-all"></span>
            </span>
          </h2>

          <select
            className={
              paymentStatus !== "" ? "form-control sd-voilet" : "form-control"
            }
            value={paymentStatus}
            onChange={(e) => {
              setPaymentStatus(e.target.value);
            }}
          >
            <option key={"success"} value={"success"}>
              Success
            </option>
            <option key={"failure"} value={"failure"}>
              Failure
            </option>
            <option key={"created"} value={"created"}>
              Created
            </option>
            <option key={"canceled"} value={"canceled"}>
              Canceled
            </option>
          </select>
          <br />
          <div className="clearfix sd-booking-history sd-devotee-details nan-border-none sd-toggle-table">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th width="25%">Ticket ID </th>
                  <th width="25%">service Name / Category</th>
                  <th width="15%">Person(S)</th>
                  <th width="15%">date / Time slot</th>
                  <th width="10%">Amount(₹)</th>
                  <th width="20%">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <span className="fw600">{item?.ticketId}</span>
                        <br />
                        <span style={{ fontWeight: "400" }}>
                          {IndiaTime(item?.paymentDate).format(
                            "DD - MM - YYYY"
                          )}{" "}
                          <span style={{ marginLeft: "5px" }}>
                            {" "}
                            {IndiaTime(item?.paymentDate).format("hh:mm A")}
                          </span>
                          {/* 6:30 PM */}
                        </span>
                      </td>
                      <td>
                        <span className="fw600 TC">{item?.serviceName}</span>
                        <br></br>
                        <span className="TC fw400">
                          ({item?.serviceType?.replace("_", " ")})
                        </span>
                      </td>
                      <td>
                        <span className="TC">
                          {item?.noOfTickets
                            ? item?.noOfTickets !== 0
                              ? `Persons - ${item?.noOfTickets}`
                              : "-"
                            : "-"}
                        </span>
                      </td>
                      <td>
                        <span className="fw600">
                          {" "}
                          {IndiaTime(item?.bookingDate).format(
                            "DD - MM - YYYY"
                          )}
                        </span>
                        <br />
                        <span>
                          {item?.serviceType === "online_seva" ||
                          item?.serviceType === "online_darshanam" ? (
                            `${moment(item?.slotStartTime, "HH:mm:ss")
                              .tz("Asia/Kolkata")
                              .format("hh:mm A")} - ${moment(
                              item?.slotEndTime,
                              "HH:mm:ss"
                            ).format("hh:mm A")}`
                          ) : (
                            <>
                              {moment(item?.slotStartTime, "HH:mm:ssZ")
                                .tz("Asia/Kolkata")
                                .format("hh:mm A") ===
                                moment(item?.slotEndTime)
                                  .tz("Asia/Kolkata")
                                  .format("hh:mm A") && (
                                <>
                                  {item?.slotStartTime &&
                                    moment(item?.slotStartTime)
                                      .tz("Asia/Kolkata")
                                      .format("hh:mm A")}
                                </>
                              )}
                            </>
                          )}
                        </span>
                      </td>
                      <td className="sd-actions-icons-main">
                        <span>{item?.onlineCartItemAmount}/-</span>
                      </td>
                      <td className="sd-actions-icons-main">
                        {/* {item?.serviceCategory?.toLowerCase() !==
                              "donation" && (
                              <> */}
                        {paymentStatus === "success" ? (
                          moment(item?.bookingDate, "YYYY-MM-DD").isSameOrAfter(
                            moment(moment().format("YYYY-MM-DD"))
                          ) ? (
                            <>
                              {" "}
                              <a
                                onClick={() => {
                                  history?.push(
                                    `/${selectedLanguage}/devotee/print-a-ticket?id=${EncryptInformation(
                                      item?.ticketId
                                    )}&display=view`
                                  );
                                }}
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                }}
                              >
                                <img src={sd_view} className="sd-view-icon" />
                              </a>
                              <a
                                onClick={() => {
                                  history?.push(
                                    `/${selectedLanguage}/devotee/print-a-ticket?id=${EncryptInformation(
                                      item?.ticketId
                                    )}&display=print`
                                  );
                                }}
                                style={{
                                  paddingLeft: "10px",
                                  borderLeft: "1px solid rgb(156 155 155)",
                                }}
                              >
                                <img
                                  src={SD_Print_Icon}
                                  className="sd-print-icon"
                                  height="15px"
                                />
                              </a>
                            </>
                          ) : (
                            "-"
                          )
                        ) : (
                          "-"
                        )}
                        {/* </>
                            )} */}
                      </td>
                    </tr>
                  );
                })}
                {data.length === 0 && (
                  <td
                    colSpan="24"
                    className="pt-20 pb-30"
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <br />
                    <img
                      src={sd_script}
                      alt="#"
                      height="80px"
                      width="80px"
                      style={{ marginRight: "5px", opacity: "0.3" }}
                    />
                    <p className="w-100 p-5">
                      Looks like you haven't made a booking yet.
                      <br />
                      <span
                        className="mr-5"
                        style={{ color: "#991403", fontWeight: "600" }}
                        onClick={() => {
                          history?.push(`/${selectedLanguage}/online-booking`);
                        }}
                      >
                        {" "}
                        Click here
                      </span>
                      to make a booking.
                    </p>
                    <br />
                  </td>
                )}
              </tbody>
            </table>
          </div>
          <div className="sd-table-detoggler">
            {data.map((item, i) => {
              return (
                <div key={i} className="sd-b-d-inner">
                  <div className="sd-booking-details-up">
                    <div className="sd-actions-icons-main">
                      <span style={{ fontWeight: 600 }}>{item?.ticketId}</span>
                      <br />
                      <span style={{ fontWeight: "500" }}>
                        {IndiaTime(item?.paymentDate).format("DD - MM - YYYY")}{" "}
                        <span style={{ marginLeft: "5px" }}>
                          {" "}
                          {IndiaTime(item?.paymentDate).format("hh:mm A")}
                        </span>
                        {/* 6:30 PM */}
                      </span>
                    </div>
                    <div className="sd-actions-icons-main">
                      <a
                        onClick={() => {
                          history?.push(
                            `/${selectedLanguage}/devotee/print-a-ticket?id=${EncryptInformation(
                              item?.ticketId
                            )}&display=view`
                          );
                        }}
                        style={{
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        <img src={sd_view} className="sd-view-icon" />
                      </a>
                      <a
                        onClick={() => {
                          history?.push(
                            `/${selectedLanguage}/devotee/print-a-ticket?id=${EncryptInformation(
                              item?.ticketId
                            )}&display=print`
                          );
                        }}
                        style={{
                          paddingRight: "10px !important",
                          borderLeft: "1px solid rgb(156 155 155)",
                        }}
                      >
                        <img
                          src={SD_Print_Icon}
                          className="sd-print-icon"
                          height="15px"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="sd-booking-details-middle">
                    <label style={{ marginBottom: "0pc" }}>
                      SERVICE NAME / CATEGORY
                    </label>
                    <br />
                    <span className="fw600 TC mr-10">{item?.serviceName}</span>

                    <span className="TC fw600">
                      ({item?.serviceType?.replace("_", " ")})
                    </span>
                  </div>
                  <div className="sd-booking-details-down">
                    <div>
                      <label>PERSONS</label>
                      <span className="TC">
                        {item?.personsCount !== 0
                          ? `${item?.noOfTickets}`
                          : "-"}
                      </span>
                    </div>
                    <div>
                      <label>DATE/TIME SLOT</label>
                      <span className="fw600">
                        {" "}
                        {IndiaTime(item?.bookingDate).format("DD - MM - YYYY")}
                      </span>

                      <span>
                        {item?.serviceType === "online_seva" ||
                        item?.serviceType === "online_darshanam" ? (
                          `${moment(item?.slotStartTime, "HH:mm:ss").format(
                            "hh:mm A"
                          )} - ${moment(item?.slotEndTime, "HH:mm:ss").format(
                            "hh:mm A"
                          )}`
                        ) : (
                          <>
                            {moment(item?.slotStartTime, "HH:mm:ss").format(
                              "hh:mm A"
                            ) ===
                              moment(item?.slotEndTime, "HH:mm:ss").format(
                                "hh:mm A"
                              ) && (
                              <>
                                {item?.slotStartTime &&
                                  IndiaTime(
                                    item?.slotStartTime,
                                    "HH:mm:ss"
                                  ).format("hh:mm A")}
                              </>
                            )}
                          </>
                        )}
                      </span>
                    </div>
                    <div>
                      <label>AMOUNT</label>
                      <span>{item?.onlineCartItemAmount}/-</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {data.length !== 0 && (
            <div className="clearfix mt-20">
              <p className="float-left">
                Showing {data?.length} of {meta?.itemCount}
              </p>
              <ul className="pagination mt-0 float-right">
                <li>
                  <a>
                    <img src={sd_move_left} alt="<-" />
                  </a>
                </li>
                {getTotal()}

                <li>
                  <a>
                    <img src={sd_move_right} />
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default Sd_dashboard_booking_history;

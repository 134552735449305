import React from "react";
import { maintenance, SD_Logo_circle } from "../../Assets/imageList";

const Maintenance = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img src={SD_Logo_circle} alt="logo" style={{ width: "10%" }} />
      <p style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
        Sri Bhramaramba Mallikarjuna Swamy Varla Devasthanam
      </p>
      <div style={{ width: "40%" }}>
        <img
          src={maintenance}
          alt="logo"
          style={{ width: "100%", margin: "auto" }}
        />
      </div>
    </div>
  );
};

export default Maintenance;

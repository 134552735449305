/* eslint-disable react/jsx-pascal-case */
import React from "react";
import {
  sd_donation_banner,
  SD_ganapathi_homam_banner_img,
  SD_parokshaseva_banner_img,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-parokshaseva.scss";
import Sd_breadcrumb from "../../sd-common-components/sd-breadcrumb";

const SD_Paroksha_Seva_One = ({ from, data }) => {
  //-------------------------------------------------------------consts-----------------------------------------------------------//
  const donationArray = [
    "Annaprasadam",
    "Agama Patasala",
    "GoSamrakshana",
    "One Day GoSamrakshana",
    "E-Hundi",
    "SBMSD Trust",
    "Swachh Srisailam",
    "One Day Gosamrakshana Trust",
    "Gold Thapadam For Swamy Vari Temple Dwajasthambam",
    "",
  ];
  //-------------------------------------------------------------consts-----------------------------------------------------------//

  return (
    <div className="sd-Paroksha-one">
      <div className="banner">
        <div className="sd-banner-top">
          <h1>
            {from === "Paroksha-seva" ? (
              <span>
                Paroksha<span>Seva ( Virtual Seva )</span>
              </span>
            ) : from === "darshanam" ? (
              <span>Darshanam</span>
            ) : from === "donations" ? (
              <span>Donations</span>
            ) : from === "Saswatha Seva" ? (
              <span>
                Saswatha<span>Seva</span>
              </span>
            ) : from === "Support-Contact Us" ? (
              <span>Contact Us</span>
            ) : from === "faqs" ? (
              "FAQs"
            ) : (
              <span>{from} </span>
            )}

            {/* {pratyakshasevaArray.includes(from) ? (
              <span>{from.split("-")[1]}</span>
            ) : (
              "asd"
            )} */}
          </h1>
          <h4>
            {from === "Paroksha-seva"
              ? "Srisailam is Maha Shiva, Srisailam is Sri Chakra."
              : from === "darshanam" ||
                from === "donations" ||
                donationArray.includes(from)
              ? "Pray and cherish the omnipresent Maha Shiva wherever you are."
              : "Srisailam is Maha Shiva, Srisailam is Sri Chakra."}
          </h4>
        </div>

        <div className="sd-banner-bottom">
          <img
            src={
              from === "Ganapathi-homam"
                ? SD_ganapathi_homam_banner_img
                : from === "donations"
                ? sd_donation_banner
                : SD_parokshaseva_banner_img
            }
            alt="icon"
          />
        </div>
      </div>
      <Sd_breadcrumb
        data={data}
        from={from === "Paroksha-seva" ? "Paroksha-seva" : from}
      />
    </div>
  );
};
export default SD_Paroksha_Seva_One;

import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { sd_failure_icon, sd_success_icon } from "../../../../Assets/imageList";
import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
import { SD_Context } from "../../../../context/sd-context";

const SuccessPage = ({ history }) => {
  const { setActive, setActivePath } = useContext(SD_Context);
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");
  const [status, setStatus] = useState(null);
  const [orderID, setOrderID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [payment, setPayment] = useState(null);

  useEffect(() => {
    setLoading(true);
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }

    setActive("online-booking-payment");
    setActivePath("Onlinebooking-payment");
    const status = new URLSearchParams(history?.location?.search).get("status");
    const paymentData = JSON.parse?.(localStorage.getItem("payment-context"));
    const statusFlag = status.includes("success") || status.includes("failed");
    if (!statusFlag && !paymentData) {
      history?.push(`/${selectedlanguage}/devotee-app/booking-history`);
    }
    setStatus(status);
    setOrderID(new URLSearchParams(history?.location?.search).get("orderId"));
    setPayment(paymentData);
    if (status === "success" || payment === "'success'") {
      localStorage.removeItem("payment-context");
    }
    setLoading(false);
  }, []);

  const renderStatus = () => {
    if (status === "success" || status === "'success'") {
      return (
        <div className="sd-success">
          <div className="sd-success-top sd-green">
            <img src={sd_success_icon} alt="success" /> <p>Success</p>
          </div>
          <div className="sd-success-bottom">
            <p style={{ color: "#181818" }}>
              Confirmed! Your booking with ID: ({orderID}) for{" "}
              <b style={{ fontWeight: "600" }}>
                {payment?.responseData?.data?.productDetails?.productName}
              </b>{" "}
              - Rs.
              <b>{payment?.responseData?.data?.price} </b>
              has been made successfully. Looking forward to meeting you on a
              journey towards spirituality.
            </p>
            <div className="sd-success-bottom-down">
              <p
                className="sd-border-right"
                onClick={() => {
                  history?.push(
                    `/${selectedlanguage}/devotee-app/booking-history`
                  );
                }}
              >
                Booking History
              </p>{" "}
              <p
                onClick={() => {
                  history?.push(`/${selectedlanguage}/home`);
                }}
              >
                Go to Home
              </p>
            </div>
          </div>
        </div>
      );
    } else if (status === "failed" || status === "'failed'") {
      return (
        <div className="sd-profile sd-seva-form">
          <div className="sd-success">
            <div className="sd-success-top sd-redback">
              <img src={sd_failure_icon} alt="success" />{" "}
              <p>
                Sorry, your booking could not be placed.
                {/* {errorMsg}  */}
              </p>
            </div>
            <div className="sd-success-bottom">
              <p style={{ color: "#181818" }}>
                Your booking with ID: ({orderID}) for{" "}
                <b style={{ fontWeight: "600" }}>
                  {payment?.responseData?.data?.productDetails?.productName}
                </b>{" "}
                - Rs.
                {payment?.responseData?.data?.price} was not placed as there was
                a problem processing payment. Check your booking history or SMS
                after 20 mins for confirmation. Please note that any funds
                debited will credit back to your account within 7 working days.
              </p>
              <div className="sd-success-bottom-down">
                <p
                  className="sd-border-right"
                  onClick={() => {
                    if (payment.from === "darshanam") {
                      history?.push(
                        `/${selectedlanguage}/devotee-app/online-booking/darshanam/confirm`
                      );
                    } else if (payment.from === "parokshaSeva") {
                      history?.push(
                        `/${selectedlanguage}/devotee-app/online-booking/paroksha-seva/confirm-details`
                      );
                    } else if (payment.from === "pratyakshaSeva") {
                      history?.push(
                        `/${selectedlanguage}/devotee-app/online-booking/pratyaksha-seva/confirm-details`
                      );
                    } else if (payment.from === "donation") {
                      history?.push(
                        `/${selectedlanguage}/devotee-app/online-booking/donations/confirm`
                      );
                    } else {
                      history?.push(
                        `/${selectedlanguage}/devotee-app/devotee-dashboard`
                      );
                    }
                  }}
                >
                  Try Again
                </p>{" "}
                <p
                  onClick={() => {
                    history?.push(`/${selectedlanguage}/support/contact-us`);
                  }}
                >
                  Contact Us
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      toast.error("Something wrong with the response.");
      history?.push(`/${selectedlanguage}/devotee-app/booking-history`);
    }
  };

  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      <div className="sd-profile sd-seva-form">
        <h2 className="sd-side-heading fw400">
          <span className="fw700">Payment </span>Page
        </h2>
        {!loading ? renderStatus() : <Sd_loading />}
      </div>
    </div>
  );
};

export default withRouter(SuccessPage);

import React from "react";
import "../../Utils/sd-styles/sd-home.scss";
const Sd_MobileRedirection = () => {
  return (
    <div className="sd-coming-soon">
      <div>
        {/* <img src={SD_Logo_circle} alt="logo" /> */}
        Mobile redirectiono
      </div>
    </div>
  );
};
export default Sd_MobileRedirection;

import Axios from "../Utils/sd-axios/Axios";

const Sd_Donations_service = {
  getAll: async (id) => {
    try {
      const resp = await Axios.get("/donation-online-booking/valid-products", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Code": id,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.statusCode === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.statusCode !== 500 &&
        error?.response?.message
      ) {
        throw new Error(error?.response?.message);
      } else {
        //console.error(error);
        // throw new Error("something is wrong");
      }
    }
  },

  getAllOnline: async (id) => {
    try {
      const resp = await Axios.get("/donation/all/online", {
        headers: {
          "X-Temple-Code": id,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.statusCode === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.statusCode !== 500 &&
        error?.response?.message
      ) {
        throw new Error(error?.response?.message);
      } else {
        //console.error(error);
        // throw new Error("something is wrong");
      }
    }
  },
};

export default Sd_Donations_service;

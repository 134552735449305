import React, { useState } from "react";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import {
  SD_highlets_border,
  SD_Right_Arrow_white,
  sd_share,
  sd_wishlist_heart_active,
  sd_wishlist_heart_unactive,
} from "../../../Assets/imageList";
import { SD_Context } from "../../../context/sd-context";
import "../../../Utils/sd-styles/sd-donation.scss";
import Sd_loading from "../../sd-common-components/sd-loading";
import "react-toastify/dist/ReactToastify.css";

const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const Sd_donation_inner_one = ({ history, type }) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//

  const [selectedlanguage, setSelectedlanguage] = useState("en-in");
  const { fav, addFavorite, removeFavorite, commonMatchFormat } =
    React.useContext(SD_Context);
  const [copyFlag, setCopyFlag] = useState("");
  const [index, setIndex] = useState(0);
  const [loading, setLoadin] = useState(true);
  const data = {
    sbmsagamapatashalatrust: {
      banner: (
        <p>
          Charity is crux of humanity,
          <br />
          The way for righteousness, <br />
          The way to show the generosity of spirit, <br />
          The tenet of Lord Shiva
          <br />
        </p>
      ),
      heading: null,
      desc: `Though a tree has grown with many branches, leaves, flowers, and
                fruits, yet the foundation of that tree is none other than its roots.
                Similarly, we could become progressive in the modern world, yet our
                roots of education and development are the Vedas. Srisailam, the adobe
                of sacred Vedas has Agama Patasala which is operated under the
                supervision of Srisaila Devasthanam. Agama Patasala offers free
                education of Aagamas. The culture and procedures of Pooja and Homam
                rituals are being taught in Aagamas. Srisaila Devasthanam accepts
                donations to upkeep Agama Patasala through Agama Patasala Trust.
                People can donate online to Agama Patasala Trust and become divine
                donors of education.`,
    },
    sbmsonedayannaprasadam: {
      banner: (
        <p>
          Gaja thuraga sahasram gokulakoti dhaanam kanaka rjatha pathram
          <br />
          methini saagarantham Upaka kula vishuttham koti kanyapradhanam
          <br />
          nabhavathu samathulyam annadaana samaanaha
        </p>
      ),
      header: null,
      desc: `  One AnnaPrasadam is greater than donating a thousand elephants, one
    crore cows, gold, land till the sea, contruibuting services of the
    whole dynasty,succouring brides in need during the marriage. The
    sacred place where Jyotirlingam and Shakthi Peetam reside under one
    temple roof is one and only Srisailam. In the most sacred place,
    devotees have the privilege to donate for AnnaPrasadam which is held
    at Annadaana Satram daily.`,
    },
    goldthapadamforswamyvaritempledwajasthambam: {
      banner: (
        <p>
          Charity is crux of humanity,
          <br />
          The way for righteousness, <br />
          The way to show the generosity of spirit, <br />
          The tenet of Lord Shiva
          <br />
        </p>
      ),
      desc: ` Srisailam is sanctified with Sri Mallikarjuna Swamy in Jyothirlinga form and Sri Bhramaramba Devi as Sakthi Rupam.  The online donation facility is available via internet banking and the UPI facility.`,
    },
    sbmssaswathaannaprasadam: {
      banner: (
        <p>
          Charity is crux of humanity,
          <br />
          The way for righteousness, <br />
          The way to show the generosity of spirit, <br />
          The tenet of Lord Shiva
          <br />
        </p>
      ),
      desc: `  One AnnaPrasadam is greater than donating a thousand elephants, one
      crore cows, gold, land till the sea, contruibuting services of the
      whole dynasty,succouring brides in need during the marriage. The
      sacred place where Jyotirlingam and Shakthi Peetam reside under one
      temple roof is one and only Srisailam. In the most sacred place,
      devotees have the privilege to donate for AnnaPrasadam which is held
      at Annadaana Satram daily.`,
    },

    sbmsgowsamrakshanatrust: {
      banner: (
        <p>
          Charity is crux of humanity,
          <br />
          The way for righteousness, <br />
          The way to show the generosity of spirit, <br />
          The tenet of Lord Shiva
          <br />
        </p>
      ),
      desc: (
        <>
          {" "}
          <span>“Sarva deva sthitha dhehe, Sarva devamihi gavo”</span>
          <br />
          <br />
          Vedas suggest that all gods reside in every fraction of a cow. So it
          is believed that a cow has the sanctity of all the gods present in the
          whole universe. Now it’s time to save and serve the holy cow. For this
          purpose, Srisaila Devasthanam operates a huge Gosala with more than
          1500 cows. To save and serve cows, you can donate to the GoSamrakshana
          trust operated under the Srisaila Devasthanam.
        </>
      ),
    },
    onedaygowsamrakshananidhi: {
      banner: (
        <p>
          Charity is crux of humanity,
          <br />
          The way for righteousness, <br />
          The way to show the generosity of spirit, <br />
          The tenet of Lord Shiva
          <br />
        </p>
      ),
      desc: (
        <>
          {" "}
          <span>“Sarva deva sthitha dhehe, Sarva devamihi gavo”</span>
          <br />
          <br />
          Vedas suggest that all gods reside in every fraction of a cow. So it
          is believed that a cow has the sanctity of all the gods present in the
          whole universe. Now it’s time to save and serve the holy cow. For this
          purpose, Srisaila Devasthanam operates a huge Gosala with more than
          1500 cows. To save and serve cows, you can donate to the GoSamrakshana
          trust operated under the Srisaila Devasthanam.
        </>
      ),
    },
    sbmssaswathaannaprasadam10yearsonly: {
      banner: (
        <p>
          Gaja thuraga sahasram gokulakoti dhaanam kanaka rjatha pathram
          <br />
          methini saagarantham Upaka kula vishuttham koti kanyapradhanam
          <br />
          nabhavathu samathulyam annadaana samaanaha
        </p>
      ),
      header: null,
      desc: `Annaprasadam is greater than donating a thousand elephants, one
    crore cows, gold, land till the sea, contruibuting services of the
    whole dynasty,succouring brides in need during the marriage. The
    sacred place where Jyotirlingam and Shakthi Peetam reside under one
    temple roof is one and only Srisailam. In the most sacred place,
    devotees have the privilege to donate for AnnaPrasadam which is held
    at Annadaana Satram daily.`,
    },

    sbmspranadanatrust: {
      banner: (
        <p>
          Charity is crux of humanity,
          <br />
          The way for righteousness, <br />
          The way to show the generosity of spirit, <br />
          The tenet of Lord Shiva
          <br />
        </p>
      ),
      desc: ` Srisailam is sanctified with Sri Mallikarjuna Swamy in Jyothirlinga
    form and Sri Bhramaramba Devi as Sakthi Rupam. Srisaila Devasthanam
    operates a Health Care Centre for the sake of devotees who visit
    Srisailam. It provides first aid and basic treatment for common
    illnesses like fever and minor infections. Devotees and generous
    people can donate to this Health Care Centre through Pranadana Trust.
    The online donation facility is available via internet banking and the
    UPI facility.`,
    },
    sbmsswachhsrisailam: {
      banner: (
        <p>
          Charity is crux of humanity,
          <br />
          The way for righteousness, <br />
          The way to show the generosity of spirit, <br />
          The tenet of Lord Shiva
          <br />
        </p>
      ),
      desc: ` Lord always admires the shower of pure-hearted prayers from his
          devotees. To pray wholeheartedly, many devotees travel all the way to
          Srisailam. To facilitate hearty prayers, it’s our responsibility to
          keep Srisailam and its premises clean. Srisaila Devasthanam has
          arranged Swachh Srisailam Trust to maintain Srisailam clean. Devotees
          can donate to Swachh Srisailam Trust to become part of conserving
          beautiful nature and maintaining the cleanliness of Srisailam.`,
    },
    sbmssrisailatvbhakthichannel: {
      banner: (
        <p>
          Charity is crux of humanity,
          <br />
          The way for righteousness, <br />
          The way to show the generosity of spirit, <br />
          The tenet of Lord Shiva
          <br />
        </p>
      ),
      desc: `Donation to Srisaila TV ensures 24 x 7 Webcast devotional content in Telugu which caters to the people of the Hindu religion. Your donation shall be utilised to improve Infrastructure, Technical undertakings, telecasting Spiritual Programs in important languages. Launched in March 2017, this Srisaila Devasthanam Product is south India's first, Lord Shiva's devotional channel on Telugu. The channel telecasts fiction and non-fiction Spirtual Programs.`,
    },
    ehundi: {
      banner: (
        <p>
          Charity is crux of humanity,
          <br />
          The way for righteousness, <br />
          The way to show the generosity of spirit, <br />
          The tenet of Lord Shiva
          <br />
        </p>
      ),
      desc: `  Lord Shiva and Parvathi Devi reside on Srisailam guarded by 8
          Gateways. e-Hundi allows donations from people across the globe for
          the welfare of the sacred Srisailam. You can donate to Srisaila
          Devasthanam e-Hundi through the Internet. It is believed that when
          devotees make their donations, they earn happiness through the
          complete blessings of Sri Bhramaramba Sametha Mallikarjuna Swamy.`,
    },
  };

  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useeffect-----------------------------------------------------------//

  React.useEffect(() => {
    setLoadin(true);
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    if (type) {
      setIndex(commonMatchFormat(type?.name));
      // console.log(type?.name?.replace?.(/\s/g, "").toLowerCase());
    }
    setLoadin(false);
  }, []);

  //-------------------------------------------------------------useeffect-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//

  const onShareFunction = (path) => {
    setCopyFlag(true);
    navigator.clipboard.writeText(path);
    setTimeout(() => {
      setCopyFlag(false);
    }, 2000);
  };
  const getImage = () => {
    if (localStorage.getItem("accessToken")) {
      // console.log(type.id);
      let res = fav.find(
        (item) => item.item_id === type.id && item.type === "donation"
      );
      if (res) {
        return (
          <img
            className="border-l"
            src={sd_wishlist_heart_active}
            alt="active"
            onClick={() => {
              removeFavorite(res.id);
            }}
          />
        );
      } else {
        return (
          <img
            className="border-l"
            src={sd_wishlist_heart_unactive}
            alt="unactive"
            onClick={async () => {
              try {
                let resp = await addFavorite(type, "donation");

                if (resp === "redirect") {
                  history?.push(
                    `/${selectedlanguage}/devotee/signin?to=donations/${type?.name
                      ?.replace?.(/\s/g, "-")
                      .toLowerCase()}`
                  );
                }
              } catch (e) {
                toast.error(e.message);
              }
            }}
          />
        );
      }
    } else {
      return (
        <img
          className="border-l"
          src={sd_wishlist_heart_unactive}
          alt="unactive"
          onClick={async () => {
            history?.push(
              `/${selectedlanguage}/devotee/signin?to=donations/${type?.name
                ?.replace?.(/\s/g, "-")
                .toLowerCase()}`
            );
          }}
        />
      );
    }
  };

  //------------------------------------------------------------functions-----------------------------------------------------------//

  return (
    <>
      {loading ? (
        <Sd_loading />
      ) : (
        <div className="sd-donation-annaprasadam">
          <div className="sd-donation-annaprasadam-top">
            <div className="sd-donation-annaprasadam-inner">
              <img
                className="sd-annaprasadam-inner-image"
                src={SD_highlets_border}
                alt={SD_highlets_border}
              />
              {data[index]?.banner}
            </div>
          </div>
          <div className="sd-donation-annaprasadam-bottom">
            <h3>{data[index]?.desc}</h3>
            <div className="sd-donation-annaprasadam-div-bottom">
              <div className="sd-a-left">
                <img
                  className="padding-r"
                  src={sd_share}
                  alt="share"
                  onClick={() => {
                    onShareFunction(
                      `https://www.srisailadevasthanam.org/${selectedlanguage}/donations/${type?.name
                        ?.replace?.(/\s/g, "-")
                        .toLowerCase()}`
                    );
                  }}
                />
                {getImage()}
              </div>
              {!type?.disabled && (
                <button
                  onClick={() => {
                    if (localStorage.getItem("accessToken")) {
                      history?.push(
                        `/${selectedlanguage}/devotee-app/online-booking/donations?to=${type?.name
                          ?.replace?.(/\s/g, "-")
                          .toLowerCase()}`
                      );
                    } else {
                      history?.push(
                        `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/donations?to=${type?.name
                          ?.replace?.(/\s/g, "-")
                          .toLowerCase()}`
                      );
                    }
                  }}
                >
                  Donate Now
                  <img className="imgs" src={SD_Right_Arrow_white} alt=">" />
                </button>
              )}
            </div>
          </div>{" "}
          {copyFlag && <div className="sd-copy">Link Copied</div>}{" "}
        </div>
      )}
    </>
  );
};
export default withRouter(Sd_donation_inner_one);

import React, { useContext, useEffect, useState } from "react";
import Sd_gallery_semi_view from "../../../Components/sd-common-components/sd-gallery-semi-view";
import { SD_Context } from "../../../context/sd-context";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sd_faqs_semi_view from "../../../Components/sd-common-components/sd-faqs-semi-view";
import Sd_loading from "../../../Components/sd-common-components/sd-loading";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import SD_Paroksha_Seva_Inner_Component_One from "../../../Components/body/paroksha-seva/sd-paroksha-seva-inner-components/sd-paroksha-seva-inner-component-one";
import SD_Paroksha_Seva_Inner_Component_Two from "../../../Components/body/paroksha-seva/sd-paroksha-seva-inner-components/sd-paroksha-seva-inner-component-two";
import SD_Paroksha_Seva_One from "../../../Components/body/paroksha-seva/sd-paroksha-seva-component-one";
import ParokshaSevaService from "../../../services/sd-parokshaseva.service";

const Sd_sevas_and_darshanam_parokshaseva_common = ({ match }) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const { parokshaSevaData, templeID, setParokshaSevaData, commonMatchFormat } =
    useContext(SD_Context);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useeffect-----------------------------------------------------------//
  useEffect(() => {
    if (parokshaSevaData.length !== 0) {
      setLoading(true);
      let datas = parokshaSevaData.find(
        (item) =>
          commonMatchFormat(item?.name) === commonMatchFormat(match.params.id)
      );
      if (datas) {
        setData(datas);
        setLoading(false);
      }
    } else {
      getData();
    }
  }, [match]);
  //-------------------------------------------------------------useeffect-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//
  const getData = async () => {
    setLoading(true);
    try {
      let data = await ParokshaSevaService.getAllOnline(templeID);
      data = data.filter(
        (item) => item.sevaType === "parokshaSeva" && item.onlineEnabled
      );
      setParokshaSevaData(data);

      const datas = data.find(
        (item) =>
          commonMatchFormat(item?.name) === commonMatchFormat(match.params.id)
      );
      setData(datas);
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };
  //-------------------------------------------------------------functions-----------------------------------------------------------//
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {`${data?.name} Srisailam Online Booking | Paroksha Seva | Srisailam
          Online`}
        </title>
        <meta
          name="description"
          content={`Participate in ${data?.name} by purchasing Paroksha Seva tickets Online.`}
        />
        <meta
          name="keywords"
          content={`${data?.name}, Srisailam Online Booking, Paroksha Seva, Srisailam Online`}
        />
      </Helmet>
      {loading ? (
        <Sd_loading />
      ) : (
        <div className="w-100">
          <SD_Paroksha_Seva_One from={`${data?.name}`} data={data} />
          <SD_Paroksha_Seva_Inner_Component_One data={data} />
          <SD_Paroksha_Seva_Inner_Component_Two data={data} />
          <Sd_faqs_semi_view from="paroksha_seva" />
          <Sd_gallery_semi_view />
        </div>
      )}
    </>
  );
};
export default withRouter(Sd_sevas_and_darshanam_parokshaseva_common);

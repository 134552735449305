import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Sd_loading from "../../../Components/sd-common-components/sd-loading";
import { SD_Context } from "../../../context/sd-context";

import { SD_Print_Icon } from "../../../Assets/imageList";
import { toast } from "react-toastify";

import Sd_DashboardService from "../../../services/sd-dashboard.service";
import PrintDonorCoupon from "./sd-donor-module-print";
import moment from "moment";

const Sd_Dashboard_Donor_module = () => {
  const { setActive, setActivePath } = useContext(SD_Context);
  const [modal, setModal] = useState(false);
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [selectedLanguage, setSelectedlanguage] = React.useState("en-in");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [blocks, setBlocks] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [printData, setPrintData] = useState();
  const [type, setType] = useState("");

  const couponType = [
    "DONAR_ACCOMMODATION",
    "DONOR_DARSHANAM",
    "DONOR_SEVA",
    "DONOR_SHOP_PRODUCT",
    // "ONCE_A_USER",
  ];

  useEffect(() => {
    setLoading(true);
    setActive("donor-module");
    setActivePath("Donor Coupon");
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await Sd_DashboardService.getAllDonorCoupons();
      const coupons = [];
      // response["DONAR_ACCOMMODATION"].items.map((item) => {
      //   return coupons.push(
      //     ...item.coupons.map((innerItem) => {
      //       return {
      //         name: item.accBlock.name,
      //         donorName: item?.donorName,
      //         ...innerItem,
      //       };
      //     })
      //   );
      // });
      setData(response);

      const blocksData = [];
      response["DONAR_ACCOMMODATION"].items.forEach((item) => {
        let findOne = blocksData.find(
          (innerItem) =>
            innerItem.accBlock.name === item.accBlock.name &&
            item.accRoomId === innerItem.accRoomId
        );
        if (!findOne) {
          blocksData.push(item);
        }
      });
      setBlocks(blocksData);
      setSelectedBlock(blocksData[0]);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
    setLoading(false);
  };

  const onPrintFunction = (couponData) => {
    setModal(true);
    let mappedData = couponData.map((item) => {
      switch (item.type) {
        case "DONOR_SEVA": {
          setType("Seva");

          break;
        }
        case "DONOR_SHOP_PRODUCT": {
          setType("Product");

          break;
        }
        case "DONOR_DARSHANAM": {
          setType("Darshanam");

          break;
        }
        case "ONCE_A_USER": {
          setType("Once a user");

          break;
        }
        case "DONAR_ACCOMMODATION": {
          setType("Accommodation");

          break;
        }
        default:
          return [];
      }
      return [
        { label: "Donor Name", data: item?.donorName },
        {
          label: `${item.type
            ?.replaceAll("DONOR_", "")
            ?.replaceAll("DONAR_", "")
            ?.replaceAll("_", " ")
            ?.toLowerCase()} Name`,
          data: item?.name,
        },
        {
          label: "Coupon Code",
          data: item?.couponCode,
        },
        {
          label: "Coupon Value",
          data: item?.maxOfferAmount ? (
            <>Rs. {item?.maxOfferAmount}</>
          ) : (
            <>{item?.maxOfferPercentage} %</>
          ),
        },
        {
          label: "Coupon Validity",
          data: `${moment(item?.validFrom, "YYYY-MM-DD").format("DD-MM-YYYY")} -
        ${moment(item?.validTo, "YYYY-MM-DD").format("DD-MM-YYYY")}`,
        },
      ];
    });
    setPrintData(mappedData);
  };

  const displayCoupons = (flag) => {
    let div = data[flag]?.items?.map((e, i) => {
      return e?.coupons?.map((innerEle, j) => {
        return (
          <div
            key={`coupn-box-${j}`}
            className={!innerEle.used ? "coupon-box" : "coupon-box opacity-05"}
            onClick={() => {
              navigator.clipboard.writeText(innerEle.couponCode);
              toast.success("Coupon copied!!");
            }}
          >
            <div className="cc-left">
              {innerEle.maxOfferAmount ? (
                <>
                  Rs.{innerEle.maxOfferAmount} <br />
                  OFF
                </>
              ) : (
                <>{innerEle.maxOfferPercentage} %</>
              )}
            </div>
            <div className="up-circle"></div>
            <div className="down-circle"></div>
            <div className="cc-right">
              <p className="cc-right-heading">Coupon Code</p>
              <p>
                {innerEle.couponCode}{" "}
                <img
                  onClick={() => {
                    onPrintFunction([
                      {
                        ...innerEle,
                        donorName: e?.donorName,
                        name: e[
                          flag?.includes("ACCOMMODATION")
                            ? "accBlock"
                            : flag?.includes("SEVA")
                            ? "seva"
                            : flag?.includes("DARSHANAM")
                            ? "darshanam"
                            : flag?.includes("PRODUCT")
                            ? "shopProduct"
                            : "darshanam"
                        ]?.name,
                      },
                    ]);
                  }}
                  src={SD_Print_Icon}
                  style={{ margin: "-1px 0px 0px 5px", height: "14px" }}
                />
              </p>
            </div>
            {
              <div
                className={!e.used ? `cc-used used-c` : "cc-used not-used-c"}
              >
                {!e.used ? "Available" : "Used"}
              </div>
            }
          </div>
        );
      });
    });
    return div;
  };

  const displayBlocks = () => {
    if (blocks?.length === 0 || !blocks) {
      return <div>No Blocks available</div>;
    }
    return blocks.map((item, i) => {
      return (
        <div
          className="sd-acc-blocks"
          key={"blocks" + i}
          tabIndex={i}
          onClick={() => {
            setSelectedBlock(item);
          }}
        >
          <div className="sd-block-name">
            <div
              className={
                item?.id === selectedBlock?.id
                  ? "sd-selected-border"
                  : "sd-plain-border"
              }
            ></div>
            {item?.accBlock?.name} <br /> (Block)
          </div>
          <div className="sd-room-name">
            <span style={{ fontSize: "13px" }}>Coupons </span>
            <br />
            {item?.noOfCoupons}
          </div>
        </div>
      );
    });
  };
  return (
    <>
      <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard">
        {loading ? (
          <Sd_loading />
        ) : (
          <>
            <h2
              className="sd-side-heading fw500"
              style={{ paddingBottom: "10px" }}
            >
              <span className="fw700">Donor</span> Coupon
            </h2>
            {couponType?.map((type) => {
              return (
                <>
                  <hr className="clearfix col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0"></hr>
                  <h4
                    className="sd-side-heading fw500"
                    style={{ paddingBottom: "10px", fontSize: "20px" }}
                  >
                    <span
                      className="fw700"
                      style={{ textTransform: "capitalize" }}
                    >
                      {type
                        ?.replaceAll("DONOR_", "")
                        ?.replaceAll("DONAR_", "")
                        ?.replaceAll("_", " ")
                        ?.toLowerCase()}
                    </span>{" "}
                    Coupons{" "}
                  </h4>
                  {type === "DONAR_ACCOMMODATION" && (
                    <>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pr-0 sd-dd-boxes flex-start pt-20">
                        {displayBlocks()}
                      </div>
                      <hr className="clearfix col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0"></hr>
                    </>
                  )}

                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pr-0 sd-dd-boxes flex-start pt-20">
                    {type === "DONAR_ACCOMMODATION"
                      ? selectedBlock
                        ? displayCoupons(type)
                        : "No Coupons available"
                      : displayCoupons(type)?.length !== 0
                      ? displayCoupons(type)
                      : "No Coupons available"}
                  </div>
                </>
              );
            })}
          </>
        )}
      </div>{" "}
      {modal && (
        <div className="sd-profile-popup" style={{ padding: "0px !important" }}>
          <div
            className="inner"
            style={{
              height: "auto",
              display: "flex",
              flexDirection: "column",
              minHeight: "150px",
              width: "fit-content",
              overflowY: "scroll",
              padding: "0px",
            }}
          >
            <PrintDonorCoupon
              data={printData}
              setModal={setModal}
              type={type}
            />
          </div>
        </div>
      )}{" "}
    </>
  );
};
export default withRouter(Sd_Dashboard_Donor_module);

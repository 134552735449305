import React, { useState, useContext, useEffect, useRef } from "react";
import { withRouter } from "react-router";
import {
  sd_dashboard_dob,
  sd_rupee,
  // sd_script,
  sd_side_arrow,
} from "../../../../Assets/imageList";
import { SD_Context } from "../../../../context/sd-context";
// import DatePicker from "react-date-picker";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sd_DashboardService from "../../../../services/sd-dashboard.service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../Utils/sd-styles/sd-datepicker.scss";
import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
import moment from "moment";
import { addDays } from "date-fns";
// import {DecryptInformation} from "../../../../Utils/sd-encrypt/encrypt";
toast.configure();

const Sd_dashboard_online_booking_accommodation = ({ history }) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const {
    setActive,
    setActivePath,
    currentBookingAccomadations,
    setcurrentBookingAccomdations,
    logout,
    roomMapData,
    setRoomMapData,
  } = useContext(SD_Context);

  const [selectedLanguage, setSelectedlanguage] = useState("en-in");
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [startDate, setStartDate] = useState(addDays(new Date(), 1));
  const [reserved, setReserved] = useState({});
  const [ac, setAc] = useState("");
  const [beds, setBeds] = useState("");
  const [search, setSearch] = useState(false);
  const [err, setErr] = useState([]);
  const [first, setFirst] = useState(false);
  const [data, setData] = useState([]);
  const templeID = process.env.REACT_APP_TEMPLE ?? "SBMSD";
  const [occupancy, setOccupancy] = useState(2);
  const [loading, setLoading] = useState();
  const ref = useRef(null);
  const [slotsData, setSlotsData] = useState([]);
  const [highlightDates, setHighlightDates] = useState([]);
  const [excludeDates, setExcludeDates] = useState([]);

  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useeffects-----------------------------------------------------------//

  useEffect(() => {
    if (
      new URLSearchParams(history?.location?.search).get("state") === "clear"
    ) {
      setcurrentBookingAccomdations(null);
    }
    setActive("online-booking-accommodations");
    setActivePath("Onlinebooking-Accommodations");
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    if (currentBookingAccomadations) {
      setStartDate(currentBookingAccomadations?.startDate);
      setOccupancy(currentBookingAccomadations?.occupancy);
      setAc(currentBookingAccomadations?.ac);
      setSearch(false);
      // setReserved(reserved);
    }
    roomTypes();
  }, []);

  useEffect(() => {
    if (occupancy !== "" && startDate !== "" && ac !== "") {
      getQuota();
    }
  }, [occupancy, startDate, ac]);

  //-------------------------------------------------------------useeffects-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//

  const roomTypes = async () => {
    setLoading(true);
    try {
      let res = await Sd_DashboardService.getRoomType(templeID);
      setRoomMapData(res);
    } catch (e) {
      toast.error(e.message);
      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/accommodations`
        );
      }
      setLoading(false);

      return;
    }
  };

  const handleSubmit = (item) => {
    setReserved(item);

    let data = {
      reserved: item,
      startDate,
      beds,
      ac,
      occupancy,
    };
    setcurrentBookingAccomdations(data);
    setTimeout(() => {
      history?.push(
        `/${selectedLanguage}/devotee-app/online-booking/accommodations/confirm`
      );
    }, 1000);
  };
  const getRoomsDetails = () => {
    if (
      data?.filter(
        (item) =>
          item?.date?.toString() ===
            moment(startDate)?.format("YYYY-MM-DD")?.toString() &&
          item?.availableQuantity > 0
      )?.length === 0
    ) {
      return "No Rooms available";
    }
    return data
      ?.filter(
        (item) =>
          item?.date?.toString() ===
            moment(startDate)?.format("YYYY-MM-DD")?.toString() &&
          item?.availableQuantity > 0
      )
      ?.map((item, i) => {
        return (
          <div
            className="col-xs-12 col-sm-12 col-md-4 col-lg-4 xs-pl-0  xs-pr-0"
            key={i}
          >
            <div className="sd-seva-box text-center">
              {/* <img
                src={item?.accBlockCategory?.imageUrl}
                alt={SD_gallery_02}
                className="sd-acc-img"
              /> */}
              <div className="sd-side-heading-2 sd-acc">
                {item?.accBlockCategory?.accBlock?.name}
              </div>
              <div
                style={{
                  fontSize: "15px",
                  height: "85px",
                  overflow: "hidden",
                  textAlign: "left",
                  marginBottom: "10px",
                }}
              >
                {item?.accBlockCategory?.accBlock?.description}
              </div>
              <p className="sd-seva-time">
                <span style={{ marginRight: "3px", fontWeight: "600" }}>
                  {item?.availableQuantity}
                </span>
                -<span style={{ fontWeight: "400" }}> Available Rooms </span>{" "}
                <br />
                <span style={{ fontWeight: "600" }}>
                  <span style={{ textTransform: "capitalize" }}>
                    {item?.accBlockCategory?.roomType?.replaceAll("_", " ")}
                  </span>
                </span>
                <br></br>
              </p>
              <div className="clearfix mt-20">
                <span
                  className="float-left sd-seva-cost"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <img
                    src={sd_rupee}
                    alt="Rs"
                    height="13px"
                    style={{ marginTop: "2px" }}
                  />
                  <span style={{ fontWeight: "600" }}>
                    {item?.accBlockCategory?.price}{" "}
                  </span>
                  <span style={{ fontWeight: "400" }}> / Day</span>
                </span>
                <div className="float-right">
                  <a
                    className="sd-btn-orange"
                    onClick={() => {
                      handleSubmit(item);
                    }}
                  >
                    Book Now
                    <img className="sd-arrow-btn ml-5" src={sd_side_arrow} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        );
      });
  };

  const getDates = (startDate, endDate, quotaDates) => {
    let date = startDate;
    let dates = [];
    while (date <= endDate) {
      if (
        !quotaDates?.includes(moment(date, "YYYY-MM-DD").format("YYYY-MM-DD"))
      ) {
        dates.push(new Date(date));
      }
      date = moment(date, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
    }
    setExcludeDates([...dates]);
  };

  const getQuota = async () => {
    setLoading(true);
    let quotaDates = [];
    let availableDates = [];
    let availableDatesString = [];
    let almostBookedDates = [];
    let almostBookedDatesString = [];
    let notAvailableDates = [];
    let notAvailableDatesString = [];
    let a = true;
    if (a) {
      let data = [...err];
      if (!occupancy) {
        data.push({ type: "date", msg: "Please Select No. of Persons" });
      }

      if (ac === "") {
        data.push({ type: "ac", msg: "Please Select a Type" });
      }
      setErr(data);
      if (data.length !== 0) {
        setLoading(false);
        return;
      }
    }

    if (!occupancy || ac === "") {
      toast.error("Please fill all the details and search");
      setLoading(true);
      false;
      return;
    }
    try {
      let res = await Sd_DashboardService.getDailyQuota(
        ac,
        moment(addDays(new Date(), 1)).format("YYYY-MM-DD"),
        moment(addDays(new Date(), 30)).format("YYYY-MM-DD"),
        occupancy,
        templeID
      );
      setData(res);
      if (!first) {
        setFirst(true);
      }
      setSearch(true);
      res?.map((item) => {
        quotaDates.push(item?.date);
        if (item?.quantity > 0) {
          if (Number(item?.availableQuantity / item?.quantity) * 100 > 50) {
            availableDates.push(new Date(item?.date));
            availableDatesString.push(new Date(item?.date).toString());
            if (
              notAvailableDatesString.includes(new Date(item?.date).toString())
            ) {
              let index = notAvailableDatesString.indexOf(
                new Date(item?.date).toString()
              );
              notAvailableDates.splice(index, 1);
              notAvailableDatesString.splice(index, 1);
            }
          } else if (
            Number(item?.availableQuantity / item?.quantity) * 100 <= 50 &&
            Number(item?.availableQuantity / item?.quantity) * 100 > 0
          ) {
            almostBookedDates.push(new Date(item?.date));
            almostBookedDatesString.push(new Date(item?.date).toString());
            if (
              notAvailableDatesString.includes(new Date(item?.date).toString())
            ) {
              let index = notAvailableDatesString.indexOf(
                new Date(item?.date).toString()
              );
              notAvailableDates.splice(index, 1);
              notAvailableDatesString.splice(index, 1);
            }
          } else {
            if (
              !availableDatesString.includes(new Date(item?.date).toString())
            ) {
              if (
                !almostBookedDatesString.includes(
                  new Date(item?.date).toString()
                )
              ) {
                notAvailableDates.push(new Date(item?.date));
                notAvailableDatesString.push(new Date(item?.date).toString());
              }
            }
          }
        } else {
          notAvailableDates.push(new Date(item?.date));
          notAvailableDatesString.push(new Date(item?.date).toString());
        }
      });
      notAvailableDates?.map((date) => {
        if (
          availableDatesString.includes(date.toString()) ||
          almostBookedDatesString.includes(date.toString())
        ) {
          let index = notAvailableDatesString.indexOf(date.toString());
          notAvailableDates.splice(index, 1);
          notAvailableDatesString.splice(index, 1);
        }
      });
      setHighlightDates([
        { "react-datepicker__day--highlighted-custom-1": availableDates },
        { "react-datepicker__day--highlighted-custom-2": almostBookedDates },
        { "react-datepicker__day--highlighted-custom-3": notAvailableDates },
      ]);
      getDates(
        moment(addDays(new Date(), 1)).format("YYYY-MM-DD"),
        moment(addDays(new Date(), 30)).format("YYYY-MM-DD"),
        quotaDates
        // notAvailableDates
      );
      setLoading(false);
    } catch (e) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      toast.error(e.message);
      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/accommodations`
        );
      }
      setLoading(false);

      return;
    }
  };

  //-------------------------------------------------------------functions-------------------------------------------------------------//
  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      <div className="sd-profile sd-seva-form">
        <h2 className="sd-side-heading fw400">
          <span className="fw700">Accommodation </span>Booking
        </h2>
        <div className="clearfix">
          {/* <p className="flex-c w-100 border-c pb-30  pt-20 br-4">
            <img
              src={sd_script}
              alt="#"
              height="80px"
              width="80px"
              className="mr-5 mb-5 opace"
            />
            We are sorry to say that online accommodation booking is temporarily
            unavailable. We regret the inconvenience.
          </p> */}
          <form className="sd-form" style={{ marginBottom: "10px" }}>
            <div className="sd-profile sd-acc-profile">
              <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 xssm-pl-0 xssm-pr-0 sd-acc-sub pl-0 mb-0 pr-0 ">
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0">
                  <label>Check in</label>
                  <label className="form-control sd-q-label">
                    8 AM, {moment(startDate).format("Do MMMM YYYY")}
                  </label>
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0">
                  <label>Check out</label>
                  <label className="form-control sd-q-label">
                    8 AM ,{" "}
                    {moment(addDays(startDate, 1)).format("Do MMMM YYYY")}
                  </label>
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0">
                  <label>
                    No. of persons <span className="sd-imp">*</span>
                  </label>
                  <select
                    className={
                      err?.find((e) => e.type === "occupancy")
                        ? "form-control sd-red"
                        : occupancy !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={occupancy}
                    onChange={(e) => {
                      if (e.target.value) {
                        if (
                          err.find(
                            (e) => e.type === "occupancy" || e.type === "server"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "occupancy" && e.type !== "server") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setOccupancy(e.target.value);
                      }
                    }}
                  >
                    <option
                      style={{
                        display: "none",
                      }}
                    >
                      Select No. of persons
                    </option>

                    <option value={"2"} key={"2"}>
                      2
                    </option>
                    <option value={"3"} key={"3"}>
                      3
                    </option>
                    <option value={"4"} key={"4"}>
                      4
                    </option>
                  </select>
                  <p style={{ color: "#181818", fontWeight: "600" }}>
                    Free for Children under 10 years
                  </p>
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "occupancy")?.msg}
                  </p>
                </div>

                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0">
                  <label>
                    Room Type<span className="sd-imp">*</span>
                  </label>
                  <select
                    className={
                      err?.find((e) => e.type === "ac")
                        ? "form-control sd-red"
                        : ac !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={ac}
                    onChange={(e) => {
                      if (e.target.value) {
                        if (
                          err.find(
                            (e) => e.type === "ac" || e.type === "server"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "ac" && e.type !== "server") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setData([]);
                        setSearch(false);
                        setLoading(false);
                        setFirst(false);
                        setAc(e.target.value);
                      }
                    }}
                  >
                    {loading ?? <option>Laoding ...</option>}
                    <option
                      style={{
                        display: "none",
                      }}
                    >
                      Select a room type
                    </option>
                    {roomMapData.map((item) => {
                      return (
                        <option value={item} key={item}>
                          {item?.replaceAll("_", " ")}
                        </option>
                      );
                    })}
                  </select>
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "ac")?.msg}
                  </p>
                </div>

                {/* <div
                  className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-20  xssm-pl-0 pr-0"
                  style={{ float: "revert" }}
                >
                  <a
                    className="sd-btn-orange"
                    style={{ whiteSpace: "nowrap" }}
                    onClick={() => {
                      getQuota();
                    }}
                  >
                    Search
                    {loading ? (
                      <img className="sd-arrow-btn ml-5" src={sd_btn_loading} />
                    ) : (
                      <img
                        className="sd-arrow-btn ml-5 mt-2"
                        src={SD_Right_Arrow_white}
                      />
                    )}
                  </a>
                </div> */}
              </div>
              <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 xssm-pl-0 xssm-pr-0 sd-acc-main ">
                <div className={"sd-dob sd-acc-calender"}>
                  <DatePicker
                    allowSameDay={false}
                    style={{
                      width: "90%",
                      border: "none",
                      backgroundColor: "blue",
                    }}
                    inline
                    monthsShown={window.innerWidth < 600 ? 1 : 2}
                    highlightDates={highlightDates}
                    excludeDates={excludeDates}
                    minDate={addDays(new Date(), 1)}
                    maxDate={addDays(new Date(), 36)}
                    calendarIcon={<img src={sd_dashboard_dob} alt="" />}
                    clearIcon={null}
                    selected={startDate}
                    onChange={(date) => {
                      if (
                        err.find(
                          (e) => e.type === "date" || e.type === "server"
                        )
                      ) {
                        let data = err.filter((e) => {
                          if (e.type !== "date" && e.type !== "server") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      setStartDate(date);
                      setData([]);
                    }}
                  />
                </div>
                <div className=" sd-quota">
                  <div className="sd-quota-div">
                    <p
                      style={{
                        backgroundColor: "rgb(0, 207, 0)",
                        color: "rgb(0, 207, 0)",
                      }}
                    ></p>
                    Available
                  </div>
                  <div className="sd-quota-div">
                    <p
                      style={{
                        backgroundColor: "orange",
                        color: "orange",
                      }}
                    ></p>
                    Almost Booked
                  </div>
                  <div className="sd-quota-div">
                    <p
                      style={{
                        backgroundColor: "rgb(201, 0, 0)",
                        color: "rgb(201, 0, 0)",
                      }}
                    ></p>
                    Not Available
                  </div>
                  <div className="sd-quota-div">
                    <p
                      style={{
                        backgroundColor: "rgb(117,117,117)",
                        color: "rgb(117,117,117)",
                      }}
                    ></p>
                    Quota Not Released
                  </div>
                  {/* <div className="sd-quota">
                        
                      </div> */}
                </div>
              </div>
            </div>

            <hr className="clearfix"></hr>

            {search === true ? (
              <div className="clearfix sd-seva-content">
                <h3
                  className="sd-side-heading fw400 mt-30 mr-30"
                  style={{ paddingBottom: "10px" }}
                >
                  <span className="fw600">Search Results</span>
                </h3>
                <hr className="clearfix"></hr>
                <br />
                {getRoomsDetails()}
              </div>
            ) : first ? (
              <Sd_loading />
            ) : (
              ""
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
export default withRouter(Sd_dashboard_online_booking_accommodation);

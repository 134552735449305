import Axios from "../Utils/sd-axios/Axios";
import ReportingAxios from "../Utils/sd-axios/reporting-axios";
const Sd_DashboardService = {
  upcoming_booking: async (limit, pageNumber) => {
    try {
      const resp = await ReportingAxios.get(
        `cart/user/upcoming-bookings?paymentStatus=success&page=${pageNumber}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "X-Temple-Id": process.env.REACT_APP_TEMPLE ? 2 : 10,
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        // //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  getStatistics: async () => {
    try {
      const resp = await ReportingAxios.get(`/user-report/dash`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": process.env.REACT_APP_TEMPLE ? 2 : 10,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        // //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  booking_history: async (
    limit,
    pageNumber,
    templeID,
    filter,
    paymentStatus
  ) => {
    try {
      let url = `dashboard/user?paymentStatus=${paymentStatus}&page=${pageNumber}&limit=${limit}`;
      if (filter) {
        url += `&filter=upcoming`;
      }
      const resp = await ReportingAxios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Code": templeID,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  getOrderStats: async (orderId, templeID) => {
    try {
      const resp = await Axios.get(`ccavenue/order/${orderId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Code": templeID,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  get_taxes: async (amount, currency) => {
    try {
      const resp = await Axios.get(
        `payment/additional-charges?amount=${amount}&currency=${currency}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "X-Temple-Id": process.env.REACT_APP_TEMPLE ? 2 : 10,
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  upload_document: async (doc, from, templeID) => {
    try {
      // console.log(doc);
      const resp = await Axios.post(
        `file-upload/upload/private?fileFor=${from}`,
        doc,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "X-Temple-Code": templeID,
          },
        }
      );
      return resp.data;
    } catch (error) {
      // console.log(error);
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        // console.log(error);
        throw new Error(
          "Please make sure the file you are attaching are below 1 mb"
        );
      }
    }
  },

  getRoomType: async (templeID) => {
    try {
      const resp = await Axios.get(
        "accommodation/block/category/room-types-by-temple",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "X-Temple-Code": templeID,
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },

  switch: async (id) => {
    try {
      // console.log(doc);
      const resp = await Axios.post(
        `user/login/asUserId/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "X-Temple-Id": process.env.REACT_APP_TEMPLE ? 2 : 10,
          },
        }
      );
      return resp.data;
    } catch (error) {
      // console.log(error);
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        // console.log(error);
        throw new Error(
          "Please make sure the file you are attaching are below 1 mb"
        );
      }
    }
  },
  logout: async () => {
    try {
      // console.log(doc);
      const resp = await Axios.post(
        `user/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
          },
        }
      );
    } catch (error) {
      if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  donations_getall: async (id) => {
    try {
      const resp = await Axios.get("/donation-online-booking/valid-products", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Code": id,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.statusCode === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.statusCode !== 500 &&
        error?.response?.message
      ) {
        throw new Error(error?.response?.message);
      } else {
        //console.error(error);
        // throw new Error("something is wrong");
      }
    }
  },
  darshanam_getall: async () => {
    try {
      const resp = await Axios.get("darshanam/all");
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  getDailyQuota: async (ac, fromDate, toDate, persons, id) => {
    try {
      const resp = await Axios.get(
        `acc-block-category-daily-quota?fromDate=${fromDate}&toDate=${toDate}&roomType=${ac}&noOfPersons=${persons}&onlyOnlineEnabled=true`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "X-Temple-Code": id,
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  get_favorities: async (id) => {
    try {
      const resp = await Axios.get(`favorite?page=1&limit=1000`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Code": id,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  add_Favorite: async (data, id) => {
    try {
      const resp = await Axios.post(`favorite/add`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Code": id,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  remove_Favorite: async (id, templeID) => {
    try {
      const resp = await Axios.delete(`favorite/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Code": templeID,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  get_master_list: async (data) => {
    try {
      const resp = await Axios.get(
        `devotee-master?page=${data.page}&limit=${data.limit}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "X-Temple-Code": data.id,
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  update_master_list: async (data, id, templeID) => {
    try {
      const resp = await Axios.put(`devotee-master/${id}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Code": templeID,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  add_master_list: async (data, templeID) => {
    try {
      const resp = await Axios.post(`devotee-master`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Code": templeID,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  delete_master_list: async (data, templeID) => {
    try {
      const resp = await Axios.delete(`devotee-master/${data}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Code": templeID,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  download_publication: async (id) => {
    try {
      let resp = await Axios.get(`cart/down-pub/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": process.env.REACT_APP_TEMPLE ? 2 : 10,
        },
        responseType: "blob",
      });
      return resp;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  getTicket: async (id, code) => {
    try {
      const resp = await Axios.get(`scan-ticket/user-ticket-details/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Code": code,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  get_publications: async () => {
    try {
      const resp = await Axios.get(`publications/all`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": process.env.REACT_APP_TEMPLE ? 2 : 10,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  getAllDonorCoupons: async () => {
    try {
      const resp = await Axios.get(`coupon/user?page=1&limit=1000`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Code": process.env.REACT_APP_TEMPLE ?? "SBMSD",
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
};

export default Sd_DashboardService;

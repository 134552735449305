import React, { useState, useContext, useEffect, useRef } from "react";
import { withRouter } from "react-router";
import {
  sd_dashboard_dob,
  sd_dashboard_edit_ico,
  sd_trash,
  sd_failure_icon,
  sd_side_arrow,
  sd_success_icon,
} from "../../../../Assets/imageList";
import DatePicker from "react-date-picker";
import "react-toastify/dist/ReactToastify.css";
import DisplayRazorpay from "../../../../Utils/sd-razorpay/sd-razorpay-display";
import moment from "moment";
import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
import { SD_Context } from "../../../../context/sd-context";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import ParokshaSevaService from "../../../../services/sd-parokshaseva.service";
import { toast } from "react-toastify";
import Sd_DashboardService from "../../../../services/sd-dashboard.service";
import PaymentPage from "../payment-pages/payement-page";
import PhoneInput from "react-phone-input-2";
import { BASE_BANK } from "../../../../Utils/sd-axios/sd-api-url";
const CommonRegex = /^[#.0-9a-zA-Z\s,-/]+$/;

toast.configure();

const Sd_dashboard_online_booking_accommodation_confirm = ({ history }) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const {
    setActive,
    setActivePath,
    currentBookingAccomadations,
    userData,
    devoteeList,
    logout,
    termsListAccommodation,
    setcurrentBookingAccomdations,
    setPaymentPageFlag,
    paymentPageFlag,
  } = useContext(SD_Context);

  const [selectedLanguage, setSelectedlanguage] = React.useState("en-in");
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [firstName, setFirstName] = useState("");
  const [docNumber, setdocNumber] = useState("");
  const [docType, setDockType] = useState("");
  const [LastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("91");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [uploadLink, setUploadLink] = useState("");
  const [charges, setCharges] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("India");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [loading, setLoading] = useState(false);
  const [successOrfailureFlag, setSuccessOrfailureFlag] = useState(false);
  const [status, setStatus] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [dissmissed, setDissmissed] = useState("");
  const [halfState, setHalfState] = useState(null);
  const refContainer = useRef();
  const [email, setEmail] = useState("");
  const [result, setResult] = useState({});
  const [err, setErr] = useState([]);
  const [autoFill, setAutoFill] = useState("none");
  const ref = useRef(null);
  const [timestamp, setTimestamp] = useState("");
  const [detailsFlag, setDetailsFlag] = useState(false);
  const [uploadFlag, setUploadFlag] = useState(false);
  const templeID = process.env.REACT_APP_TEMPLE ?? "SBMSD";
  // const [uploadLink2, setUploadLink2] = useState("");

  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useeffect-----------------------------------------------------------//
  useEffect(async () => {
    setLoading(true);

    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    setActive("online-booking-accommodation-confirm");
    setActivePath("Onlinebooking-Accommodations-Confirm");
    setTimestamp("");

    const localData = JSON.parse(localStorage.getItem("payment-context"));
    if (!currentBookingAccomadations) {
      if (localData) {
        if (localData.responseData) {
          setHalfState(localData.responseData);
          setcurrentBookingAccomdations(localData.bookingData);
          setCharges(localData?.bookingData?.reserved?.accBlockCategory);

          setTimeout(() => {
            setPaymentPageFlag(true);
          }, 1000);
        } else {
          setcurrentBookingAccomdations(localData.bookingData);
        }
      } else {
        history?.push(
          `/${selectedLanguage}/devotee-app/online-booking/accommodations`
        );
      }
    } else {
      setCharges(currentBookingAccomadations?.reserved?.accBlockCategory);
    }

    setLoading(false);

    return () => {
      setTimestamp("");
    };
  }, []);

  useEffect(() => {
    if (dissmissed === "failed") {
      setErrorMsg("Cancelled by User");
      setSuccessOrfailureFlag(true);
      setStatus("failed");
      setLoading(false);
      setDissmissed("");
    }
  }, [dissmissed]);

  useEffect(() => {
    refContainer?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [status]);

  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//
  const handleFileUpload = async (e) => {
    setUploadFlag(true);
    try {
      if (e.target.files[0].size / 1024 / 1024 > 5) {
        setUploadFlag(false);
        document.getElementById("uploadInput").value = "";
        throw new Error(
          "Please make sure the file you are attaching are below 5 mb "
        );
      }
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      let res = await Sd_DashboardService.upload_document(
        formData,
        "myProfile"
      );
      toast.info("File Uploaded");
      setUploadLink(res);
      if (err.find((e) => e.type === "upload" || e.type !== "common")) {
        let data = err.filter((e) => {
          if (e.type !== "upload" && e.type !== "common") {
            return e;
          }
        });
        setErr(data);
      }
      setUploadFlag(false);
    } catch (e) {
      toast.error(e.message);
      setUploadLink("");
      setUploadFlag(false);
      document.getElementById("uploadInput").value = "";

      // let upload = document.getElementById("upload-image-donation");
      // upload.files = [];
      if (e.message === "Session Expired") {
        logout();
        history?.push(
          `/${selectedLanguage}/devotee/signin?to=devotee-app/my-account/profile`
        );
      }
    }
  };

  function addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date?.getDate() + days);
    return copy;
  }

  const handleSubmit = async (flag) => {
    setLoading(true);
    let a = true;
    if (a) {
      let data = [...err];
      if (mobile.substring(0, 2) === "91") {
        if (mobile.length !== 12) {
          data.push({ type: "phone", msg: "Invalid mobile number" });
        }
      } else {
        if (mobile.length < 8) {
          data.push({ type: "phone", msg: "Invalid mobile number" });
        }
      }
      if (email === "") {
        data.push({ type: "email", msg: "Please enter a email id" });
      } else {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email)?.toLowerCase())) {
          data.push({ type: "email", msg: "Please enter a email id" });
        }
      }
      if (firstName === "") {
        data.push({ type: "fname", msg: "Please enter your first name" });
      } else if (firstName.length < 3) {
        data.push({ type: "fname", msg: "Minimun 3 Characters" });
      }

      if (state === "") {
        data.push({ type: "state", msg: "Please select your state" });
      }
      if (country === "") {
        data.push({ type: "country", msg: "Please select your country" });
      }
      if (zip === "") {
        data.push({ type: "zip", msg: "Invalid zipcode! Try again." });
      } else {
        if (zip.length !== 6) {
          data.push({ type: "zip", msg: "Invalid zipcode! Try again." });
        }
      }
      if (docType === "") {
        data.push({ type: "docType", msg: "Select the type of id proof" });
      } else {
        if (docType === "pan") {
          let txt = docNumber.toUpperCase();
          let regex =
            /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
          let pan = {
            C: "Company",
            P: "Personal",
            H: "Hindu Undivided Family (HUF)",
            F: "Firm",
            A: "Association of Persons (AOP)",
            T: "AOP (Trust)",
            B: "Body of Individuals (BOI)",
            L: "Local Authority",
            J: "Artificial Juridical Person",
            G: "Govt",
          };
          pan = pan[txt[3]];
          if (regex.test(txt)) {
            if (pan !== "Personal") {
              data.push({ type: "docNumber", msg: "Invalid pan number" });
            }
          } else data.push({ type: "docNumber", msg: "Invalid pan number" });
        } else if (docType === "ration") {
          let regex = /^([a-zA-Z0-9]){8,12}\s*$/;
          if (!regex.test(docNumber.toUpperCase())) {
            data.push({ type: "docNumber", msg: "Invalid ration number" });
          }
        } else if (docType === "driving") {
          let regex =
            /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
          if (!regex.test(docNumber.toUpperCase())) {
            // data.push({
            //   type: "docNumber",
            //   msg: "Invalid driving license number",
            // });
          }
        } else if (docType === "voter") {
          let regex = /^([a-zA-Z]){3}([0-9]){7}?$/;
          if (!regex.test(docNumber.toUpperCase())) {
            data.push({ type: "docNumber", msg: "Invalid voter id" });
          }
        } else if (docType === "aadhar") {
          let regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
          if (!regex.test(docNumber.toUpperCase())) {
            data.push({ type: "docNumber", msg: "Invalid AADHAAR number" });
          }
        } else if (docType === "passport") {
          let regex = /^[A-Za-z0-9]{6,15}$/;
          if (!regex.test(docNumber.toUpperCase())) {
            data.push({ type: "docNumber", msg: "Invalid passport id" });
          }
        }
        if (uploadLink === "") {
          data.push({ type: "upload", msg: "Please attach a valid id proof" });
        }
      }
      // if (uploadLink2 === "") {
      //   data.push({
      //     type: "upload2",
      //     msg: "Please attach a valid COVID certificate",
      //   });
      // }
      if (city === "") {
        data.push({ type: "city", msg: "Please enter a city" });
      }
      if (gender === "") {
        data.push({ type: "gender", msg: "Please select your gender" });
      }
      if (dob === "") {
        data.push({ type: "date", msg: "Please select a date" });
      }

      if (address1 === "") {
        data.push({ type: "address1", msg: "Please enter a valid address" });
      }
      setErr(data);
      if (data.length !== 0) {
        setLoading(false);
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        return;
      }
    }

    let res;
    if (!halfState) {
      try {
        let dto = {
          blockCategoryId: Number(
            currentBookingAccomadations?.reserved?.accBlockCategoryId
          ),
          bookingDate: moment(currentBookingAccomadations?.startDate).format(
            "YYYY-MM-DD"
          ),
          address: address1,
          city: city,
          state: state,
          country: country,
          pinCode: Number(zip),
          noOfPeople: Number(currentBookingAccomadations?.occupancy),
          dob: moment(dob).format("YYYY-MM-DD"),
          gender: gender,
          documentType: docType,
          documentId: docNumber,
          documentFileURL: uploadLink?.fileUrl
            ? uploadLink?.fileUrl
            : uploadLink,
          contact: `+${mobile}`,
          email: email,
          firstName: firstName,
        };
        if (LastName !== "") {
          dto.lastName = LastName;
        }
        if (address2 !== "") {
          dto.address2 = address2;
        }
        let subDataObj = {
          cartContains: ["accommodation"],
          accommodationBookings: [dto],
          returnPriceStats: flag,
          returnURL: `${window.location.origin}/en-in/devotee-app/online-booking/payment-status`,
        };

        res = await ParokshaSevaService.bookings(subDataObj, templeID);
        if (!flag) {
          setHalfState(res);
          localStorage.setItem(
            "payment-context",
            JSON.stringify({
              responseData: {
                data: {
                  productDetails: res.cartItems.accommodation[0].productDetails,
                  price:
                    res.cartItems.accommodation[0].accommodationBooking.price,
                  personDetails: {
                    personName:
                      res.cartItems.accommodation[0].accommodationBooking
                        ?.firstName +
                      res.cartItems.accommodation[0].accommodationBooking
                        ?.lastName,
                    gender:
                      res.cartItems.accommodation[0].accommodationBooking
                        .gender ?? "",
                  },
                },
                ...res,
              },
              bookingData: currentBookingAccomadations,
              from: "accommodation",
            })
          );
        }
        setResult(res);
        res && setDetailsFlag(true);
      } catch (e) {
        toast.error(e.message);
        if (e.message === "Session Expired") {
          logout();

          history?.push(
            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/accommodations`
          );
        }
        setLoading(false);

        return;
      }
    }

    if (!flag) {
      try {
        if (halfState !== null) {
          if (
            halfState?.priceStats?.templePaymentConfig.configuration.type !==
            "ccavenue"
          ) {
            if (Math.floor((Date.now() - timestamp) / 1000) > 900) {
              toast.error(
                "Redirecting to Previous Page as buffer time of 15 min for retrying the payment has been exceeded."
              );
              setTimestamp("");
              setLoading(false);
              setTimeout(() => {
                history?.push(
                  `/${selectedLanguage}/devotee-app/online-booking/darshanam`
                );
              }, 3000);
              return;
            }
          }

          if (
            halfState?.priceStats?.templePaymentConfig.configuration.type ===
            "ccavenue"
          ) {
            if (halfState || JSON.parse(localStorage.getItem("payment"))) {
              setLoading(false);
              setTimestamp("");
              setPaymentPageFlag(true);
              return;
            }
          } else if (
            halfState?.priceStats?.templePaymentConfig.configuration.type ===
            "razorpay"
          ) {
            await DisplayRazorpay(
              halfState?.paymentInformation?.razorOrder?.id,
              halfState?.paymentInformation?.razorOrder?.currency,
              halfState?.paymentInformation?.razorOrder?.amount,
              halfState?.cartItems?.donation,
              halfState?.paymentInformation?.onlinePaymentEntity?.id,
              BASE_BANK,
              setDissmissed,
              userData,
              dissmissed,
              halfState?.priceStats?.templePaymentConfig?.razorpay?.key,
              templeID
            );
            setHalfState(null);
            // setcurrentBookingAccomdations(null);
            setTimestamp("");
            setSuccessOrfailureFlag(true);
            setStatus("success");
            setLoading(false);
          } else {
            setHalfState(null);
            setTimestamp("");
            setLoading(false);
            toast.error(
              "Payment gateway issue. Please contact temple authorities"
            );
          }
        } else {
          console.log("res state", res);
          setTimestamp(Date.now());
          if (
            res.priceStats?.templePaymentConfig.configuration.type ===
            "ccavenue"
          ) {
            if (res || JSON.parse(localStorage.getItem("payment"))) {
              setLoading(false);
              setTimestamp("");
              setPaymentPageFlag(true);
              return;
            }
          } else if (
            res.priceStats?.templePaymentConfig.configuration.type ===
            "razorpay"
          ) {
            await DisplayRazorpay(
              res?.paymentInformation?.razorOrder?.id,
              res?.paymentInformation?.razorOrder?.currency,
              res?.paymentInformation?.razorOrder?.amount,
              res?.cartItems?.donation,
              res?.paymentInformation?.onlinePaymentEntity?.id,
              BASE_BANK,
              setDissmissed,
              userData,
              dissmissed,
              res?.priceStats?.templePaymentConfig?.razorpay?.key,
              templeID
            );
            setHalfState(null);
            setSuccessOrfailureFlag(true);
            setStatus("success");
            setTimestamp("");
            // setcurrentBookingAccomdations(null);
            setLoading(false);
          } else {
            toast.error({
              message:
                "Something went wrong. Please try again or contact us through support.",
            });
            setLoading(false);
          }

          // console.log(result);
        }
      } catch (e) {
        // console.log(e);
        setErrorMsg(e.message);
        setSuccessOrfailureFlag(true);
        setStatus("failed");
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const getStatusFunction = () => {
    switch (status) {
      case "success":
        return (
          <div className="sd-success" ref={refContainer}>
            <div className="sd-success-top sd-green">
              <img src={sd_success_icon} alt="success" /> <p>Success</p>
            </div>
            <div className="sd-success-bottom">
              <h3>
                Confirmed! Your online booking ID
                <span className="mr-3">-</span>
                {result?.cart?.id} for accommodation at
                <span className="mr-3">
                  {
                    currentBookingAccomadations?.reserved?.accBlockCategory
                      ?.accBlock?.name
                  }
                </span>{" "}
                has been made successfully. We look forward to hosting you.
              </h3>
              <div className="sd-success-bottom-down">
                <p
                  className="sd-border-right"
                  onClick={() => {
                    setcurrentBookingAccomdations(null);
                    history?.push(
                      `/${selectedLanguage}/devotee-app/booking-history`
                    );
                  }}
                >
                  Booking History
                </p>{" "}
                <p
                  onClick={() => {
                    setcurrentBookingAccomdations(null);

                    history?.push(`/${selectedLanguage}/home`);
                  }}
                >
                  Go to Home
                </p>
              </div>
            </div>
          </div>
        );
      case "failed":
        return (
          <div className="sd-success" ref={refContainer}>
            <div className="sd-success-top sd-redback">
              <img src={sd_failure_icon} alt="success" />{" "}
              <p>Sorry, your booking could not be placed. {errorMsg} </p>
            </div>
            <div className="sd-success-bottom">
              <h3>
                Your booking was not placed as there was a problem processing
                payment.<span>failed.</span>
                An error has occurred. Check your booking history or SMS after
                20 mins for confirmation. Please note that any funds debited
                will credit back to your account within 7 working days.
                {/* Please
                place your booking again, for any query click below */}
              </h3>
              <div className="sd-success-bottom-down">
                <p
                  className="sd-border-right"
                  onClick={() => {
                    setStatus("");
                    setSuccessOrfailureFlag(false);
                  }}
                >
                  Try Again
                </p>{" "}
                <p
                  onClick={() => {
                    setcurrentBookingAccomdations(null);

                    history?.push(`/${selectedLanguage}/support/contact-us`);
                  }}
                >
                  Contact Us
                </p>
              </div>
            </div>
          </div>
        );
    }
  };
  const fnCalculateAge = (userDateinput) => {
    let birthDate = new Date(userDateinput);
    let difference = Date.now() - birthDate.getTime();
    let ageDate = new Date(difference);
    let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
    return calculatedAge;
  };
  const autoFillFunction = (name) => {
    let array = [];
    let userClick = () => {
      // console.log(userData);
      userData?.firstName
        ? setFirstName(userData?.firstName)
        : setFirstName("");
      userData?.lastName ? setLastName(userData?.lastName) : setLastName("");
      userData?.gender ? setGender(userData?.gender) : setGender("");
      userData?.docFileUrl
        ? setUploadLink(userData?.docFileUrl)
        : setUploadLink("");
      userData?.idProofType
        ? setDockType(userData?.idProofType?.toLowerCase())
        : setDockType("");
      userData?.idProofNumber
        ? setdocNumber(userData?.idProofNumber)
        : setdocNumber("");
      userData?.city ? setCity(userData?.city) : setCity("");
      userData?.country ? setCountry(userData?.country) : setCountry("");
      userData?.address1 ? setAddress1(userData?.address1) : setAddress1("");
      userData?.address2 ? setAddress2(userData?.address2) : setAddress2("");
      userData?.state ? setState(userData?.state) : setState("");
      userData?.email ? setEmail(userData?.email) : setEmail("");
      userData?.phone
        ? setMobile(userData?.phone?.replace?.("+", ""))
        : setMobile("");
      userData?.pinCode ? setZip(userData?.pinCode) : setZip("");
      userData?.dateOfBirth
        ? setDob(new Date(userData?.dateOfBirth))
        : setDob("");
      let data = err.filter((e) => {
        if (
          e.type !== "fname" &&
          e.type !== "lname" &&
          e.type !== "gender" &&
          e.type !== "city" &&
          e.type !== "address1" &&
          e.type !== "state" &&
          e.type !== "country" &&
          e.type !== "mobile" &&
          e.type !== "zip" &&
          e.type !== "address2" &&
          e.type !== "email" &&
          e.type !== "upload" &&
          e.type !== "docNumber" &&
          e.type !== "docType" &&
          e.type !== "date"
        ) {
          return e;
        }
      });
      setErr(data);
      setAutoFill("none");
    };
    if (
      userData?.firstName?.toLowerCase()?.includes(name?.toLowerCase()) ||
      userData?.lastName?.toLowerCase()?.includes(name?.toLowerCase())
    ) {
      array.push(
        <p
          className="sd-autofill-div"
          onClick={() => {
            userClick();
          }}
        >
          <p
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              userClick();
            }}
          >
            Name:{" "}
            {userData?.firstName ? userData?.firstName : userData?.displayName}
            <span style={{ marginLeft: "3px" }}>{userData?.lastName}</span>
          </p>
          {userData?.dateOfBirth && (
            <p
              onClick={() => {
                userClick();
              }}
            >
              Age:
              {fnCalculateAge(userData?.dateOfBirth)} yrs
            </p>
          )}
          {userData?.gender && (
            <p
              onClick={() => {
                userClick();
              }}
            >
              Gender: {userData?.gender}
            </p>
          )}
        </p>
      );
    }
    if (devoteeList !== null) {
      devoteeList.forEach((items) => {
        if (
          items?.firstName?.toLowerCase()?.includes(name?.toLowerCase()) ||
          items?.lastName?.toLowerCase()?.includes(name?.toLowerCase())
        ) {
          let onClicks = () => {
            // console.log(items);
            items?.firstName
              ? setFirstName(items?.firstName)
              : setFirstName("");
            items?.dateOfBirth
              ? setDob(new Date(items?.dateOfBirth))
              : setDob("");
            items?.lastName ? setLastName(items?.lastName) : setLastName("");
            items?.gender ? setGender(items?.gender) : setGender("");
            items?.doc_file_url
              ? setUploadLink(items?.doc_file_url)
              : setUploadLink("");
            items?.id_proof_type
              ? setDockType(items?.id_proof_type.toLowerCase())
              : setDockType("");
            items?.id_proof_number
              ? setdocNumber(items?.id_proof_number)
              : setdocNumber("");
            let data = err.filter((e) => {
              if (
                e.type !== "fname" &&
                e.type !== "lname" &&
                e.type !== "gender" &&
                e.type !== "upload" &&
                e.type !== "docNumber" &&
                e.type !== "docType" &&
                e.type !== "date"
              ) {
                return e;
              }
            });
            setErr(data);
            setAutoFill("none");
          };
          array.push(
            <p
              key={items.id}
              className="sd-autofill-div"
              onClick={() => {
                onClicks();
              }}
            >
              <p
                onClick={() => {
                  onClicks();
                }}
              >
                Name: {items?.firstName}
                <span style={{ marginLeft: "3px" }}>{items?.lastName}</span>
              </p>
              {items?.dob && (
                <p
                  onClick={() => {
                    onClicks();
                  }}
                >
                  Age:
                  {fnCalculateAge(items?.dob)} yrs
                </p>
              )}
              {items?.gender && (
                <p
                  onClick={() => {
                    onClicks();
                  }}
                >
                  Gender: {items?.gender}
                </p>
              )}
            </p>
          );
        }
      });
    }

    if (array.length !== 0) {
      return (
        <div className="sd-autofill bg">
          {array.map((item) => {
            return item;
          })}
        </div>
      );
    } else {
      if (name === "") {
        let demo = (
          <p
            className="sd-autofill-div"
            onClick={() => {
              userClick();
            }}
          >
            <p
              style={{ textTransform: "capitalize" }}
              onClick={() => {
                userClick();
              }}
            >
              Name:{" "}
              {userData?.firstName
                ? userData?.firstName
                : userData?.displayName}
              <span style={{ marginLeft: "3px" }}>{userData?.lastName}</span>
            </p>
            {userData?.dateOfBirth && (
              <p
                onClick={() => {
                  userClick();
                }}
              >
                Age:
                {fnCalculateAge(userData?.dateOfBirth)} yrs
              </p>
            )}
            {userData?.gender && (
              <p
                onClick={() => {
                  userClick();
                }}
              >
                Gender: {userData?.gender}
              </p>
            )}
          </p>
        );

        let a = [...devoteeList, demo];
        return a;
      } else {
        // console.log("none");
        // setAutoFill("none");
        return "";
      }
    }
  };
  //-------------------------------------------------------------functions-----------------------------------------------------------//
  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {loading ? (
        <Sd_loading />
      ) : successOrfailureFlag ? (
        getStatusFunction()
      ) : (
        <div className="sd-confirm-details">
          <h2 className="sd-side-heading fw400">
            <span className="fw400" ref={ref}>
              <span className="fw700">Confirm</span> Details
            </span>
            <a
              onClick={() => {
                history?.push(
                  `/${selectedLanguage}/devotee-app/online-booking/accommodations`
                );
              }}
              className="sd-confirm-edit-button"
            >
              <img src={sd_dashboard_edit_ico} /> Edit details
            </a>
          </h2>
          <div className="clearfix  sd-booking-details">
            <h4>Booking Details</h4>
            <table className="table table-bordered sd-toggle-table">
              <thead>
                <tr>
                  <th width="40%">Booking for</th>
                  <th width="20%">Date / Time</th>
                  <th width="20%">Particulars</th>
                  <th width="20%">{detailsFlag ? "Total" : "Room Price"}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Accommodation
                    <br></br>(
                    {currentBookingAccomadations?.reserved?.accBlockCategory?.roomType?.replaceAll(
                      "_",
                      " "
                    )}
                    )
                  </td>
                  <td>
                    Check In<br></br>
                    8AM-
                    {moment(currentBookingAccomadations?.startDate).format(
                      "DD-MM-YYYY"
                    )}
                  </td>
                  <td>
                    {currentBookingAccomadations?.reserved?.accBlockCategory?.roomType?.replaceAll(
                      "_",
                      " "
                    )}
                    <br></br>({currentBookingAccomadations?.occupancy} Persons)
                  </td>
                  {(() => {
                    if (
                      currentBookingAccomadations?.reserved?.accBlockCategory
                        ?.price > 0
                    ) {
                      return (
                        <td>
                          {" "}
                          Rs.{" "}
                          {detailsFlag
                            ? Number(result?.totalAmount).toFixed(2)
                            : Number(charges?.price).toFixed(2)}
                          {detailsFlag ? (
                            <>
                              <br /> (Handling charges:{" "}
                              {Number(result?.templeFee).toFixed(2)})
                              <br /> GST Charges: Rs.
                              {Number(result?.gstAmount).toFixed(2)}
                            </>
                          ) : (
                            <></>
                          )}
                        </td>
                      );
                    }
                  })()}
                </tr>

                <tr>
                  <td>
                    <span
                      style={{
                        color: "grey",
                        fontWeight: "600",
                        fontSize: "16px",
                      }}
                    >
                      {" "}
                      Block Name
                    </span>{" "}
                    <br></br>
                    {
                      currentBookingAccomadations?.reserved?.accBlockCategory
                        ?.accBlock?.name
                    }
                    -
                    {currentBookingAccomadations?.reserved?.accBlockCategory?.roomType?.replaceAll(
                      "_",
                      " "
                    )}
                  </td>
                  <td>
                    Check Out<br></br>
                    8AM-
                    {moment(
                      addDays(currentBookingAccomadations?.startDate, 1)
                    ).format("DD-MM-YYYY")}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <div className="sd-table-detoggler">
              <div className="sd-booking-details-up bg">
                <div className="sd-actions-icons-main">
                  <label style={{ color: "grey", fontWeight: 500 }}>
                    BOOKING FOR
                  </label>
                  <br />
                  <span className="fw500">
                    <span
                      style={{ textTransform: "capitalize", fontWeight: "600" }}
                    >
                      {" "}
                      {
                        currentBookingAccomadations?.reserved?.accBlockCategory
                          ?.accBlock?.name
                      }
                    </span>{" "}
                    <br></br>Room Type: (
                    <span
                      style={{ textTransform: "capitalize", fontWeight: "600" }}
                    >
                      {currentBookingAccomadations?.reserved?.accBlockCategory?.roomType?.replaceAll(
                        "_",
                        " "
                      )}
                    </span>
                    ) - Persons - (
                    <span
                      style={{ textTransform: "capitalize", fontWeight: "600" }}
                    >
                      {currentBookingAccomadations?.occupancy}
                    </span>
                    )
                  </span>
                </div>
              </div>
              <div className="sd-booking-details-down ">
                <div>
                  <label>DATE/SLOT</label>
                  Check In<br></br>
                  <span>
                    {moment(currentBookingAccomadations?.startDate).format(
                      "Do MMMM YYYY"
                    )}
                  </span>
                </div>
                <div style={{ marginRight: "0px", marginTop: "5px" }}>
                  <br />
                  Checkout <br />
                  <span>
                    {" "}
                    {moment(
                      addDays(currentBookingAccomadations?.startDate, 1)
                    ).format("Do MMMM YYYY")}
                  </span>
                </div>
              </div>

              <div className="sd-booking-details-down ">
                <div>
                  <label>AMOUNT</label>
                  <span>
                    {(() => {
                      if (
                        currentBookingAccomadations?.reserved?.accBlockCategory
                          ?.price > 0
                      ) {
                        let percentage =
                          currentBookingAccomadations?.reserved
                            ?.accBlockCategory?.gstAmount;
                        return (
                          <>
                            {" "}
                            Rs.{" "}
                            {Number(
                              Number(charges?.price) + Number(percentage)
                            ).toFixed(2)}{" "}
                            <br /> (Handling charges:{" "}
                            {Number(charges?.additionalAmount).toFixed(2)})
                            <br /> GST Charges: Rs.
                            {Number(percentage).toFixed(2)}
                          </>
                        );
                      } else {
                        return (
                          <>
                            {" "}
                            Rs. {charges?.price}.00 <br /> (Handling charges:{" "}
                            {~~charges?.additionalAmount}.00)
                          </>
                        );
                      }
                    })()}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <h3
            className="sd-side-heading fw400 mt-30"
            style={{ paddingBottom: "10px" }}
          >
            <span className="fw400">User Details</span>
            {detailsFlag ? (
              <a
                onClick={() => {
                  setDetailsFlag(false);
                }}
                className="sd-confirm-edit-button"
              >
                <img src={sd_dashboard_edit_ico} /> Edit details
              </a>
            ) : (
              <></>
            )}
          </h3>
          <hr className="clearfix"></hr>
          <div className="clearfix">
            {detailsFlag ? (
              <div className="clearfix sd-devotee-details mt-30">
                <h4>Devotee Details</h4>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th width="5%">S.No.</th>
                      <th width="20%">Name</th>
                      <th width="30%">ID Proof</th>
                      <th width={"45%"}>Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>{`${firstName} ${LastName}`}</td>
                      <td>{docNumber}</td>
                      <td>
                        {address1}
                        {address2 && `, ${address2}`}, {city},{state},{country},
                        {zip}.<br />
                        Mobile: +{mobile}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <form className="sd-form">
                <div className="clearfix">
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                    <label>
                      First Name<span className="sd-imp">*</span>
                    </label>
                    <input
                      autoFocus
                      maxLength="30"
                      type="text"
                      className={
                        err.find((e) => e.type === "fname")
                          ? "form-control sd-red"
                          : firstName !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      onFocus={() => {
                        if (autoFill === `autofill`) {
                          setAutoFill("none");
                        } else {
                          setAutoFill(`autofill`);
                        }
                      }}
                      onBlur={() => {
                        if (autoFill === `autofill`) {
                          setTimeout(() => {
                            setAutoFill("none");
                          }, 250);
                        }
                      }}
                      placeholder="Please enter your first name"
                      value={firstName}
                      autoComplete="new-password"
                      onChange={(e) => {
                        if (
                          /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                          e.target.value === ""
                        ) {
                          if (err.find((e) => e.type === "fname")) {
                            let data = err.filter((e) => e.type !== "fname");
                            setErr(data);
                          }
                          setFirstName(e.target.value);
                        }
                      }}
                    />
                    {autoFill === `autofill` && autoFillFunction(firstName)}
                    <p className="sd-EM mr-30 ">
                      {err.find((e) => e.type === "fname")?.msg}
                    </p>
                  </div>
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0 pr-0">
                    <label>Last Name</label>
                    <input
                      maxLength="30"
                      type="text"
                      className={
                        err.find((e) => e.type === "fname")
                          ? "form-control sd-red"
                          : LastName !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      placeholder="Please enter your last name"
                      value={LastName}
                      onChange={(e) => {
                        if (
                          /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                          e.target.value === ""
                        ) {
                          if (err.find((e) => e.type === "lname")) {
                            let data = err.filter((e) => e.type !== "lname");
                            setErr(data);
                          }
                          setLastName(e.target.value);
                        }
                      }}
                    />
                    <p className="sd-EM">
                      {err.find((e) => e.type === "lname")?.msg}
                    </p>
                  </div>
                </div>

                <div className="clearfix">
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                    <label>
                      Mobile Number<span className="sd-imp">*</span>
                    </label>

                    <div className={"sd-profile-mobile"}>
                      <PhoneInput
                        inputExtraProps={{
                          autoFocus: true,
                          enableSearch: true,
                          countryCodeEditable: false,
                        }}
                        onlyCountries={["in"]}
                        // onlyCountries={[
                        //   "in",
                        //   "au",
                        //   "ca",
                        //   "sg",
                        //   "ch",
                        //   "gb",
                        //   "us",
                        // ]}
                        placeholder="Enter Your 10 digit mobile number"
                        maxLength="15"
                        countryCodeEditable={false}
                        containerClass={
                          err?.find((e) => e.type === "phone")
                            ? "sd-phone-voilet sd-red"
                            : mobile !== ""
                            ? "sd-phone-voilet"
                            : ""
                        }
                        country="in"
                        value={mobile}
                        onChange={(e) => {
                          if (
                            err?.find(
                              (e) => e.type === "phone" || e.type === "common"
                            )
                          ) {
                            let array = err.filter((e) => {
                              if (e.type !== "phone" && e.type !== "common") {
                                return e;
                              }
                            });
                            setErr(array);
                          }
                          setMobile(e);
                        }}
                      />
                      <p className="d-EM ">
                        {err.find((e) => e.type === "phone")
                          ? err.find((e) => e.type === "phone")?.msg
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pr-0">
                    <label>
                      Email address <span className="sd-imp">*</span>
                    </label>
                    <input
                      type="text"
                      maxLength="30"
                      className={
                        err.find((e) => e.type === "email")
                          ? "form-control sd-red"
                          : email !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      placeholder="Please enter a valid email id"
                      value={email}
                      style={{ textTransform: "none" }}
                      onChange={(e) => {
                        if (err.find((e) => e.type === "email")) {
                          let data = err.filter((e) => e.type !== "email");
                          setErr(data);
                        }
                        setEmail(e.target.value);
                      }}
                    />
                    <p className="sd-EM  ">
                      {err.find((e) => e.type === "email")?.msg}
                    </p>
                  </div>
                </div>

                <div className="clearfix">
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0 pl-0">
                    <label>
                      Date of Birth<span className="sd-imp">*</span>
                    </label>
                    <div
                      className={
                        err.find((e) => e.type === "date")
                          ? "sd-dob customDatePickerWidth sd-red"
                          : dob !== ""
                          ? "sd-dob customDatePickerWidth sd-voilet"
                          : "sd-dob customDatePickerWidth"
                      }
                    >
                      <DatePicker
                        dayPlaceholder="DD"
                        monthPlaceholder="MM"
                        yearPlaceholder="YYYY"
                        style={{
                          width: "90%",
                          border: "none",
                          backgroundColor: "blue",
                        }}
                        locale="as-IN"
                        format="dd-MM-y"
                        maxDate={addDays(new Date(), -6570)}
                        calendarIcon={<img src={sd_dashboard_dob} alt="" />}
                        clearIcon={null}
                        value={dob}
                        onChange={(date) => {
                          if (err.find((e) => e.type === "date")) {
                            let data = err.filter((e) => e.type !== "date");
                            setErr(data);
                          }
                          // console.log(date);
                          setDob(date);
                        }}
                      />
                    </div>
                    <p className="sd-EM ">
                      {err.find((e) => e.type === "date")?.msg}
                    </p>
                  </div>

                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0 pr-0">
                    <label>
                      Gender<span className="sd-imp">*</span>
                    </label>
                    <select
                      className={
                        err.find((e) => e.type === "gender")
                          ? "form-control sd-red"
                          : gender !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      value={gender}
                      onChange={(e) => {
                        if (err.find((e) => e.type === "gender")) {
                          let data = err.filter((e) => e.type !== "gender");
                          setErr(data);
                        }
                        setGender(e.target.value);
                      }}
                    >
                      <option key={""} value="" style={{ display: "none" }}>
                        Select a gender
                      </option>
                      <option key={"male"} value="male">
                        Male
                      </option>
                      <option key="female" value="female">
                        Female
                      </option>
                      <option key="others" value="others">
                        Others
                      </option>
                    </select>
                    <p className="sd-EM ">
                      {err.find((e) => e.type === "gender")?.msg}
                    </p>
                  </div>
                </div>
                <hr className="clearfix"></hr>

                <div className="clearfix">
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                    <label>
                      Address Line 1<span className="sd-imp">*</span>
                    </label>
                    <input
                      type="text"
                      maxLength="30"
                      className={
                        err.find((e) => e.type === "address1")
                          ? "form-control sd-red"
                          : address1 !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      value={address1}
                      //value
                      onChange={(e) => {
                        if (
                          CommonRegex.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          if (err.find((e) => e.type === "address1")) {
                            let data = err.filter((e) => e.type !== "address1");
                            setErr(data);
                          }
                          setAddress1(e.target.value);
                        }
                      }}
                      placeholder="House/building number, street number
                    "
                    />
                    <p className="sd-EM mr-30">
                      {err.find((e) => e.type === "address1")?.msg}
                    </p>
                  </div>

                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0 pr-0">
                    <label>Address Line 2</label>
                    <input
                      type="text"
                      maxLength="30"
                      className={
                        address2 !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      placeholder="Building name, street name, etc"
                      value={address2}
                      onChange={(e) => {
                        if (
                          CommonRegex.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          setAddress2(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="clearfix">
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                    <label>
                      City<span className="sd-imp">*</span>
                    </label>
                    <input
                      type="text"
                      maxLength="30"
                      className={
                        err.find((e) => e.type === "city")
                          ? "form-control sd-red"
                          : city !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      value={city}
                      placeholder="Please enter your city"
                      onChange={(e) => {
                        if (
                          CommonRegex.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          if (err.find((e) => e.type === "city")) {
                            let data = err.filter((e) => e.type !== "city");
                            setErr(data);
                          }
                          setCity(e.target.value);
                        }
                      }}
                    />
                    <p className="sd-EM mr-30">
                      {err.find((e) => e.type === "city")?.msg}
                    </p>
                  </div>

                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0 pr-0">
                    <label>
                      ZIP/Pincode<span className="sd-imp">*</span>
                    </label>
                    <input
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      className={
                        err.find((e) => e.type === "zip")
                          ? "form-control sd-red"
                          : zip !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      placeholder="Enter your zipcode"
                      value={zip}
                      onChange={(e) => {
                        if (err.find((e) => e.type === "zip")) {
                          let data = err.filter((e) => e.type !== "zip");
                          setErr(data);
                        }
                        if (e.target.value.length <= 6) {
                          setZip(e.target.value);
                        }
                      }}
                    />
                    <p className="sd-EM ">
                      {err.find((e) => e.type === "zip")?.msg}
                    </p>
                  </div>
                </div>
                <div className="clearfix">
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0 ">
                    <label>
                      State<span className="sd-imp">*</span>
                    </label>
                    <RegionDropdown
                      country={country}
                      classes={
                        err.find((e) => e.type === "state")
                          ? "form-control sd-border-none w-100 sd-red"
                          : state !== ""
                          ? " sd-voilet w-100 sd-border-none"
                          : "w-100 sd-border-none"
                      }
                      value={state}
                      showDefaultOption={true}
                      blankOptionLabel="Select your State"
                      onChange={(val) => {
                        if (err.find((e) => e.type === "state")) {
                          let data = err.filter((e) => e.type !== "state");
                          setErr(data);
                        }
                        setState(val);
                      }}
                    />
                    <p className="sd-EM">
                      {err.find((e) => e.type === "state")?.msg}
                    </p>
                    {/* <select className="form-control" onChange={(e) => {}}>
                  <option>Telangana</option>
                  <option>Andhra Pradesh</option>
                </select> */}
                  </div>
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0 pr-0">
                    <label>
                      Country<span className="sd-imp">*</span>
                    </label>
                    <CountryDropdown
                      value={country}
                      classes={
                        err.find((e) => e.type === "country")
                          ? "form-control sd-border-none w-100 sd-red"
                          : country !== ""
                          ? " sd-voilet w-100 sd-border-none"
                          : "w-100 sd-border-none"
                      }
                      showDefaultOption={true}
                      defaultOptionLabel="Select your Country"
                      onChange={(val) => {
                        if (err.find((e) => e.type === "country")) {
                          let data = err.filter((e) => e.type !== "country");
                          setErr(data);
                        }
                        setCountry(val);
                      }}
                    />
                    <p className="sd-EM mr-30">
                      {err.find((e) => e.type === "country")?.msg}
                    </p>
                  </div>
                </div>

                <hr className="clearfix"></hr>
                <div className="clearfix">
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 pl-0 xssm-pl-0 xssm-pr-0">
                    <label>
                      ID Proof Type<span className="sd-imp">*</span>
                    </label>
                    <select
                      className={
                        err?.find((e) => e.type === `docType`)
                          ? "form-control sd-red"
                          : docType !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      onChange={(e) => {
                        if (err.find((e) => e.type === "docType")) {
                          let data = err.filter((e) => e.type !== "docType");
                          setErr(data);
                        }
                        let value = docType;
                        setDockType(e.target.value);
                        if (value !== e.target.value) {
                          setdocNumber("");
                          setUploadLink("");
                        }
                      }}
                      value={docType}
                    >
                      <option style={{ display: "none" }} value="" key="">
                        Select a ID proof
                      </option>
                      <option key="pan" value="pan">
                        PAN
                      </option>
                      <option key="aadhar" value="aadhar">
                        AADHAAR
                      </option>
                      <option key="passport" value="passport">
                        Passport
                      </option>
                      <option key="driving" value="driving">
                        Driving License
                      </option>
                      <option key="ration" value="ration">
                        Ration Card
                      </option>
                      <option key="voter" value="voter">
                        Voter ID
                      </option>
                    </select>
                    <p className="sd-EM mr-15">
                      {err.find((e) => e.type === "docType")?.msg}
                    </p>
                  </div>

                  <div className="form-group col-xs-12 sd-profile-mobile col-sm-12 col-md-6 col-lg-6  xssm-pr-0 ">
                    <label>
                      Photo ID Number<span className="sd-imp">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Please enter your id number"
                      className={
                        err.find((e) => e.type === "docNumber")
                          ? "form-control sd-red"
                          : docNumber !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      style={{ paddingLeft: "15px", textTransform: "none" }}
                      value={docNumber}
                      onChange={(e) => {
                        if (
                          /^[#.0-9a-zA-Z\s]+$/.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          if (err.find((e) => e.type === "docNumber")) {
                            let data = err.filter((e) => {
                              if (
                                e.type !== "docNumber" &&
                                e.type !== "common"
                              ) {
                                return e;
                              }
                            });
                            setErr(data);
                          }
                          setdocNumber(
                            e.target?.value
                              ?.toUpperCase()
                              ?.replaceAll?.(" ", "")
                          );
                        }
                      }}
                    />
                    <p className="sd-EM mr-15">
                      {err.find((e) => e.type === "docNumber")?.msg}
                    </p>
                  </div>
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0  pl-0 mb-0">
                    <label>
                      Upload ID Proof<span className="sd-imp">*</span>
                    </label>
                    {uploadLink !== "" && (
                      <div className="upload-button">
                        <p>
                          {" "}
                          {uploadLink?.originalname
                            ? uploadLink?.originalname
                            : "File Uploaded"}{" "}
                        </p>{" "}
                        <img
                          src={sd_trash}
                          alt="delete"
                          onClick={() => {
                            if (err.find((e) => e.type === "upload")) {
                              let data = err.filter((e) => {
                                if (
                                  e.type !== "upload" &&
                                  e.type === "common"
                                ) {
                                  return e;
                                }
                              });
                              setErr(data);
                            }
                            setUploadLink("");
                          }}
                        />
                      </div>
                    )}
                    {uploadFlag && (
                      <div className="upload-button">
                        <p>Uploading...</p>{" "}
                      </div>
                    )}
                    {uploadLink === "" && !uploadFlag && (
                      <input
                        style={{ padding: "10px 12px" }}
                        type="file"
                        placeholder="Browse"
                        id="uploadInput"
                        accept="image/png, image/jpeg,image/jpg,.pdf"
                        className={
                          err.find((e) => e.type === "upload")
                            ? "form-control sd-red"
                            : uploadLink !== ""
                            ? "form-control sd-voilet"
                            : "form-control"
                        }
                        onChange={(e) => {
                          handleFileUpload(e);
                        }}
                      />
                    )}{" "}
                    {err.find((e) => e.type === "upload") && (
                      <p className="sd-error-m mr-15">
                        {err.find((e) => e.type === "upload")?.msg}
                      </p>
                    )}
                  </div>
                  {/* <div
                  className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0  pr-0 mb-0"
                  style={{marginBottom: "0px !important"}}
                >
                  <label>
                    Upload COVID Certificate<span className="sd-imp">*</span>
                  </label>
                  {uploadLink2 !== "" ? (
                    <div className="upload-button">
                      <p>
                        {" "}
                        {uploadLink2?.originalname
                          ? uploadLink2?.originalname
                          : "File uploaded"}{" "}
                      </p>{" "}
                      <img
                        src={sd_trash}
                        alt="delete"
                        onClick={() => {
                          setUploadLink2("");
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {uploadLink2 === "" && (
                    <input
                      style={{padding: "10px 12px"}}
                      type="file"
                      placeholder="Browse"
                      id="uploadInputB"
                      accept="image/png, image/jpeg,image/jpg,.pdf"
                      className={
                        err.find((e) => e.type === "upload2")
                          ? "form-control sd-red"
                          : "form-control"
                      }
                      onChange={(e) => {
                        if (err.find((e) => e.type === "upload2")) {
                          let data = err.filter((e) => e.type !== "upload2");
                          setErr(data);
                        }
                        handleFileUpload(e, "2");
                      }}
                    />
                  )}
                  <p className="sd-EM">
                    {err.find((e) => e.type === "upload2")?.msg}
                  </p>
                </div> */}
                </div>
                <hr className="clearfix"></hr>
              </form>
            )}
          </div>
          <div className="clearfix mt-30 sd-form">
            <a
              className="sd-btn-orange"
              onClick={() => {
                if (!loading) {
                  handleSubmit(!detailsFlag);
                }
              }}
            >
              {detailsFlag ? "Proceed to Payment" : "Confirm Booking"}
              <img className="sd-arrow-btn ml-5" src={sd_side_arrow} />
            </a>
          </div>
        </div>
      )}
      <hr className="mt-30" />
      <h3
        className="sd-side-heading fw400 mt-30"
        style={{ paddingBottom: "10px" }}
      >
        <span className="fw400">Terms and Conditions </span>
      </h3>
      <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pl-0 pr-0 ">
        <ol className="sd-terms-list">
          {termsListAccommodation.map((item, i) => {
            return (
              <li className="active" key={i + "terms-prs"}>
                <a style={{ paddingRight: "3px" }}>{item}</a>
              </li>
            );
          })}
        </ol>
      </div>
      {paymentPageFlag && (
        <PaymentPage
          bookingData={{
            responseData: {
              data: {
                productDetails:
                  halfState?.cartItems?.accommodation[0].productDetails,
                price:
                  halfState?.cartItems.accommodation[0].accommodationBooking
                    .price,
                personDetails: [
                  {
                    personName:
                      halfState?.cartItems?.accommodation[0]
                        .accommodationBooking.personDetails,
                    gender:
                      halfState?.cartItems?.accommodation[0]
                        .accommodationBooking.gender,
                  },
                ],
              },
              ...halfState,
            },
            bookingData: currentBookingAccomadations,
            from: "accommodation",
          }}
        />
      )}
    </div>
  );
};
export default withRouter(Sd_dashboard_online_booking_accommodation_confirm);

import React, { createContext, useState } from "react";
import Sd_loading from "../Components/sd-common-components/sd-loading";
import Sd_DashboardService from "../services/sd-dashboard.service";
export const SD_Context = createContext();
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserService from "../services/sd-user.service";
import { API_URL } from "../Utils/sd-axios/sd-api-url";
import { withRouter } from "react-router-dom";

toast.configure();

const SD_Context_Provider = ({ children, history }) => {
  const AddressRegex = /^[#.0-9a-zA-Z\s,-/]+$/;
  const SITE_KEY = "6LfKG_QaAAAAAI9rFw0CjwXxTjEUWZkCMzVqyba6";

  const [activeTopHeader, setActiveTopHeader] = useState("Srisaila-tv");
  const [activeDownHeader, setActiveDownHeader] = useState("");
  const [currentDashboard, setCurrentDashboard] = useState("Srisaila-tv");
  const [userData, setUserData] = useState({});
  const [registerDetails, setRegisterdetails] = useState({});
  const [parokshaSevaData, setParokshaSevaData] = useState([]);
  const [pratyakshaSevaData, setPratyakshaSevaData] = useState([]);
  const [donationData, setDonationData] = useState([]);
  const [currentBookingDonation, setcurrentBookingDonation] = useState(null);
  const [currentDashbaordLower, setCurrentDashboardLower] = useState("");
  const [fav, setFav] = useState([]);
  const [cbSrisailaPrabha, setCbSrisailaPrabha] = useState(null);
  const [currentBookingPratyaksha, setcurrentBookingPratyaksha] =
    useState(null);
  const templeID = process.env.REACT_APP_TEMPLE ?? "SBMSD";
  const [currentBookingDarshananam, setcurrentBookingDarshananam] =
    useState(null);
  const [currentBookingAccomadations, setcurrentBookingAccomdations] =
    useState(null);
  const [display, setDisplay] = useState(true);
  const [currentBooking, setCurrentBooking] = React.useState(null);
  const [currentBookingDetails, setCurrentBookingDetails] =
    React.useState(null);
  const [devoteeList, setDevoteeList] = useState(null);
  const [publicationData, setPublicationData] = useState([]);
  const [visitorCount, setVisitorCount] = useState({});
  const [language, setlanguage] = useState({ desc: "ENGLISH", code: "en-in" });
  const [active, setActive] = useState("devotee-dashboard");
  const [activePath, setActivePath] = useState("Devotee-dashboard");
  const [darshanamData, setDrashanamData] = useState([]);
  const api_url = API_URL;
  const [loading, setLoading] = useState(false);
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [paymentData, setPaymentData] = useState(null);
  const [paymentPageFlag, setPaymentPageFlag] = useState(false);
  const [addonsApiRes, setAddonsApiRes] = useState([]);
  const [addonsList, setAddonsList] = useState([]);
  const [addonIds, setAddonIds] = useState([]);
  const [addonSlotIds, setAddonSlotIds] = useState([]);
  const [addonSlotsData, setAddonSlotsData] = useState([]);
  const [addonTypes, setAddonTypes] = useState([]);
  const [selectedCard, setSelectedCard] = useState();
  const [addonPersonStates, setAddonPersonStates] = useState([
    {
      id: 1,
      name: "",
      idProofNumber: "",
    },
  ]);
  const [noPersons, setNoperons] = useState(1);
  const commonMatchFormat = (item) =>
    item
      ?.replace?.(/\s/g, "")
      ?.replace?.(/[{()}]/g, "")
      ?.replaceAll("-", "")
      .toLowerCase();

  const prathyakshaNameArray = [
    "p-Abhishekam Alamkaramandapam ",
    "p-Abhishekam Garbhalayam ",
    "p-Aksharabhyasam",
    "p-Annaprasana",
    "p-Chandi Homam",
    "p-Gow Puja",
    "p-Kunkumarchana",
    "p-Kunkumarchana Prakaramandapam",
    "p-Leela Kalyanam",
    "p-Mrithymjaya Homam",
    "p-Navavarana Puja",
    "p-Pradoshakala Seva",
    "p-Rudra Homam",
    "p-Sakshi Ganapathi Homam",
    "p-Sarpadoshanivarana Puja",
    "p-Sri Vruddamallikarjuna Swamy Vari Abhishekam ",
    "p-Sri Vruddamallikarjuna Swamy Vari Bilwarchana ",
    "p-Swarna Pushparchana",
    "p-Uchita Samuhika Seva - Mrithymjaya Homam ",
    "p-Udayasthamana Seva",
    "p-Umamaheswara Swamy Vratham",
    "p-Vallidevasena Subramanya Swami Vari Kalyanam",
    "p-Veedaaseerwacahanam",
  ];

  const logout = async () => {
    try {
      setLoading(true);
      await Sd_DashboardService.logout();
      localStorage.clear();
      setCurrentBooking(null);
      setCurrentBookingDetails(null);
      setCbSrisailaPrabha(null);
      setcurrentBookingAccomdations(null);
      setDevoteeList(null);
      setcurrentBookingPratyaksha(null);
      setcurrentBookingDonation(null);
    } catch (e) {
      setLoading(false);
      // console.log(object);
    }
    setLoading(false);
  };

  function numFormatter(num) {
    if (num > 999 && num < 100000) {
      return (num / 1000).toFixed(2) + "K"; // convert to K for number from > 1000 < 1 million
    }
    if (num > 100000 && num < 10000000) {
      return (num / 100000).toFixed(2) + "L"; // convert to K for number from > 1000 < 1 million
    } else if (num > 10000000) {
      return (num / 10000000).toFixed(2) + "Cr"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }

  const getFavorities = async () => {
    if (localStorage.getItem("accessToken")) {
      // setLoading(true);
      try {
        let res = await Sd_DashboardService.get_favorities(
          process.env.REACT_APP_TEMPLE ?? "SBMSD"
        );
        setFav(res?.items ?? []);
        return;
      } catch (e) {
        // console.log(e);
        // return new Error();
      }
    } else {
      return;
    }
  };

  const termsListAccommodation = [
    "If accommodation is booked online, the pilgrim should submit the printed copy at the time of arrival.",
    "Check-in & Check-out time of Cottage/Room is 08:00 am to next day 08:00 am. Thus, the pilgrims check-in at any time on the day of reservation to the Cottage/Room. But the pilgriim must check-out the Cottage/Room by 08:00 am the next day.",
    <>
      Booked Rooms will be allotted as given below Reception Offices: <br />
      'Ganga Sadan Reception : Ganga Sadan & Gouri Sadan
      <br />
      'Mallikarjuna Sadan Reception: Mallikarjuna Sadan
      <br />
      'Central Reception Office : Cottages & Pathaleswara Sadan
      <br />
      'Ganesh Sadan reception Office : Ganesh Sadan Rooms
    </>,
    "Any ID proof on the name of the ticket holder must also be shown.",
    "If accommodation is booked online, the pilgrim must pay the deposit amount at the booking counter while checking-in  and collect the deposit at check-out time.",
    "If accommodation is booked online, only the available Room/Cottage is provided. The Room or Cottage which was particularly booked can only be allotted subject to availability.",
    "The Cottage/Room booking cannot be canceled once the reservation is confirmed. Further change of dates is also not allowed. ",
    "Only cash payment is allowed for deposit amounts.",
    "Pilgrims are expected to maintain the cottage room and things at the cottage room with utmost care and must take complete responsibility of the same and in case of any damage occurred in the cottage/room expect normal wear and tear. The pilgrims need to pay the entire cost of the damaged things at the cottage.",
    "Cottage/Room is reserved for only one day. Extension is not allowed for another day. Thus, pilgrims must check-out within stipulated time without fail.  ",
    "Cottage/Room cannot be allotted to a single person.",
    "Pets/animals are strictly not allowed into the Cottage/Room.",
    "As per Endowment Act – 30/1987 under Sec 23(5) carrying or consumption of Smoking, Alcohol, Tobacco/Gutka, Drugs are strictly prohibited in Srisailam.",
  ];

  const [roomMapData, setRoomMapData] = useState([]);

  const addFavorite = async (data, type) => {
    if (localStorage.getItem("accessToken")) {
      try {
        let datas = {
          type: type,
          itemId: data.id,
        };
        let res = await Sd_DashboardService.add_Favorite(
          datas,
          process.env.REACT_APP_TEMPLE ?? "SBMSD"
        );
        toast.success(`Added ${data.name} Favourites updated!!`);
        await getFavorities();
        // console.log(res);
        // return "ok";
      } catch (e) {
        // console.log(e);
        toast.error(e.message);
      }
    } else {
      return "redirect";
      // toast.error("Please login to add favorities");
    }
  };

  const getVisitors = async () => {
    try {
      console.log(templeID);
      let res = await UserService.getVisitors(templeID);
      setVisitorCount(res);
    } catch (e) {
      // toast.error(e?.message);
    }
    // console.log(res);
  };

  const removeFavorite = async (id) => {
    if (localStorage.getItem("accessToken")) {
      try {
        await Sd_DashboardService.remove_Favorite(
          id,
          process.env.REACT_APP_TEMPLE ?? "SBMSD"
        );
        toast.success(`Removed item from Favourites!`);

        await getFavorities();
        // console.log(res);
      } catch (e) {
        if (e.message === "Session Expired") {
          logout();
          history?.push(`/`);
        }
      }
      // console.log(e);      }}
    } else {
      toast.error("Please login to add favorities");
    }
  };

  React.useEffect(() => {
    getFavorities();
  }, [localStorage.getItem("accessToken")]);

  return (
    <SD_Context.Provider
      value={{
        commonMatchFormat,
        addonPersonStates,
        setAddonPersonStates,
        addonsApiRes,
        setAddonsApiRes,
        addonsList,
        setAddonsList,
        addonTypes,
        setAddonTypes,
        addonIds,
        setAddonIds,
        addonSlotIds,
        setAddonSlotIds,
        addonSlotsData,
        setAddonSlotsData,
        templeID,
        SITE_KEY,
        cbSrisailaPrabha,
        numFormatter,
        setCbSrisailaPrabha,
        getVisitors,
        publicationData,
        setPublicationData,
        visitorCount,
        setVisitorCount,
        darshanamData,
        setDrashanamData,
        donationData,
        setDonationData,
        addFavorite,
        removeFavorite,
        getFavorities,
        loading,
        setLoading,
        devoteeList,
        setDevoteeList,
        currentBookingPratyaksha,
        setcurrentBookingPratyaksha,
        currentBookingAccomadations,
        setcurrentBookingAccomdations,
        currentBookingDarshananam,
        setcurrentBookingDarshananam,
        display,
        setDisplay,
        currentBookingDonation,
        setcurrentBookingDonation,
        activePath,
        setActivePath,
        active,
        setActive,
        supportedLanguages,
        currentBooking,
        setCurrentBooking,
        currentBookingDetails,
        setCurrentBookingDetails,
        currentDashbaordLower,
        setCurrentDashboardLower,
        parokshaSevaData,
        setParokshaSevaData,
        language,
        setlanguage,
        registerDetails,
        setRegisterdetails,
        activeTopHeader,
        setActiveTopHeader,
        activeDownHeader,
        setActiveDownHeader,
        currentDashboard,
        setCurrentDashboard,
        userData,
        setUserData,
        api_url,
        pratyakshaSevaData,
        setPratyakshaSevaData,
        fav,
        setFav,
        logout,
        roomMapData,
        setRoomMapData,
        setPaymentPageFlag,
        paymentPageFlag,
        AddressRegex,
        paymentData,
        setPaymentData,
        prathyakshaNameArray,
        selectedCard,
        setSelectedCard,
        noPersons,
        setNoperons,
        termsListAccommodation,
      }}
    >
      {loading ? <Sd_loading /> : children}
    </SD_Context.Provider>
  );
};

export default withRouter(SD_Context_Provider);

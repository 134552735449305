import React, { useContext, useEffect, useState } from "react";
import {
  SD_Right_Arrow_Blue,
  sd_rupee,
  // SD_ganapathi_homam_img,
  // SD_info_icon,
  sd_share,
  sd_wishlist_heart_active,
  sd_wishlist_heart_unactive,
} from "../../../../Assets/imageList";
import "../../../../Utils/sd-styles/sd-ganapathihomam.scss";
import { withRouter } from "react-router";
import { SD_Context } from "../../../../context/sd-context";
// import YoutubeService from "../../../../services/sd-youtube.service";
import Iframe from "react-iframe";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const SD_Paroksha_Seva_Inner_Component_Two = ({ data, history }) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const [selectedlanguage, setSelectedlanguage] = useState("en-in");
  const { fav, addFavorite, removeFavorite } = useContext(SD_Context);
  const [isYoutubeLive, setIsYoutubeLive] = useState(false);
  const [copyFlag, setCopyFlag] = useState(false);

  //-------------------------------------------------------------states-----------------------------------------------------------//
  useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);
  useEffect(async () => {
    try {
      // const videoId = await YoutubeService.getHomePageId();
      // console.log(videoId);
      // setIsYoutubeLive(videoId);
    } catch (error) {
      setIsYoutubeLive(false);
      // console.log(`problem getting youtube video id`);
    }
  }, []);
  //-------------------------------------------------------------functions-----------------------------------------------------------//
  const getImage = () => {
    if (localStorage.getItem("accessToken")) {
      let res = fav.find(
        (item) => item.item_id === data.id && item.type === "parokshaSeva"
      );
      if (res) {
        return (
          <img
            className="border-l"
            src={sd_wishlist_heart_active}
            alt="active"
            onClick={() => {
              removeFavorite(res.id);
            }}
          />
        );
      } else {
        return (
          <img
            className="border-l"
            src={sd_wishlist_heart_unactive}
            alt="unactive"
            onClick={async () => {
              let resp = await addFavorite(data, "parokshaSeva");
              if (resp === "redirect") {
                history?.push(
                  `/${selectedlanguage}/devotee/signin?to=sevas-and-darshanam/paroksha-seva/${data?.name
                    ?.trim()
                    ?.replace?.(/\s/g, "-")
                    .toLowerCase()}`
                );
              }
            }}
          />
        );
      }
    } else {
      return (
        <img
          className="border-l"
          src={sd_wishlist_heart_unactive}
          alt="unactive"
          onClick={async () => {
            history?.push(
              `/${selectedlanguage}/devotee/signin?to=sevas-and-darshanam/paroksha-seva/${data?.name
                ?.replace?.(/\s/g, "-")
                .toLowerCase()}`
            );
          }}
        />
      );
    }
  };
  const onShareFunction = (path) => {
    setCopyFlag(true);
    navigator.clipboard.writeText(path);
    setTimeout(() => {
      setCopyFlag(false);
    }, 2000);
  };
  //-------------------------------------------------------------functions-----------------------------------------------------------//
  return (
    <div className="sd-gp-two">
      <div className="sd-gp-seva-div">
        <img className="head-image" src={data?.imageUrl} alt="info" />
        <h4>{data?.name}</h4>
        <h5>{data?.description}</h5>
        <h6>
          For more information , please call us: <br></br>
          <span>+91-8333901351 /2 /3 /4 /5 /6</span>
        </h6>
        <div className="sd-timings-gp">
          <div>
            <img
              className="padding-r"
              src={sd_share}
              alt="share"
              onClick={() => {
                onShareFunction(
                  `https://www.srisailadevasthanam.org/${selectedlanguage}/sevas-and-darshanam/paroksha-seva/${data?.name
                    ?.trim()
                    ?.replace?.(/\s/g, "-")
                    ?.replace?.(/[{()}]/g, "")
                    .toLowerCase()}`
                );
              }}
            />
            {getImage()}
          </div>
        </div>

        <div className="sd-booknow">
          <p>
            <img src={sd_rupee} alt="Rs." />
            {data?.price}.00
          </p>
          {!data?.disabled && (
            <button
              onClick={() => {
                if (localStorage.getItem("accessToken")) {
                  history?.push(
                    `/${selectedlanguage}/devotee-app/online-booking/paroksha-seva/booking/${data?.name
                      ?.trim()
                      ?.replace?.(/\s/g, "-")
                      .toLowerCase()}`
                  );
                } else {
                  history?.push(
                    `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/paroksha-seva/booking/${data?.name
                      ?.trim()
                      ?.replace?.(/\s/g, "-")
                      .toLowerCase()}`
                  );
                }
              }}
            >
              Book Now{" "}
              <img
                src={SD_Right_Arrow_Blue}
                alt=""
                height="12px"
                style={{ marginLeft: "5px" }}
              />
            </button>
          )}
        </div>
      </div>
      <div className="sd-gp-video">
        <h2>
          {data?.name}
          <span>|</span> <h3>Srisaila TV</h3>
        </h2>
        {isYoutubeLive ? (
          <Iframe
            url={`https://www.youtube.com/embed/live_stream?channel=UCNIzL2dT2gZxQae2LHXvl0A&rel=0`}
            display="initial"
          />
        ) : (
          <Iframe
            url={`https://www.youtube.com/embed/bgyVTq7Xa64?rel=0`}
            display="initial"
          />
        )}
        {/* <img src={SD_youtube_image} alt={SD_youtube_image} /> */}
      </div>{" "}
      {copyFlag && <div className="sd-copy">Link Copied</div>}{" "}
    </div>
  );
};
export default withRouter(SD_Paroksha_Seva_Inner_Component_Two);

import React, { useEffect, useRef, useState } from "react";
import QRCode from "qrcode.react";
import "../../../Utils/sd-styles/sd-misc.scss";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
// import QRCode from "qrcode.react";

import {
  sd_email_header,
  SD_facebook_icon,
  SD_instagram_icon,
  SD_twitter_icon,
  sd_flower,
  sd_t_websiter,
  sd_t_phone,
  sd_t_email,
  SD_youtube_icon,
} from "../../../Assets/imageList";

// import Sd_loading from "../../Components/sd-common-components/sd-loading";
// import numberToWords from "number-to-words";
import IndiaTime from "../../../Utils/sd-moment/IndiaTime";
// import moment from "moment";
// import {addDays} from "date-fns";
import { useReactToPrint } from "react-to-print";

const PrintDonorCoupon = ({ data, setModal, type }) => {
  //-------------------------------------------------------------states--------------------------------------------------------//

  const refContainer = useRef(null);
  const [print, setPrint] = useState("");
  const [download, setDownload] = useState(false);
  const [dataUrl, setDataUrl] = useState("");
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  useEffect(() => {
    if (print === "print") {
      // console.log("yes");
      setTimeout(() => {
        handlePrint();
      }, 200);
    }
  }, []);
  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  //-------------------------------------------------------------consts-------------------------------------------------------------//

  const styles = {
    buttonDiv: {
      display: "flex",
      justifyContent: "center",
      padding: "20px",
      marginLeft: "25px",
    },
    button: {
      border: "none",
      outline: "none",
      boxShadow: "none",
      marginTop: "0px",
      width: "100px",
      marginLeft: "10px",
    },
  };

  //-------------------------------------------------------------consts-------------------------------------------------------------//

  //-------------------------------------------------------------functions----------------------------------------------------------//

  const fetchTerms = () => {
    return [
      `The Donor shall give not less than 10 days clear advance intimation of the visit by a separate letter through fax (08524 – 287126) or email : endow-eosri@gov.in / aeoaccom1@gmail.com.`,
      `The duration of stay shall not exceed ordinarily for 3 days at a time.`,
      `The donor self can be 100% wavier and the donor referral can be 50% wavier only on the cottage / suite / room rent. `,
      `The donor self or referral must pay the deposit amount for the cottage / suite / room. `,
      `The donor is eligible to occupy his cottage / suite / room if it is vacant, otherwise suitable alternate accommodation will be provided.`,
      `The donor / party shall pay the service charge, etc., according to rules from time to time.`,
      `The donor slip used either by the donor or his relatives or friends shall be deducted from the total eligibility of 45 days provided during the year as per norms.`,
      `The donor shall use one coupon for each day.`,
      `During auspicious days, such as “Brahmotsavams days  prefix day and suffix day of Maha Shivaratri, Ugadi, Karthikamasam, of every year accommodation shall be provided to the self donors only”.`,
      `No accommodation will be provided to single person even on production of Donor Slip.`,
      `Accommodation will be reserved for the self donors only those who submitted written requisition before  30 days during the Maha Shivaratri. `,
      `For more enquiry Call centre No: 8333901351, 52 , 53, 54, 55, 56.`,
    ];
  };

  const handlePrint = useReactToPrint({
    content: () => refContainer.current,
    copyStyles: true,
    bodyClass: "sd-ticket-container",
  });

  const onButtonClick = () => {
    setDownload(true);
    setTimeout(() => {
      let domElement = document.getElementById("sd-ticket-container");
      htmlToImage.toPng(domElement, { quality: 0.95 }).then(function (dataUrl) {
        const pdf = new jsPDF();
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = pdf.internal.pageSize.getHeight() * 0.75; // Adjust the multiplier as needed

        pdf.addImage(
          dataUrl,
          "PNG",
          0, // X position
          0, // Y position
          imgWidth,
          imgHeight
        );
        pdf.save(
          `SBMSD-donor-coupon-${IndiaTime(new Date()).format("DD-MM-YYYY")}.pdf`
        );
        setDownload(false);
      });
    }, 300);
  };

  const renderContent = () => {
    return (
      <>
        <p className="sd-outer-p">
          <span
            className="pr-0"
            style={{
              textTransform: "capitalize",
              fontWeight: "600",
            }}
          >
            {type}
          </span>
        </p>
        <div className="sd-d-list">
          {data[0]?.map((item, i) => {
            return (
              <div key={`print list -${i}`}>
                <label style={{ textTransform: "capitalize" }}>
                  {item?.label}
                </label>
                <h5 style={{ textTransform: "capitalize" }}>{item?.data}</h5>
              </div>
            );
          })}
        </div>
        <br />
        <div
          id="barcode-ticket"
          className="sd-t-barcode"
          style={{ textAlign: "center" }}
        >
          <QRCode
            className="qrcode"
            size="40"
            fontSize="15px"
            value={data[0]?.find((item) => item.label === "Coupon Code")?.data}
          />
        </div>
      </>
    );
  };

  //-------------------------------------------------------------functions----------------------------------------------------------//

  return (
    <div
      className="sd-print"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div style={styles.buttonDiv}>
        <button
          style={styles.button}
          className="sd-btn-orange"
          onClick={() => {
            onButtonClick();
          }}
        >
          {download ? "Downloading.." : "Download"}
        </button>
        <button
          style={styles.button}
          className="sd-btn-orange"
          onClick={() => {
            setModal(false);
          }}
        >
          Close
        </button>
      </div>

      <div
        id="sd-ticket-container"
        className="sd-ticket-container p-0 bg-grey"
        ref={refContainer}
      >
        <div className="sd-d-top">
          <div className="sd-orange-top">
            <p>Om Namah Shivaya</p>
            <img className="sd-img" src={sd_flower} alt="*" />

            <p>Srisaila Lingam Sirasaa Namaami</p>
            <img className="sd-img" src={sd_flower} alt="*" />

            <p>Sri Matre Namaha</p>
          </div>
          <img src={sd_email_header} alt="email" />
        </div>

        <div className="sd-d-bottom">
          {renderContent()}

          <div className="sd-d-terms">
            <p>Terms & Conditions</p>
            <div className="sd-d-terms-inner">
              <ol>
                {fetchTerms()?.map((item, i) => {
                  return <li key={i}>{item}</li>;
                })}
              </ol>
            </div>
          </div>

          <div className="sd-d-b-bottom">
            <div className="sd-d-d-one">
              <div className="sd-d-d-one-right">
                <p> Srisaila Devasthanam,</p>

                <p>
                  Srisailam - 518101, Kurnool (Dist.), Andhra Pradesh, India.
                </p>
              </div>
              <div className="sd-d-d-one-left">
                <div className="sd-d-d-one-1">
                  <img
                    onClick={() => {
                      window.open(
                        "https://www.facebook.com/SrisailaDevasthanamOfficial",
                        "_blank"
                      );
                    }}
                    src={SD_facebook_icon}
                    alt="icon"
                  />
                  <img
                    onClick={() => {
                      window.open(
                        "https://twitter.com/sbmsdevasthanam",
                        "_blank"
                      );
                    }}
                    src={SD_twitter_icon}
                    alt="icon"
                  />

                  <img
                    onClick={() => {
                      window.open(
                        "https://www.instagram.com/sbmsdevasthanam/",
                        "_blank"
                      );
                    }}
                    src={SD_instagram_icon}
                    alt="icon"
                  />
                  <img
                    onClick={() => {
                      window.open(
                        "https://www.youtube.com/channel/UCNIzL2dT2gZxQae2LHXvl0A",

                        "_blank"
                      );
                    }}
                    src={SD_youtube_icon}
                    alt="icon"
                  />
                </div>
                <div className="sd-d-d-one-2">
                  <p>© {new Date().getFullYear()} Srisaila Devasthanam.</p>
                  <p>All Rights Reserved.</p>
                </div>
              </div>
            </div>

            <div className="sd-d-d-two">
              <div>
                <img src={sd_t_websiter} alt="fb"></img>
                <a
                  onClick={() => {
                    window.open(
                      "https://www.srisailadevasthanam.org",

                      "_blank"
                    );
                  }}
                >
                  www.srisailadevasthanam.org
                </a>
              </div>
              <div>
                <img src={sd_t_email} alt="fb" />
                <a
                  onClick={() => {
                    window.open(
                      "https://endow-eosri@gov.in",

                      "_blank"
                    );
                  }}
                >
                  endow-eosri@gov.in
                </a>
              </div>
              <div>
                <img src={sd_t_phone} alt="fb" />
                <a>+91-8333901351 /2 /3 /4 /5 /6</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <img src={dataUrl} alt="" />
    </div>
  );
};

export default PrintDonorCoupon;

/* eslint-disable react/jsx-pascal-case */
import React, { useContext, useRef, useState } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { SD_Context } from "./context/sd-context";
import New_Nav from "./Components/sd-navbar/sd-new-nav";
import Footer from "./Components/sd-footer/sd-footer";
import Sd_Routes from "./sd-routes";
import UserService from "./services/sd-user.service";
import Sd_error_page from "./pages/sd-error-pages/sd-error-response";
import {
  free_samoohika_seva,
  HomePopup1,
  HomePopup2,
  popup1,
  sd_masks,
  SD_Right_Arrow_white,
} from "./Assets/imageList";
import ErrorBoundary from "./context/sd-error-boundary";
import Sd_loading from "./Components/sd-common-components/sd-loading";
import Maintenance from "./pages/maintenance/maintenance";

const App = React.memo((props) => {
  const { setUserData, display, setDisplay, refContainer, templeID } =
    useContext(SD_Context);
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [maintenance, setMaintenance] = useState(false);

  const [loading, setLoading] = React.useState(true);
  const [trig, setTrig] = useState(false);
  const [trig2, setTrig2] = useState(false);
  const [selectedLanguage, setSelectedlanguage] = useState("");
  const [shift, setShift] = useState(false);
  const refe = useRef(null);
  const refe2 = useRef(null);
  const [display2, setDisplay2] = useState(true);

  const fetchOnlineEnable = async () => {
    const res = await UserService.getTempleConfig(templeID);
    if (!res.onlineBookingEnabled) {
      setMaintenance(true);
      setLoading(false);
      return;
    }
  };

  React.useEffect(async () => {
    if (
      supportedLanguages.includes(
        props.history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(props.history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }

    if (localStorage.getItem("accessToken")) {
      checkToken();
    } else {
      setLoading(false);
      setShift(false);
    }
    fetchOnlineEnable();
  }, []);

  const checkToken = async () => {
    try {
      setLoading(true);
      if (localStorage.getItem("accessToken")) {
        // console.log("in access token");
        let data = await UserService.checkToken(templeID);
        setUserData(data);
        setShift(true);
        // if (data) {
        //   let result = await UserService.getUserDetails();
        //   setUserData(result);
        //   setShift(true);
        //   // setUserData(data);
        // }
      }
    } catch (error) {
      // console.log(error);
      localStorage.clear();
    }
    setShift(true);

    setLoading(false);
  };
  // console.log("app");
  const closeFunction = () => {
    setTimeout(() => {
      setTrig(true);
      try {
        refe.current.focus();
      } catch (e) {
        // console.log("");
      }
    }, 3000);
    setTimeout(() => {
      if (display) {
        setDisplay(false);
      }
    }, 10000);
  };

  const closeFunction2 = () => {
    setTimeout(() => {
      // setSeconds(true);
      setTrig2(true);
      try {
        refe2.current.focus();
      } catch (e) {
        // console.log("");
      }
    }, 3000);
    setTimeout(() => {
      if (display2) {
        setDisplay2(false);
      }
    }, 10000);
  };

  // const {getRemainingTime} = useIdleTimer({
  //   timeout,
  //   crossTab: {
  //     emitOnAllTabs: true,
  //   },
  // });

  const handleKey = (e) => {
    if (!e.target.className) {
      return;
    }
    if (e.target.className === "sd-pop-up") {
      if (trig) {
        setDisplay(false);
        setDisplay2(true);
        setTrig(false);
      }
      if (trig2) {
        setDisplay2(true);
        setTrig2(false);
      }
    }
  };

  const onKeyEvent = (event) => {
    const eventCode = event.which || event.keyCode;
    if (eventCode === 27) {
      if (trig) {
        setDisplay(false);
        setDisplay2(true);
        setTrig(false);
      }
      // console.log("click");

      if (trig2) {
        setDisplay2(false);
        setTrig2(false);
      }
    }
  };

  return (
    <>
      {!loading && !maintenance ? (
        <div className="sd-main" id="myDIV" name="myDIV">
          <New_Nav />
          <div ref={refContainer}></div>
          <ErrorBoundary>
            <Switch>
              <Route
                path="/:lang"
                render={(props) => {
                  if (
                    props?.history?.location?.pathname?.replaceAll?.(
                      "/",
                      ""
                    ) === props?.match?.params?.lang &&
                    supportedLanguages.includes(props?.match?.params?.lang)
                  ) {
                    return (
                      <Redirect to={`/${props?.match?.params?.lang}/home`} />
                    );
                  }
                  return supportedLanguages.includes(
                    props?.match?.params?.lang
                  ) ? (
                    <Sd_Routes {...props} />
                  ) : (
                    <Redirect
                      to={`/en-in${props?.history?.location?.pathname}`}
                    />
                  );
                }}
              />
              <Route
                path="*"
                render={(props) => {
                  // console.log("%c euhduiheruiuivheurv");
                  return (
                    <Redirect to={`/en-in${props.history.location.pathname}`} />
                  );
                }}
              />
            </Switch>
            <Footer />
          </ErrorBoundary>

          {display &&
          props?.history?.location.pathname === `/${selectedLanguage}/home` ? (
            <div
              tabIndex="-1"
              ref={refe}
              autoFocus
              className={"sd-pop-up"}
              onKeyDown={(e) => onKeyEvent(e)}
              onMouseDown={(e) => {
                handleKey(e);
              }}
            >
              <div className="inside-pop">
                <img src={popup1} />
                {closeFunction()}
                <button
                  className={"display"}
                  onClick={() => {
                    setDisplay(false);
                    setDisplay2(true);
                    setTrig(false);
                  }}
                >
                  X
                </button>
                <div className="sd-flex-c" style={{ justifyContent: "center" }}>
                  <button
                    className="s-button"
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      setDisplay(false);
                      setTrig(false);
                      props.history?.push(
                        `/${selectedLanguage}/sevas-and-darshanam/pratyaksha-seva/udayasthamana-seva`
                      );
                    }}
                  >
                    More Info
                    <img src={SD_Right_Arrow_white} className="s-l-left" />
                  </button>{" "}
                  <button
                    className="s-button"
                    onClick={() => {
                      if (localStorage?.getItem("accessToken")) {
                        setDisplay(false);
                        props.history?.push(
                          `/${selectedLanguage}/devotee-app/online-booking/pratyaksha-seva/booking/udayasthamana-seva`
                        );
                      } else {
                        setDisplay(false);

                        props.history?.push(
                          `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-seva/booking/udayasthamana-seva`
                        );
                      }
                    }}
                  >
                    Book now
                    <img src={SD_Right_Arrow_white} className="s-l-left" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            //  display2 &&
            //   props?.history?.location.pathname ===
            //     `/${selectedLanguage}/home` ? (
            //   <div
            //     tabIndex="-1"
            //     ref={refe2}
            //     autoFocus
            //     className={"sd-pop-up-2"}
            //     onKeyDown={(e) => onKeyEvent(e)}
            //     onMouseDown={(e) => {
            //       handleKey(e);
            //     }}
            //   >
            //     <div className="inside-pop-2 in-scroll">
            //       <img src={free_samoohika_seva} alt="" className="s-image-2" />
            //       {closeFunction2()}
            //       <button
            //         className={"display-2"}
            //         onClick={() => {
            //           setDisplay2(false);
            //         }}
            //       >
            //         X
            //       </button>
            //       <p>
            //         {" "}
            //         As per the instructions of Assistant Inspector General of
            //         Forest (NTCA) Govt, Indian MoEF & CC NTCA, New Delhi has
            //         suggested closure of all tiger habitats for at least three
            //         months from 01.07.2023 - 30.09.2023 to accommodate the
            //         breeding season of wild animals. Devotees using walking routes
            //         to Srisaila Devasthanam are requested to be aware.
            //         <br />
            //         అసిస్టెంట్ ఇన్స్పెక్టర్ జనరల్ ఆఫ్ ఫారెస్ట్ (NTCA) Govt. వారి
            //         సుచనల ప్రకారము వన్యప్రాణుల సంతానోత్పత్తి కాలాన్ని దృష్టిలో
            //         ఉంచుకుని కనీసం మూడు నెలల కాలము 01.07.2023 నుండి 30.09.2023
            //         వరకు అన్ని పులులు ఆవాసము ఉండే ప్రాంతములు మూసివేయాలని భారత MoEF
            //         & CC NTCA న్యూఢిల్లీ సూచించింది. శ్రీశైల దేవస్థానమునకు నడక
            //         దారిన వచ్చు భక్తులు గమనిచగలరని విజ్ఞప్తి.
            //       </p>
            //        <div className="sd-flex-c-2">
            //         <button
            //           className="s-button-2"
            //           onClick={() => {
            //             setDisplay(false);

            //             props.history?.push(
            //               `/${selectedLanguage}/sevas-and-darshanam/pratyaksha-seva/uchita-samuhika-seva-free-seva`
            //             );
            //           }}
            //         >
            //           More Info
            //           <img src={SD_Right_Arrow_white} className="s-l-left-2" />
            //         </button>{" "}
            //         <button
            //           className="s-button-2"
            //           onClick={() => {
            //             if (localStorage?.getItem("accessToken")) {
            //               setDisplay(false);
            //               props.history?.push(
            //                 `/${selectedLanguage}/devotee-app/online-booking/pratyaksha-seva/booking/uchita-samuhika-seva-(free-seva)`
            //               );
            //             } else {
            //               setDisplay(false);

            //               props.history?.push(
            //                 `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-seva/booking/uchita-samuhika-seva-(free-seva)`
            //               );
            //             }
            //           }}
            //         >
            //           Book now
            //           <img src={SD_Right_Arrow_white} className="s-l-left-2" />
            //         </button>
            //       </div>
            //     </div>
            //   </div>
            // ) :
            ""
          )}
        </div>
      ) : maintenance ? (
        <>
          <Maintenance />
        </>
      ) : (
        <Sd_loading />
      )}
      <div className="sd-error">
        <Sd_error_page />
      </div>
    </>
  );
});
App.displayName = "App";

export default withRouter(App);

import moment from "moment";
import React, { useEffect, useContext, useState, useRef } from "react";
import { withRouter } from "react-router";
import {
  sd_btn_loading,
  sd_dashboard_edit_ico,
  sd_failure_icon,
  SD_Right_Arrow_white,
  sd_success_icon,
} from "../../../Assets/imageList";
import { SD_Context } from "../../../context/sd-context";
import DisplayRazorpay from "../../../Utils/sd-razorpay/sd-razorpay-display";
import "../../../Utils/sd-styles/sd-css/main.css";
import ParokshaSevaService from "../../../services/sd-parokshaseva.service";
import { toast } from "react-toastify";
import Sd_loading from "../../../Components/sd-common-components/sd-loading";
import { BASE_BANK } from "../../../Utils/sd-axios/sd-api-url";
import PayementPage from "./payment-pages/payement-page";
const Sd_dashboard_online_donations_confirm = ({ history }) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const {
    setActive,
    setActivePath,
    userData,
    currentBookingDonation,
    setcurrentBookingDonation,
    setPaymentPageFlag,
    supportedLanguages,
    logout,
    paymentPageFlag,
  } = useContext(SD_Context);

  const templeID = process.env.REACT_APP_TEMPLE ?? "SBMSD";
  const [selectedLanguage, setSelectedlanguage] = React.useState("en-in");
  const [successOrfailureFlag, setSuccessOrfailureFlag] = useState(false);
  const [status, setStatus] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const [dissmissed, setDissmissed] = useState("");
  const [halfState, setHalfState] = useState(null);
  const refContainer = useRef();
  // const [charges, setCharges] = useState("");
  const [timestamp, setTimestamp] = useState("");

  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useeffect-----------------------------------------------------------//
  useEffect(async () => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    setActive("online-booking-donations-confirm");
    setActivePath("Onlinebooking-Donations-Confirm");
    setTimestamp("");

    const localData = JSON.parse(localStorage.getItem("payment-context"));
    if (!currentBookingDonation) {
      if (localData) {
        if (localData.responseData) {
          setHalfState(localData.responseData);
          setcurrentBookingDonation(localData.bookingData);
          setTimeout(() => {
            setPaymentPageFlag(true);
          }, 1000);
        } else {
          setcurrentBookingDonation(localData.bookingData);
        }
      } else {
        history?.push(
          `/${selectedLanguage}/devotee-app/online-booking/darshanam`
        );
      }
    }

    setLoading(false);
    return () => {
      setTimestamp("");
    };
  }, []);

  useEffect(() => {
    if (dissmissed === "failed") {
      setErrorMsg("Cancelled by User");
      setSuccessOrfailureFlag(true);
      setStatus("failed");
      setLoading(false);
      setDissmissed("");
      // console.log("dismiised in paroksha seva");
    }
  }, [dissmissed]);

  useEffect(() => {
    refContainer?.current?.scrollIntoView("start");
  }, [status]);
  //-------------------------------------------------------------useeffect-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//
  const getStatusFunction = () => {
    switch (status) {
      case "success":
        return (
          <div className="sd-success" ref={refContainer}>
            <div className="sd-success-top sd-green">
              <img src={sd_success_icon} alt="success" /> <p>Success</p>
            </div>
            <div className="sd-success-bottom">
              <h3>
                {currentBookingDonation?.donation?.name ===
                "SBMS AnnaPrasadam Trust"
                  ? "Thanks for your donation! You've freed one of hunger. May divine blessings always be upon you."
                  : currentBookingDonation?.donation?.name === "e-Hundi"
                  ? "Thanks for your e-hundi donation! Your contribution shall be utilized for noble causes. May divine blessings always be upon you."
                  : currentBookingDonation?.donation?.name ===
                    "SBMS Aagama Patashala Trust"
                  ? "Thanks for your donation! You've done a good deed. May divine blessings always be upon you."
                  : currentBookingDonation?.donation?.name ===
                    "SBMS GoSamrakshana Trust"
                  ? "Thanks for your donation! You've shown compassion towards our holy cows. May divine blessings always be upon you."
                  : currentBookingDonation?.donation?.name ===
                    "SBMS Pranadana Trust"
                  ? "Thanks for your donation! You've saved a life. May divine blessings always be upon you."
                  : currentBookingDonation?.donation?.name ===
                    "SBMS Swachh Srisailam"
                  ? "Thanks for your donation! Your contribution is making Srisailam cleaner. May divine blessings always be upon you."
                  : "Thanks for your donation! You've done a good deed. May divine blessings always be upon you."}
              </h3>
              <div className="sd-success-bottom-down">
                <p
                  className="sd-border-right"
                  onClick={() => {
                    setcurrentBookingDonation(null);

                    history?.push(
                      `/${selectedLanguage}/devotee-app/booking-history`
                    );
                  }}
                >
                  Booking History
                </p>
                <p
                  onClick={() => {
                    setcurrentBookingDonation(null);

                    history?.push(`/${selectedLanguage}/home`);
                  }}
                >
                  Go to Home
                </p>
              </div>
            </div>
          </div>
        );
      case "failed":
        return (
          <div className="sd-success" ref={refContainer}>
            <div className="sd-success-top sd-redback">
              <img src={sd_failure_icon} alt="success" />
              <p>Sorry, your Donation failed. {errorMsg} </p>
            </div>
            <div className="sd-success-bottom">
              <h3>
                Your donation was unsuccessfull! An error has occurred. Check
                your booking history or SMS after 20 mins for confirmation.
                Please note that any funds debited will credit back to your
                account within 7 working days.
              </h3>
              <div className="sd-success-bottom-down">
                <p
                  className="sd-border-right"
                  onClick={() => {
                    setStatus("");
                    setSuccessOrfailureFlag(false);
                  }}
                >
                  Try Again
                </p>
                <p
                  onClick={() => {
                    setcurrentBookingDonation(null);

                    history?.push(`/${selectedLanguage}/support/contactUs`);
                  }}
                >
                  Contact Us
                </p>
              </div>
            </div>
          </div>
        );
    }
  };

  function capitalize(str) {
    let splitStr = str?.toLowerCase?.().split?.(" ");
    for (let i = 0; i < splitStr?.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr?.join(" ");
  }

  const handleSubmit = async () => {
    let res;
    if (!halfState) {
      try {
        setLoading(true);
        let dto = {
          donationId: Number(currentBookingDonation?.donation?.id),
          devoteeName: currentBookingDonation?.donor,
          amount: Number(currentBookingDonation?.amount),
          address: capitalize(currentBookingDonation?.address1),
          city: capitalize(currentBookingDonation?.city),
          state: currentBookingDonation?.state,
          country: currentBookingDonation?.country,
          pinCode: Number(currentBookingDonation?.zip),
          contact: `+${currentBookingDonation?.mobile}`,
        };

        if (
          currentBookingDonation?.donation?.name !== "SBMS Srisaila TV" &&
          currentBookingDonation?.donation?.name !== "SBMS Swachh Srisailam" &&
          currentBookingDonation?.donation?.name !== "SBMS Pranadana Trust"
        ) {
          dto.dob = moment(currentBookingDonation?.dob).format("YYYY-MM-DD");
          dto.gender = currentBookingDonation?.gender;
          dto.calendarType = currentBookingDonation?.calenderType;

          if (currentBookingDonation?.calenderType === "telugu") {
            dto.masam = currentBookingDonation?.masam;
            dto.paksham = currentBookingDonation?.paksham;
            dto.tithi = currentBookingDonation?.tithi;
          } else if (currentBookingDonation?.calenderType === "english") {
            dto.bookingDate = moment(currentBookingDonation?.date).format(
              "YYYY-MM-DD"
            );
          }
        }

        if (currentBookingDonation?.amount >= 50000) {
          dto.panCardFileURL = currentBookingDonation?.uploadLink
            ? currentBookingDonation?.uploadLink.path
            : currentBookingDonation?.uploadLink.fileUrl; // req
          dto.documentType = currentBookingDonation?.docType?.toLowerCase?.(); // req
        }

        if (currentBookingDonation?.gothram) {
          dto.gothram = capitalize(currentBookingDonation?.gothram);
        }
        if (currentBookingDonation?.address2 !== "") {
          dto.address2 = capitalize(currentBookingDonation?.address2);
        }
        if (currentBookingDonation?.amount >= 50000) {
          dto.panCardId = currentBookingDonation?.docNumber;
        }
        if (currentBookingDonation?.occasion !== "") {
          dto.occasion = capitalize(currentBookingDonation?.occasion); // req
        }
        let subDataObj = {
          cartContains: ["donation"],
          donationBookings: [dto],
          returnPriceStats: false,
        };
        if (
          currentBookingDonation?.res.templePaymentConfig.configuration.type ===
          "ccavenue"
        ) {
          subDataObj.returnURL = `${window.location.origin}/en-in/devotee-app/online-booking/payment-status`;
        }
        res = await ParokshaSevaService.bookings(subDataObj, templeID);
        setHalfState(res);
        localStorage.setItem(
          "payment-context",
          JSON.stringify({
            responseData: {
              data: {
                productDetails: res.cartItems.donation[0].productDetails,
                price: res.cartItems.donation[0].donationBooking.price,
                personDetails: [
                  {
                    personName:
                      res.cartItems.donation[0].donationBooking.devoteeName,
                    gender: res.cartItems.donation[0].donationBooking.gender,
                  },
                ],
              },
              ...res,
            },
            bookingData: currentBookingDonation,
            from: "donation",
          })
        );
      } catch (e) {
        // console.log(e);
        toast.error(e.message);
        if (e.message === "Session Expired") {
          logout();

          history?.push(
            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/donations`
          );
        }
        setLoading(false);
        return;
      }
    }

    try {
      if (halfState !== null) {
        if (Math.floor((Date.now() - timestamp) / 1000) > 900) {
          toast.error(
            "Redirecting to Previous Page as buffer time of 15 min for retrying the payment has been exceeded."
          );
          setTimestamp("");
          setTimeout(() => {
            history?.push(
              `/${selectedLanguage}/devotee-app/online-booking/donations`
            );
          }, 3000);
          return;
        }
        if (
          currentBookingDonation?.res.templePaymentConfig.configuration.type ===
          "ccavenue"
        ) {
          if (halfState || JSON.parse(localStorage.getItem("payment"))) {
            setLoading(false);
            setTimestamp("");
            setPaymentPageFlag(true);
            return;
          }
        } else if (
          currentBookingDonation?.res.templePaymentConfig.configuration.type ===
          "razorpay"
        ) {
          await DisplayRazorpay(
            halfState?.paymentInformation?.razorOrder?.id,
            halfState?.paymentInformation?.razorOrder?.currency,
            halfState?.paymentInformation?.razorOrder?.amount,
            halfState?.cartItems?.donation,
            halfState?.paymentInformation?.onlinePaymentEntity?.id,
            BASE_BANK,
            setDissmissed,
            userData,
            dissmissed,
            halfState?.priceStats?.templePaymentConfig?.razorpay?.key,
            templeID
          );
          setHalfState(null);
          // setcurrentBookingDonation(null);
          setTimestamp("");
          setSuccessOrfailureFlag(true);
          setStatus("success");
          setLoading(false);
        } else {
          setHalfState(null);
          setTimestamp("");
          setLoading(false);
          toast.error(
            "Payment gateway issue. Please contact temple authorities"
          );
        }
      } else {
        setTimestamp(Date.now());
        if (
          currentBookingDonation?.res.templePaymentConfig.configuration.type ===
          "ccavenue"
        ) {
          if (res || JSON.parse(localStorage.getItem("payment"))) {
            setLoading(false);
            setTimestamp("");
            setPaymentPageFlag(true);
            return;
          }
        } else if (
          currentBookingDonation?.res.templePaymentConfig.configuration.type ===
          "razorpay"
        ) {
          await DisplayRazorpay(
            res?.paymentInformation?.razorOrder?.id,
            res?.paymentInformation?.razorOrder?.currency,
            res?.paymentInformation?.razorOrder?.amount,
            res?.cartItems?.donation,
            res?.paymentInformation?.onlinePaymentEntity?.id,
            BASE_BANK,
            setDissmissed,
            userData,
            dissmissed,
            res?.priceStats?.templePaymentConfig?.razorpay?.key,
            templeID
          );
          setHalfState(null);
          setSuccessOrfailureFlag(true);
          setStatus("success");
          setTimestamp("");
          // setcurrentBookingDonation(null);
          setLoading(false);
        } else {
          toast.error({
            message:
              "Something went wrong. Please try again or contact us through support.",
          });
          setLoading(false);
        }
      }
    } catch (e) {
      console.log(e);
      setErrorMsg(e.message);
      setSuccessOrfailureFlag(true);
      setStatus("failed");
      setLoading(false);
    }
  };
  //-------------------------------------------------------------functions-----------------------------------------------------------//
  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {loading ? (
        <Sd_loading />
      ) : successOrfailureFlag ? (
        getStatusFunction()
      ) : (
        <div className="sd-confirm-details">
          <h2 className="sd-side-heading fw400">
            <span className="fw400">
              <span className="fw700">Confirm</span> Details
            </span>
            <a
              onClick={() => {
                history?.push(
                  `/${selectedLanguage}/devotee-app/online-booking/donations`
                );
              }}
              className="sd-confirm-edit-button"
            >
              <img src={sd_dashboard_edit_ico} /> Edit details
            </a>
          </h2>
          <div className="clearfix  sd-booking-details">
            <h4>Booking Details</h4>
            <table className="table table-bordered sd-toggle-table">
              <thead>
                <tr>
                  {currentBookingDonation?.donation?.name !==
                    "SBMS Sri Srisaila TV" &&
                  currentBookingDonation?.donation?.name !==
                    "SBMS Swachh Srisailam" &&
                  currentBookingDonation?.donation?.name !==
                    "SBMS Pranadana Trust" ? (
                    <>
                      <th width="50%">Booking for</th>
                      <th width="25%">Date</th>
                      <th width="25%">Total Amount</th>
                    </>
                  ) : (
                    <>
                      <th width="60%">Booking for</th>
                      <th width="40%">Total Amount</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {currentBookingDonation?.donation?.name.includes(
                      "AnnaPrasadam"
                    )
                      ? currentBookingDonation?.donation?.name ===
                        "SBMS AnnaPrasadam Trust"
                        ? "Annadanam for a Day"
                        : "Saswatha Annadanam"
                      : currentBookingDonation.donation?.name}
                    <br></br>(Donations -
                    {currentBookingDonation?.donation?.name.includes(
                      "AnnaPrasadam"
                    )
                      ? "SBMS AnnaPrasadam Trust"
                      : currentBookingDonation?.donation?.name}
                    )
                  </td>
                  {currentBookingDonation?.donation?.name !==
                    "SBMS Sri Srisaila TV" &&
                  currentBookingDonation?.donation?.name !==
                    "SBMS Swachh Srisailam" &&
                  currentBookingDonation?.donation?.name !==
                    "SBMS Pranadana Trust" ? (
                    <>
                      {" "}
                      {currentBookingDonation.calenderType === "telugu" ? (
                        <td>
                          {currentBookingDonation?.masam} /
                          <span
                            style={{
                              textTransform: "capitalize",
                              marginLeft: "3px",
                            }}
                          >
                            {currentBookingDonation?.paksham}
                          </span>
                          / {currentBookingDonation?.tithi} <br></br>(
                          <span style={{ textTransform: "capitalize" }}>
                            {currentBookingDonation?.calenderType}
                          </span>
                          Calendar)
                        </td>
                      ) : (
                        <td>
                          {moment(currentBookingDonation?.date).format(
                            "DD-MM-YYYY"
                          )}
                          <span
                            style={{
                              textTransform: "capitalize",
                              marginLeft: "3px",
                            }}
                          >
                            ({currentBookingDonation?.calenderType}
                            Calendar)
                          </span>
                        </td>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  <td>
                    {userData?.phone?.substring(0, 3) === "+91" ? "Rs." : "$"}{" "}
                    {parseFloat(
                      currentBookingDonation?.res?.totalAmount
                    ).toFixed(2)}
                    <br /> (Handling charges:{" "}
                    {userData?.phone?.substring(0, 3) === "+91" ? "Rs." : "$"}{" "}
                    {parseFloat(currentBookingDonation?.res?.templeFee).toFixed(
                      2
                    )}
                    )
                  </td>
                </tr>
                <tr>
                  <td colSpan="3" height="20px"></td>
                </tr>
              </tbody>
            </table>

            <div className="sd-table-detoggler">
              <div className="sd-booking-details-middle bg">
                <div className="">
                  {" "}
                  <label style={{ color: "grey", fontWeight: 500 }}>
                    BOOKING FOR
                  </label>
                  <span className="fw500">
                    {currentBookingDonation?.donation?.name ===
                    "SBMS AnnaPrasadam Trust"
                      ? currentBookingDonation?.type
                      : currentBookingDonation.donation?.name}
                    <br></br>
                    {currentBookingDonation?.donation?.name} - (Donations)
                  </span>
                </div>
              </div>
              <div className="sd-booking-details-down ">
                <div>
                  <label>DATE/SLOT</label>
                  <span>
                    {currentBookingDonation.calenderType === "telugu" ? (
                      <>
                        {currentBookingDonation?.masam} /
                        <span
                          style={{
                            textTransform: "capitalize",
                            marginLeft: "3px",
                          }}
                        >
                          {currentBookingDonation?.paksham}
                        </span>
                        / {currentBookingDonation?.tithi} <br></br>(
                        <span
                          style={{
                            textTransform: "capitalize",
                            marginRight: "3px",
                          }}
                        >
                          {currentBookingDonation?.calenderType}
                        </span>
                        Calendar)
                      </>
                    ) : (
                      <>
                        {moment(currentBookingDonation?.date).format(
                          "DD-MM-YYYY"
                        )}
                        <span
                          style={{
                            textTransform: "capitalize",
                            marginLeft: "3px",
                          }}
                        >
                          <br />({currentBookingDonation?.calenderType}
                          Calendar)
                        </span>
                      </>
                    )}
                  </span>
                </div>

                <div style={{ marginRight: "0px" }}>
                  {" "}
                  <label>AMOUNT</label>
                  <span>
                    {" "}
                    {userData?.phone?.substring(0, 3) === "+91"
                      ? "Rs."
                      : "$"}{" "}
                    {parseFloat(
                      currentBookingDonation?.res?.serviceAmount
                    ).toFixed(2)}
                    <br />{" "}
                    <span style={{ fontSize: "12px" }}>
                      (Handling charges:{" "}
                      {userData?.phone?.substring(0, 3) === "+91" ? "Rs." : "$"}{" "}
                      {parseFloat(
                        currentBookingDonation?.res?.templeFee
                      ).toFixed(2)}
                    </span>
                  </span>
                </div>
              </div>
              {currentBookingDonation?.occasion && (
                <div className="sd-booking-details-down ">
                  <div>
                    {" "}
                    <label>OCCASION</label>
                    <span> {currentBookingDonation?.occasion}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="clearfix sd-devotee-details mt-30">
            <h4>Doner/nominee Details</h4>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th width="5%">S.No.</th>
                  <th width="20%">Name</th>
                  {currentBookingDonation?.gothram !== "" && (
                    <th width="20%">Gothram</th>
                  )}

                  {currentBookingDonation?.amount >= 50000 && (
                    <th
                      width={
                        currentBookingDonation?.gothram !== "" ? "25%" : "35%"
                      }
                    >
                      PAN Number
                    </th>
                  )}

                  <th
                    width={
                      currentBookingDonation?.gothram !== ""
                        ? "35%"
                        : currentBookingDonation?.amount >= 50000
                        ? "75"
                        : "45%"
                    }
                  >
                    Address
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>{currentBookingDonation?.donor}</td>

                  {currentBookingDonation?.gothram !== "" && (
                    <td>{currentBookingDonation?.gothram}</td>
                  )}

                  {currentBookingDonation?.amount >= 50000 && (
                    <td>
                      XXXXXX
                      {currentBookingDonation?.docNumber.substring(
                        currentBookingDonation?.docNumber?.length - 4,
                        currentBookingDonation?.docNumber?.length
                      )}
                    </td>
                  )}

                  <td>
                    {currentBookingDonation?.address1}
                    {currentBookingDonation?.address2 &&
                      `, ${currentBookingDonation?.address2}`}
                    , {currentBookingDonation?.city},
                    {currentBookingDonation?.state},
                    {currentBookingDonation?.country},
                    {currentBookingDonation?.zip}.<br />
                    Mobile: +{currentBookingDonation?.mobile}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="clearfix mt-50 sd-form">
            <a
              className="sd-btn-orange"
              onClick={() => {
                if (!loading) {
                  handleSubmit();
                }
              }}
            >
              Proceed to Payment
              {loading ? (
                <img
                  src={sd_btn_loading}
                  alt=".."
                  className="sd-arrow-btn ml-5 h-20"
                />
              ) : (
                <img className="sd-arrow-btn ml-5" src={SD_Right_Arrow_white} />
              )}
            </a>
          </div>
        </div>
      )}
      {paymentPageFlag && (
        <PayementPage
          bookingData={{
            responseData: {
              data: {
                productDetails:
                  halfState?.cartItems?.donation[0].productDetails,
                price: halfState?.cartItems.donation[0].donationBooking.price,
                personDetails: [
                  {
                    personName:
                      halfState.cartItems.donation[0].donationBooking
                        .devoteeName,
                    gender:
                      halfState.cartItems.donation[0].donationBooking.gender,
                  },
                ],
              },
              ...halfState,
            },
            bookingData: currentBookingDonation,
            from: "donation",
          }}
        />
      )}
    </div>
  );
};
export default withRouter(Sd_dashboard_online_donations_confirm);

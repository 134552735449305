/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { useContext } from "react";
import {
  SD_ganapathi_homam_banner_img,
  SD_parokshaseva_banner_img,
} from "../../../Assets/imageList";
import { SD_Context } from "../../../context/sd-context";
import "../../../Utils/sd-styles/sd-parokshaseva.scss";
import Sd_breadcrumb from "../../sd-common-components/sd-breadcrumb";

const SD_Pratyaksha_Seva_One = ({ from, data }) => {
  const { prathyakshaNameArray } = useContext(SD_Context);
  //-------------------------------------------------------------consts-----------------------------------------------------------//

  //-------------------------------------------------------------consts-----------------------------------------------------------//
  return (
    <div className="sd-Paroksha-one">
      <div className="banner">
        <div className="sd-banner-top">
          <h1>
            {from === "Pratyaksha Seva" ? (
              <span>
                Pratyaksha<span>Seva</span>
              </span>
            ) : prathyakshaNameArray.includes(from) ? (
              <span>{from.split("-")[1]}</span>
            ) : (
              <span>{from} </span>
            )}
          </h1>
          <h4>Srisailam is Maha Shiva, Srisailam is Sri Chakra.</h4>
        </div>

        <div className="sd-banner-bottom">
          <img
            src={
              from === "Ganapathi-homam"
                ? SD_ganapathi_homam_banner_img
                : SD_parokshaseva_banner_img
            }
            alt="icon"
          />
        </div>
      </div>
      <Sd_breadcrumb
        data={data}
        from={from === "Pratyaksha Seva" ? "Pratyaksha Seva" : from}
      />
    </div>
  );
};
export default SD_Pratyaksha_Seva_One;
